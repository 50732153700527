import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetPartnerListByIntegrationInterface {
  warehuseIntegration?: string
}

export const GetPartnerOrderListByIntegration = () => {
  const mutate = useMutation(
    ({ warehuseIntegration }: GetPartnerListByIntegrationInterface) => {
      return get(
        `/PartnerListByIntegration?warehuseIntegration=${warehuseIntegration}`,
      )
    },
  )

  return mutate
}

export default GetPartnerOrderListByIntegration
