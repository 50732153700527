/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react'
import CN from 'classnames'

import columnsStructure from './columnStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  className,
  tableVariables,
  setTableVariables,
  setIsLoading,
  setPageNumber,
  pageNumber,
  totalRowCount,
  setbulkreferance,
  FromHistory,
  setFromHistory,
  setWarehouseId,
  setWarehouseDetails,
  progressDetails,
  createPwShipmentConsignment,
  status,
  setStatus,
  setSummaryData,
  userDetails,

  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = useMemo(() => {
    // Start with the full columns structure
    let filteredColumns = [...columnsStructure]
    if (!FromHistory) {
      filteredColumns = filteredColumns.filter(
        (col) =>
          col.id !== 'consignedDate' &&
          col.id !== 'createdDate' &&
          col.id != 'user',
      )

      if (status != 'Completed') {
        filteredColumns = filteredColumns.filter((col) => col.id !== 'print')
      }
    } else {
      // Filter out 'ConsignedDate' if the status is 'Pending'
      if (status === 'Pending') {
        filteredColumns = filteredColumns.filter(
          (col) => col.id !== 'consignedDate' && col.id !== 'print',
        )
      }

      // Filter out 'CreatedDate' if the status is 'Completed'
      if (status === 'Completed') {
        filteredColumns = filteredColumns.filter(
          (col) => col.id !== 'createdDate',
        )
      }

      if (status === 'Failed') {
        filteredColumns = filteredColumns.filter(
          (col) =>
            col.id !== 'createdDate' &&
            col.id !== 'consignedDate' &&
            col.id !== 'print' &&
            col.id != 'user',
        )
      }

      if (status === 'InProgress' && FromHistory) {
        filteredColumns = filteredColumns.filter(
          (col) =>
            col.id !== 'createdDate' &&
            col.id !== 'consignedDate' &&
            col.id != 'user',
        )
      }

      if (status === 'InProgress' && !FromHistory) {
        filteredColumns = filteredColumns.filter(
          (col) =>
            col.id !== 'createdDate' &&
            col.id !== 'consignedDate' &&
            col.id !== 'print' &&
            col.id != 'user',
        )
      }
    }

    // Return the final filtered columns based on the status
    return filteredColumns
  }, [status])
  useEffect(() => {
    setData([...itemData])
  }, [itemData])

  return (
    <div className={CN('data-table w-full text-sm', className)} {...restProps}>
      <Table
        columns={columns}
        data={data}
        setTableVariables={setTableVariables}
        tableVariables={tableVariables}
        setIsLoading={setIsLoading}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        totalRowCount={totalRowCount}
        setbulkreferance={setbulkreferance}
        FromHistory={FromHistory}
        setFromHistory={setFromHistory}
        setWarehouseDetails={setWarehouseDetails}
        progressDetails={progressDetails}
        createPwShipmentConsignment={createPwShipmentConsignment}
        status={status}
        setStatus={setStatus}
        setSummaryData={setSummaryData}
        userDetails={userDetails}
      />
    </div>
  )
}

export default DataTable
