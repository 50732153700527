import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export const useConfirmOrderList = () => {
  const mutate = useMutation(
    ({
      warehouseOrderIds,
      principle,
    }: {
      warehouseOrderIds: any[]
      principle: any
    }) => {
      const confirmOrders = warehouseOrderIds.map((order: any) => ({
        warehouseOrderId: order.id, // Assuming ID in `selectedRowIds` is `WarehouseOrderId`
        orderNo: order.orderNo || '', // Fallback in case `orderNo` is missing
        defaultCarrierName: order.partnerOrder?.defaultCarrierName || '',
        carrierServiceProduct: order.partnerOrder?.carrierProduct || '',
        principle: principle || '',
        consNo: order.consNo || '',
      }))

      return postAPI(`PWBulkConsignment/ConfirmOrders`, { confirmOrders })
    },
  )

  return mutate
}

export default useConfirmOrderList
