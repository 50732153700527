import { useMutation } from 'react-query'
import { post } from 'framework/api/http'

interface PWBulkOrderConsignInterface {
  serviceSplitReference?: string
}

export const usePWBulkOrderConsign = () => {
  const mutate = useMutation(
    ({ serviceSplitReference }: PWBulkOrderConsignInterface) => {
      return post(`PWBulkConsignment/ConsignOrders`, {
        serviceSplitReference: serviceSplitReference,
      })
    },
  )

  return mutate
}

export default usePWBulkOrderConsign
