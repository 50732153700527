/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CN from 'classnames'

import { DatePicker } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { SimpleSelect } from 'components/atoms/Select'
import {
  useCreateOrderOnHold,
  useGetPartnerOrderOnholdReasonList,
} from 'components/pages/CustomerOrder/Orders/HoldOrderModal'

export interface HoldOrderModalProps {
  isActive?: boolean | undefined
  className?: string | undefined
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  selectedOrderId?: string | undefined
  callGetPartnerOrderDetailsById?: () => void | undefined
  getPartnerOrders?: () => void | undefined
}
export const HoldOrderModal: FC<HoldOrderModalProps> = ({
  className,
  isActive,
  onCloseClick,
  onOverlayClick,
  selectedOrderId,
  callGetPartnerOrderDetailsById,
  getPartnerOrders,
}: HoldOrderModalProps) => {
  const PopUpAlertClasses = CN(
    'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
    className,
    {
      hidden: !isActive,
    },
  )
  const [onHoldReason, setOnHoldReason] = useState<any>(null)
  const [releaseDate, setReleaseDate] = useState<null | string>(null)
  const [reasonList, setReasonList] = useState([])
  /** notification styles */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  const resetFields = () => {
    setOnHoldReason(null)
    setReleaseDate('')
  }
  /** API for get Partner Order Onhold Reason List */
  const { mutate: getPartnerOrderOnholdReasonListMutate } =
    useGetPartnerOrderOnholdReasonList()
  /** Process the get Partner Order Onhold Reason List  */
  async function getPartnerOrderOnholdReasonList() {
    getPartnerOrderOnholdReasonListMutate(
      { id: 1 },
      {
        onSuccess: ({ data: getHoldReasonList }: any) => {
          const reasons = getHoldReasonList.reasons.map((reasons: any) => {
            return {
              value: reasons.id,
              label: reasons.reason,
            }
          })
          setReasonList(reasons)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }
  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
      resetFields()
    }
  }
  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }
  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
      resetFields()
    }
  }
  useEffect(() => {
    isActive && getPartnerOrderOnholdReasonList()
  }, [isActive])
  /** API Call for create on hold using react-query */
  const {
    mutate: createOrderOnHoldMutate,
    isLoading: createOrderOnHoldIsLoading,
  } = useCreateOrderOnHold()
  /** Process the create on hold */
  async function createOrderOnHold() {
    createOrderOnHoldMutate(
      {
        partnerOrderId: selectedOrderId,
        reason: onHoldReason?.label,
        date: releaseDate || null,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          getPartnerOrders && getPartnerOrders()
          callGetPartnerOrderDetailsById && callGetPartnerOrderDetailsById()
          resetFields()
          onCloseClick && onCloseClick()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }
  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-1/3 bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Hold the Order</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        <div className='flex w-full p-4 max-w-[580px]'>
          <div className='flex w-full flex-col'>
            <div className='flex flex-col w-full'>
              <p className='text-sm font-medium text-N-700 mb-1'>
                Reason
                <span className='text-R-500 ml-1'>*</span>
              </p>
              <SimpleSelect
                options={reasonList}
                value={onHoldReason}
                onChange={(e: any) => {
                  setOnHoldReason({
                    label: e.label,
                    value: e.value,
                  })
                }}
              />
            </div>
            <div className='flex flex-col pt-6'>
              <span className='font-medium text-N-700 pb-2'>
                Date of Release
              </span>
              <DatePicker
                enabled={true}
                onChange={(e: any) => {
                  setReleaseDate(e.target.value)
                }}
                value={releaseDate}
                allowEdit={false}
                minDate={new Date()}
                isClearIcon={true}
                onClearClick={() => {
                  setReleaseDate('')
                }}
              />
            </div>
            {/* {releaseDate && (
              <div className='w-full text-right text-xs pr-1 mt-0.5'>
                <span
                  className='text-R-400 underline hover:text-R-600 cursor-pointer'
                  onClick={() => {
                    setReleaseDate('')
                  }}>
                  Clear
                </span>
              </div>
            )} */}
          </div>
        </div>
        <div className='w-full border-t border-t-N-200 px-5 py-4'>
          <Button
            className='w-[152px]'
            onClick={(e) => {
              createOrderOnHold()
            }}
            disabled={onHoldReason === null}
            isLoading={createOrderOnHoldIsLoading}>
            Hold
          </Button>
        </div>
      </div>
    </div>
  )
}
HoldOrderModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
  selectedOrderId: undefined,
  callGetPartnerOrderDetailsById: undefined,
  getPartnerOrders: undefined,
}
export default HoldOrderModal
