/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useDeleteWarehouse,
  useGetCountryList,
  useGetLabelList,
  useGetWarehouseTable,
} from 'framework/api/methods'

import { Button, PopUpAlert } from 'components/atoms'
import {
  AddWarehouse,
  UpdateWarehouse,
  WarehouseIntegration,
} from 'components/DrawerModals/Warehouse'

import { DataTable } from './WarehouseDataTable'

import 'react-toastify/dist/ReactToastify.css'

export interface WarehouseProps {
  [x: string]: any
}

export const Warehouse: FC<WarehouseProps> = ({
  className,
}: WarehouseProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)
  const editWarehouseAccess = userDetails?.userPermissions?.includes(
    'WarehouseEditAccess',
  )
  const [searchWareHouseName, setSearchWareHouseName] = useState('')
  const [rows, setRows] = useState({})
  const [countryList, setCountryList] = useState([])
  const [labelList, setLabelList] = useState<any>()
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [deleteWarehousePopOutIsOpen, setDeleteWarehousePopOutIsOpen] =
    useState(false)
  const [addWarehouseDrawerIsOpen, setAddWarehouseDrawerIsOpen] =
    useState(false)
  const [updateWarehouseDrawerIsOpen, setUpdateWarehouseDrawerIsOpen] =
    useState(false)
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    name: '',
    isActive: '',
  })
  const [selectedRowByButtonAction, setSelectedRowByButtonAction] = useState({
    id: '',
  })

  const [
    warehouseIntegrationDrawerIsOpen,
    setWarehouseIntegrationDrawerIsOpen,
  ] = useState(false)

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  /** API for get Country List */
  const { mutate: getCountryListMutate } = useGetCountryList()

  /** Process the Get Country Data List */
  async function getCountryList() {
    getCountryListMutate(
      { id: 1 },
      {
        onSuccess: ({ data: getCountryListData }: any) => {
          const countries = getCountryListData.countries.map((country: any) => {
            return {
              value: country.code,
              label: country.name,
            }
          })
          setCountryList(countries)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  const { mutate: GetLabelListMutate } = useGetLabelList()
  async function GetLabelList(): Promise<void> {
    GetLabelListMutate(
      { category: 'Warehouse', take: 100, skip: 0 },
      {
        onSuccess: ({ data: getLabelListData }: any) => {
          const labels = getLabelListData.labels.map((label: any) => {
            return {
              value: label.id,
              label: label.name,
            }
          })
          setLabelList(labels)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** API Call for get warehouses list using react-query */
  const { mutate: getWarehousesMutate, isLoading: getWarehousesListIsLoading } =
    useGetWarehouseTable()

  /** Process the get warehouses table data */
  async function getWarehousesList() {
    getWarehousesMutate(tableVariables, {
      onSuccess: ({ data: successData }: any) => {
        setRows(successData?.warehouses)
        setTotalRowCount(successData?.totalCount)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API for Delete Warehouse */
  const { mutate: deleteWarehouseMutate } = useDeleteWarehouse()

  /** Process the Delete Warehouse */
  async function deleteWarehouse(warehouseId: string) {
    deleteWarehouseMutate(
      { warehouseId },
      {
        onSuccess: ({ data: successData }: any) => {
          setDeleteWarehousePopOutIsOpen(false)
          notify(successData.message, 'success')
          getWarehousesList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
          setDeleteWarehousePopOutIsOpen(false)
        },
      },
    )
  }

  /** get country list when page loading */
  useEffect(() => {
    getCountryList()
  }, [])

  useEffect(() => {
    GetLabelList()
  }, [])

  /** call warehouses list when table variables change */
  useEffect(() => {
    getWarehousesList()
  }, [tableVariables])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setTableVariables({
        ...tableVariables,
        name: searchWareHouseName,
      })
    }
  }

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col h-full w-full rounded-lg bg-white shadow-md px-5 py-7'>
        <div className='flex flex-col w-full h-full bg-white'>
          <h2 className='text-lg font-semibold'>Warehouse List</h2>
          <div className='flex w-full items-center justify-between pt-6'>
            <div className='w-1/2'>
              {editWarehouseAccess && (
                <Button
                  onClick={() => {
                    setAddWarehouseDrawerIsOpen(true)
                  }}>
                  <i className='ri-add-circle-line pr-2'></i>Add New Warehouse
                </Button>
              )}
            </div>
            <div className='flex flex-col w-[432px] pl-2 relative'>
              <input
                onChange={(e: any) => {
                  setSearchWareHouseName(e.target.value)
                }}
                onKeyPress={(e: any) => {
                  handleSearchKeyPress(e)
                }}
                type='text'
                placeholder='Search by Warehouse name'
                className='pr-4 pl-11 py-2 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm mt-2'
              />
              <i
                className='ri-search-2-line absolute top-[15px] left-[22px] text-1xl text-N-500 cursor-pointer'
                onClick={() => {
                  setTableVariables({
                    ...tableVariables,
                    name: searchWareHouseName,
                  })
                }}></i>
            </div>
          </div>
          <div className='w-full h-[calc(100%-100px)] pt-4'>
            <DataTable
              itemData={rows}
              setTableVariables={setTableVariables}
              tableVariables={tableVariables}
              totalRowCount={totalRowCount}
              setUpdateWarehouseDrawerIsOpen={setUpdateWarehouseDrawerIsOpen}
              setSelectedRowByButtonAction={setSelectedRowByButtonAction}
              getWarehousesListIsLoading={getWarehousesListIsLoading}
              setDeleteWarehousePopOutIsOpen={setDeleteWarehousePopOutIsOpen}
              setWarehouseIntegrationDrawerIsOpen={
                setWarehouseIntegrationDrawerIsOpen
              }
            />
          </div>

          {editWarehouseAccess && addWarehouseDrawerIsOpen && (
            <AddWarehouse
              isActive={addWarehouseDrawerIsOpen}
              onCloseClick={() => setAddWarehouseDrawerIsOpen(false)}
              notify={notify}
              countryList={countryList}
              getWarehousesList={getWarehousesList}
              labelList={labelList}
            />
          )}

          {editWarehouseAccess && updateWarehouseDrawerIsOpen && (
            <UpdateWarehouse
              isActive={editWarehouseAccess && updateWarehouseDrawerIsOpen}
              onCloseClick={() => setUpdateWarehouseDrawerIsOpen(false)}
              selectedRowByButtonAction={selectedRowByButtonAction}
              getWarehousesList={getWarehousesList}
              notify={notify}
              countryList={countryList}
              labelList={labelList}
            />
          )}

          {editWarehouseAccess && deleteWarehousePopOutIsOpen && (
            <PopUpAlert
              isActive={editWarehouseAccess && deleteWarehousePopOutIsOpen}
              title='Delete Warehouse?'
              message='Are you sure you want to proceed? This is not reversible.'
              cancelButtonText='Cancel'
              confirmButtonText='Yes, I’m Sure'
              onCancelClick={() => setDeleteWarehousePopOutIsOpen(false)}
              onOverlayClick={() => setDeleteWarehousePopOutIsOpen(false)}
              onConfirmClick={() => {
                deleteWarehouse(selectedRowByButtonAction.id)
              }}
            />
          )}

          {editWarehouseAccess && warehouseIntegrationDrawerIsOpen && (
            <WarehouseIntegration
              isActive={warehouseIntegrationDrawerIsOpen}
              onCloseClick={() => setWarehouseIntegrationDrawerIsOpen(false)}
              selectedRowByButtonAction={selectedRowByButtonAction}
              notify={notify}
              getWarehousesList={getWarehousesList}
            />
          )}

          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
        </div>
      </div>
    </div>
  )
}

Warehouse.defaultProps = {}

export default Warehouse
