export const columns = [
  {
    customWidth: 128,
    Header: () => 'WMS Order #',
    accessor: 'orderNo',
    Cell: ({ row }) => <div>{row.original.orderNo}</div>,
  },
  {
    id: 'consNo',
    customWidth: 128,
    Header: 'Cons No',
    accessor: 'consNo',
    Cell: ({ row }) => <div>{row.original.consNo}</div>,
  },
  {
    id: 'noOfSKU',
    customWidth: 128,
    Header: 'No of SKU',
    accessor: 'noOfSKU',
    Cell: ({ row }) => (
      <div>{row.original.partnerOrder?.partnerOrderLines?.length ?? 0}</div>
    ),
  },
  {
    id: 'orderType',
    customWidth: 128,
    Header: () => 'Order Type',
    accessor: 'orderType',
    Cell: ({ row }) => (
      <div className='line-clamp-2'>
        {row.original?.partnerOrder?.orderType}
      </div>
    ),
  },
  {
    id: 'RDD',
    customWidth: 300,
    Header: () => 'RDD',
    accessor: 'RDD',
    Cell: ({ row }) => (
      <div className='line-clamp-2'>
        {row.original.partnerOrder?.dateWanted &&
        row.original.partnerOrder?.despatchBefore
          ? `${new Date(
              row.original.partnerOrder.dateWanted,
            ).toLocaleDateString('en-GB', {
              timeZone: 'Australia/Sydney',
            })} - ${new Date(
              row.original.partnerOrder.despatchBefore,
            ).toLocaleDateString('en-GB', { timeZone: 'Australia/Sydney' })}`
          : row.original.partnerOrder?.dateWanted
          ? new Date(row.original.partnerOrder.dateWanted).toLocaleDateString(
              'en-GB',
              { timeZone: 'Australia/Sydney' },
            )
          : row.original.partnerOrder?.despatchBefore
          ? new Date(
              row.original.partnerOrder.despatchBefore,
            ).toLocaleDateString('en-GB', { timeZone: 'Australia/Sydney' })
          : '-'}
      </div>
    ),
  },
  {
    id: 'carrierType',
    customWidth: 128,
    Header: () => 'Carrier Type',
    accessor: 'carrierType',
    Cell: ({ row }) => {
      const hasCarrierInfo =
        row.original?.partnerOrder?.defaultCarrierName &&
        row.original?.partnerOrder?.carrierProduct

      return (
        <div className='line-clamp-2'>
          {hasCarrierInfo ? (
            `${row.original?.partnerOrder?.defaultCarrierName} - ${row.original?.partnerOrder?.carrierProduct}`
          ) : (
            <button
              style={{
                backgroundColor: '#F1A248',
                color: 'white',
                border: 'none',
                padding: '8px 12px',
                borderRadius: '5px',
                cursor: 'pointer',
                WebkitAppearance: 'button', // Safari fix
                backgroundImage: 'none', // Remove background effects
                transition: 'background-color 0.3s ease', // Smooth hover effect
              }}
              onMouseEnter={(e) =>
                (e.target.style.backgroundColor = 'darkorange')
              } // Hover effect
              onMouseLeave={(e) => (e.target.style.backgroundColor = 'orange')} // Restore original color
            >
              Update
            </button>
          )}
        </div>
      )
    },
  },

  {
    id: 'destination',
    customWidth: 128,
    Header: () => 'Destination',
    accessor: 'destination',
    Cell: ({ row }) => (
      <div className='line-clamp-2'>
        {row.original?.partnerOrder?.shippingAddress?.countryCode}
      </div>
    ),
  },
  {
    id: 'dimension',
    customWidth: 128,
    Header: () => 'Dimensions',
    accessor: 'dimension',
    Cell: ({ row }) => (
      <div
        className='line-clamp-2 flex items-center'
        style={{
          overflow: 'visible',
          whiteSpace: 'normal',
        }}>
        {row.original?.shipments[0]?.packages[0]?.height &&
        row.original?.shipments[0]?.packages[0]?.width &&
        row.original?.shipments[0]?.packages[0]?.length ? (
          `${row.original?.shipments[0]?.packages[0]?.height} * ${row.original?.shipments[0]?.packages[0]?.width} * ${row.original?.shipments[0]?.packages[0]?.length}`
        ) : (
          <button
            style={{
              backgroundColor: '#F1A248', // Custom background color
              color: 'white', // Text color
              border: 'none', // Remove border
              padding: '8px 12px', // Button padding
              borderRadius: '5px', // Rounded corners
              cursor: 'pointer', // Ensure it's clickable
              WebkitAppearance: 'button', // Safari button styling fix
              backgroundImage: 'none', // Remove background effects
            }}>
            Update
          </button>
        )}
      </div>
    ),
  },
  {
    id: 'weight',
    customWidth: 128,
    Header: () => 'Weight',
    accessor: 'weight',
    Cell: ({ row }) => (
      <div className='line-clamp-2'>
        {row.original?.shipments[0]?.packages[0]?.weight ?? 0}
      </div>
    ),
  },
]

export default columns
