import { isAction } from '@reduxjs/toolkit'

import { ProgressSpinner } from 'components/atoms'

export const columns = [
  {
    customWidth: 128,
    Header: () => 'Reference',
    accessor: 'serviceSplitReference',
    Cell: ({ row }) => <div>{row.original.splitReferance}</div>,
  },
  {
    id: 'consNo',
    customWidth: 128,
    Header: 'Cons No',
    accessor: 'consNo',
    Cell: ({ row }) => <div>{row.original.consNo}</div>,
  },
  {
    id: 'principle',
    customWidth: 128,
    Header: 'Priniciple',
    accessor: 'principle',
    //Cell: ({ row }) => <div>{}</div>,
  },
  {
    id: 'createdDate',
    customWidth: 128,
    Header: () => 'Created Date',
    accessor: 'createdDate',
    Cell: ({ row }) => {
      // Only show Created Date if status is 'Pending'
      if (row.original.status === 'Pending') {
        return (
          <div className='line-clamp-2'>
            {new Date(row.original.confirmDate).toLocaleDateString('en-GB', {
              timeZone: 'Australia/Sydney',
            })}
          </div>
        )
      }
      return null // Don't show anything if the status isn't 'Pending'
    },
  },
  {
    id: 'consignedDate',
    customWidth: 128,
    Header: () => 'consigned Date',
    accessor: 'consignedDate',
    Cell: ({ row }) => {
      // Only show Consigned Date if status is 'Completed'
      if (
        row.original.status === 'Completed' ||
        row.original.status === 'Printed'
      ) {
        return (
          <div className='line-clamp-2'>
            {new Date(row.original.consignedDate).toLocaleString('en-GB', {
              timeZone: 'Australia/Sydney',
            })}
          </div>
        )
      }
      return null // Don't show anything if the status isn't 'Completed'
    },
  },
  {
    id: 'carrier',
    customWidth: 128,
    Header: () => 'Carrier',
    accessor: 'carrier',
    //Cell: ({ row }) => <div>{}</div>,
  },

  {
    id: 'numberOfOrders',
    customWidth: 128,
    Header: () => '# Orders',
    accessor: 'numberOfOrders',
    Cell: ({ row }) => (
      <div className='line-clamp-2'>{row.original.numberOfOrders}</div>
    ),
  },
  {
    id: 'user',
    customWidth: 128,
    Header: () => 'User',
    accessor: 'user',
    Cell: ({ row }) => <div className='line-clamp-2'>{row.original.user}</div>,
  },
  {
    id: 'action',
    customWidth: 128,
    Header: () => 'Action',
    accessor: 'action',
    isAction: true,
    Cell: () => true,
  },
  {
    id: 'status',
    customWidth: 128,
    Header: () => 'Status',
    accessor: 'status',
    Cell: () => true,
  },
  {
    id: 'print',
    customWidth: 128,
    Header: () => 'Print',
    accessor: 'print',
    Cell: () => true,
    //Cell: ({ row }) => <div className='line-clamp-2'>{}</div>,
  },
]

export default columns
