/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import { usePWBulkOrderConsign } from 'framework/api/methods'
import useGetWarehouseById from 'framework/api/methods/warehouse/useGetWarehouseById'
import {
  addWebSocketEventListener,
  connectWebSocket,
} from 'framework/websocket/ws'

import { Button, Input, JsonLoader, SimpleSelect } from 'components/atoms'
import { DataTable as BulkSummaryTable } from 'components/pages/BulkOrderConsignmentNew/PWBulkConsignment/Tables/BulkSummaryDataTable'

export interface PWBulkConsignmentSummaryProps {
  [x: string]: any
}

export const PWBulkConsignmentSummary: FC<PWBulkConsignmentSummaryProps> = ({
  className,
  isActive,
  onCloseClick,
  seBulkConsignmentMenuIsOpen,
  setPWBulkConsignmentIsOpen,
  setHistoryIsOpen,
  setSummaryIsOpen,
  summaryData,
  FromHistory,
  notify,
  warehouseDetails,
  setWarehouseId,
  getWarehouseById,
  setWarehouseDetails,
  setSummaryData,
  userDetails,
  totalRowCount,
  setFromHistory,
}: PWBulkConsignmentSummaryProps) => {
  const [mainLoadingState, setIsLoading] = useState(false)
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    search: '',
  })
  const [progressDetails, setProgress] = useState([
    {
      splitReferance: '',
      progress: 0,
    },
  ])
  const [status, setStatus] = useState('Pending')

  const [bulkReferance, setbulkreferance] = useState()

  const [pageNumber, setPageNumber] = useState([1])
  const [editable, setEditable] = useState(false) // State to track whether the component is editable

  // Function to toggle between editable and non-editable states
  const toggleEdit = () => {
    setEditable((prevEditable) => !prevEditable) // Toggles the state value
  }
  const address = `
  ${warehouseDetails.address.lineOne || ''}
  ${
    warehouseDetails.address.lineTwo
      ? ` ${warehouseDetails.address.lineTwo}`
      : ''
  }
  ${
    warehouseDetails.address.stateCode
      ? ` ${warehouseDetails.address.stateCode}`
      : ''
  }
  ${
    warehouseDetails.address.countryCode
      ? ` ${warehouseDetails.address.countryCode}`
      : ''
  }
  ${
    warehouseDetails.address.postCode
      ? ` ${warehouseDetails.address.postCode}`
      : ''
  }
`.trim()

  /** API Call for Bulk Order Consign */
  const {
    mutate: pwbulkOrderConsignMutate,
    isLoading: pwbulkOrderConsignIsLoading,
  } = usePWBulkOrderConsign()

  /** Process the Bulk Order Consign */
  async function createPwShipmentConsignment(
    splitReferance: string,
  ): Promise<void> {
    pwbulkOrderConsignMutate(
      {
        serviceSplitReference: splitReferance,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          addWebSocketEventListener('progress', (message: any) => {
            // Log the entire WebSocket message to verify its structure
            console.log('📩 WebSocket message received:', message)
            if (typeof message === 'string') {
              message = JSON.parse(message)
            }

            // Destructure progress and splitReferance from the message
            const { progress: newProgress, splitReferance } = message

            // Log the extracted values to ensure they are correct
            console.log('Destructured Progress:', newProgress)
            console.log('Destructured Split Referance:', splitReferance)

            // Check if progress and splitReferance are not undefined
            if (newProgress !== undefined && splitReferance !== undefined) {
              // Update the progress state for the specific splitReferance
              setProgress((prev) => ({
                ...prev,
                [splitReferance]: newProgress, // Use splitReferance as the key and progress as the value
              }))
            }
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  useEffect(() => {
    // Connect to WebSocket
    connectWebSocket('ws') // Replace 'ws' with your actual WebSocket controller
  }, [])
  return (
    <div
      className={CN(
        'flex items-start justify-center bg-white h-full w-full pt-4 px-8 pb-0',
        {},
        className,
      )}>
      {/* Loading Screen */}
      {mainLoadingState && (
        <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[99] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
          <JsonLoader size='xs' />
        </div>
      )}

      <div className='flex flex-col w-full h-full bg-white overflow-y-auto styled-scroll'>
        <div className='flex flex-col w-full h-auto bg-white rounded-lg px-4 py-2'>
          <Button
            className='mb-1'
            size='xs'
            appearance='outline'
            onClick={() => {
              if (FromHistory) {
                seBulkConsignmentMenuIsOpen(false)
                setHistoryIsOpen(true)
                setSummaryIsOpen(false)
                setPWBulkConsignmentIsOpen(false)
              } else {
                seBulkConsignmentMenuIsOpen(false)
                setHistoryIsOpen(false)
                setSummaryIsOpen(false)
                setPWBulkConsignmentIsOpen(true)
              }
            }}>
            <i className='ri-arrow-left-line pr-3'></i> Back to bulk order
            consignment
          </Button>
          <div className='flex flex-col w-full h-auto bg-white rounded-lg px-0 py-5 overflow: visible'>
            <div className='w-[700px] h-[35px] bg-P-100 rounded-[8px] flex justify-between items-center text-black font-[14px] px-4 font-semibold'>
              <div className='flex items-center'>
                <h2 className='mr-2'>Shipper Details</h2>
                {/*<i className='ri-edit-line text-[18px]'></i>{' '}*/}
                {/* Icon size adjusted */}
              </div>
            </div>
            <div className='flex flex-col w-full mb-4 py-2 z-50 px-4'>
              <div className='flex flex-row w-full gap-x-5'>
                <div className='flex flex-col w-1/2 z-[50]'>
                  <span className='pb-1 text-N-700 font-semibold'>
                    Name:
                    <span className='ml-2 text-N-700 font-thin'>
                      {warehouseDetails.name}
                    </span>
                  </span>
                </div>
                <div className='flex flex-col w-1/2 z-[50]'>
                  <span className='pb-1 text-N-700 font-semibold'>
                    Phone:
                    <span className='ml-2 text-N-700 font-thin'>
                      {warehouseDetails.phoneNo}
                    </span>
                  </span>
                </div>
              </div>
              <div className='flex flex-row w-full gap-x-5 gap-y-5'>
                <div className='flex flex-col w-1/2 z-[50]'>
                  <span className='pb-1 text-N-700 font-semibold'>
                    Address:
                    <span className='ml-2 text-N-700  font-thin'>
                      {address}
                    </span>
                  </span>
                </div>
                <div className='flex flex-col w-1/2 z-[50]'>
                  <span className='pb-1 text-N-700 font-semibold'>
                    Email:
                    <span className='ml-2 text-N-700 font-thin'>
                      {warehouseDetails.email}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col w-full h-auto bg-white rounded-lg px-0 py-2 overflow: visible'>
            <BulkSummaryTable
              itemData={summaryData}
              className=''
              setTableVariables={setTableVariables}
              tableVariables={tableVariables}
              totalRowCount={totalRowCount}
              setIsLoading={false}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              setbulkreferance={setbulkreferance}
              FromHistory={FromHistory}
              setFromHistory={setFromHistory}
              setWarehouseId={setWarehouseId}
              setWarehouseDetails={setWarehouseDetails}
              progressDetails={progressDetails}
              createPwShipmentConsignment={createPwShipmentConsignment}
              status={status}
              setStatus={setStatus}
              setSummaryData={setSummaryData}
              userDetails={userDetails}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

PWBulkConsignmentSummary.defaultProps = {}

export default PWBulkConsignmentSummary
