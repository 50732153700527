import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import orderConsignmentVector from 'assets/images/order-consignment-vector.svg'
import CN from 'classnames'

import {
  BulkScanButton,
  HistoryButton,
  ICSBulkConsignment,
  PWBulkConsignment,
} from 'components/atoms'

import BulkOrderConsignment from '../BulkOrderConsignment/BulkOrderConsignment'

import BulkConsignmentHistory from './PWBulkConsignment/BulkConsignmentHistory'
import PWBulkConsignmentSummary from './PWBulkConsignment/PWBulkConsigmentSummary'
import PWBulkOrderConsignment from './PWBulkConsignment/PWBulkOrderConsignment'

import 'react-toastify/dist/ReactToastify.css'

export interface BulkOrderConsignmentMenuProps {
  [x: string]: any
}

const customizeClass = CN(
  'w-[350px] px-6 py-4 text-lg font-semibold text-white rounded-lg hover:bg-blue-700 flex items-start justify-start',
)

export const BulkOrderConsignmentMenu: FC<BulkOrderConsignmentMenuProps> = ({
  className,
}: BulkOrderConsignmentMenuProps) => {
  const [PWBulkCOnsignmentIsOpen, setPWBulkConsignmentIsOpen] = useState(false)
  const [BulkConsignmentMenuIsOpen, seBulkConsignmentMenuIsOpen] =
    useState(true)

  const [HistoryIsOpen, setHistoryIsOpen] = useState(false)
  const [isSummaryOpen, setSummaryIsOpen] = useState(false)
  const [summaryData, setSummaryData] = useState([])
  const [warehouseId, setWarehouseId] = useState<any>()
  const [FromHistory, setFromHistory] = useState(false)
  const [warehouseDetails, setWarehouseDetails] = useState<any>(false)
  const [totalRowCount, setTotalRowCount] = useState(0)

  const handleOpenPWBulkConsignment = () => {
    setPWBulkConsignmentIsOpen(true)
    seBulkConsignmentMenuIsOpen(false)
    setHistoryIsOpen(false)
  }

  const handleOpenHistory = () => {
    setPWBulkConsignmentIsOpen(false)
    seBulkConsignmentMenuIsOpen(false)
    setHistoryIsOpen(true)
  }
  const { userDetails }: any = useSelector<any>((state) => state.user)

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  return (
    <div
      className={CN(
        'relative flex items-center justify-start bg-N-50 h-full w-full pt-4 px-8 pb-4',
        className,
      )}>
      {/* Button Container - Positioned to the Left Center */}
      <div
        className='bg-white bg-opacity-70 p-6 rounded-lg shadow-md flex flex-col gap-10 absolute inset-4 max-w-screen max-h-screen overflow-auto'
        style={{
          backgroundImage: orderConsignmentVector
            ? `url(${orderConsignmentVector})`
            : 'none',
          backgroundSize: '1050px', // Adjust this value to control the image size
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'calc(100% - 30px) calc(100% - 70px)', // Moves the image closer to the bottom-right corner with some margin
        }}>
        {BulkConsignmentMenuIsOpen && (
          <div className='flex flex-col gap-4 pt-5'>
            {/*<ICSBulkConsignment
              disabled={false}
              isLoading={false}
              className={customizeClass}>
              ICS WMS
            </ICSBulkConsignment>*/}

            <PWBulkConsignment
              disabled={false}
              isLoading={false}
              className={customizeClass}
              onClick={() => {
                handleOpenPWBulkConsignment()
              }}>
              Paperless WMS
            </PWBulkConsignment>

            {/*<BulkScanButton
              disabled={false}
              isLoading={false}
              className={customizeClass}>
              Bulk Scan
            </BulkScanButton>*/}

            <HistoryButton
              disabled={false}
              isLoading={false}
              onClick={() => {
                handleOpenHistory()
              }}
              className='w-[200px] px-2 py-4 text-lg font-semibold text-white rounded-lg hover:bg-blue-700 flex items-start justify-start absolute bottom-10 left-10'>
              History
            </HistoryButton>
          </div>
        )}

        {PWBulkCOnsignmentIsOpen && (
          <PWBulkOrderConsignment
            isActive={PWBulkCOnsignmentIsOpen}
            onCloseClick={() => setPWBulkConsignmentIsOpen(false)}
            seBulkConsignmentMenuIsOpen={seBulkConsignmentMenuIsOpen}
            setPWBulkConsignmentIsOpen={setPWBulkConsignmentIsOpen}
            setHistoryIsOpen={setHistoryIsOpen}
            setSummaryIsOpen={setSummaryIsOpen}
            setSummaryData={setSummaryData}
            PWBulkCOnsignmentIsOpen={PWBulkCOnsignmentIsOpen}
            notify={notify}
            FromHistory={FromHistory}
            setFromHistory={setFromHistory}
            setWarehouseDetails={setWarehouseDetails}
            userDetails={userDetails}
            setTotalRowCount={setTotalRowCount}
          />
        )}

        {HistoryIsOpen && (
          <BulkConsignmentHistory
            isActive={HistoryIsOpen}
            onCloseClick={() => setPWBulkConsignmentIsOpen(false)}
            seBulkConsignmentMenuIsOpen={seBulkConsignmentMenuIsOpen}
            setPWBulkConsignmentIsOpen={setPWBulkConsignmentIsOpen}
            setHistoryIsOpen={setHistoryIsOpen}
            setWarehouseId={setWarehouseId}
            setSummaryIsOpen={setSummaryIsOpen}
            setSummaryData={setSummaryData}
            notify={notify}
            FromHistory={true}
            setFromHistory={setFromHistory}
            setWarehouseDetails={setWarehouseDetails}
            userDetails={userDetails}
            totalRowCount={totalRowCount}
          />
        )}

        {isSummaryOpen && (
          <PWBulkConsignmentSummary
            isActive={isSummaryOpen}
            onCloseClick={false}
            seBulkConsignmentMenuIsOpen={seBulkConsignmentMenuIsOpen}
            setPWBulkConsignmentIsOpen={setPWBulkConsignmentIsOpen}
            setHistoryIsOpen={setHistoryIsOpen}
            setSummaryIsOpen={setSummaryIsOpen}
            summaryData={summaryData}
            notify={notify}
            FromHistory={false}
            setFromHistory={setFromHistory}
            warehouseDetails={warehouseDetails}
            setWarehouseId={setWarehouseId}
            warehouseId={warehouseId}
            setWarehouseDetails={setWarehouseDetails}
            setSummaryData={setSummaryData}
            userDetails={userDetails}
            totalRowCount={totalRowCount}
          />
        )}
      </div>

      {/* Toaster */}
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
    </div>
  )
}

BulkOrderConsignmentMenu.defaultProps = {}

export default BulkOrderConsignmentMenu
