import { useMutation } from 'react-query'
import { get, postAPI } from 'framework/api/http'

interface GetPWBulkOrderListInterface {
  take?: number
  skip?: number
  search?: string
  filterVariables?: any
  isFetchAll?: boolean
}

export const useGetPWBulkOrderList = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      search,
      filterVariables,
      isFetchAll,
    }: GetPWBulkOrderListInterface) => {
      return postAPI(`/PWBulkOrderList`, {
        take,
        skip,
        SearchKeyword: search === '' ? null : search,
        isFetchAll,
        principleCode: filterVariables?.principle?.value,
        consStatus: filterVariables?.consStatus?.value,
        consNumber: filterVariables?.consNumber,
        rddFrom: filterVariables?.rddFrom,
        rddTo: filterVariables?.rddTo,
        courrierType: filterVariables?.courrierType?.value,
        weightStatus: filterVariables?.weight?.value,
        dimensionStatus: filterVariables?.dimensions?.value,
        consNo: filterVariables?.consNo,
        orderTypes:
          filterVariables?.orderTypes?.map(
            (item: { label: any }) => item.label,
          ) || [],
      })
    },
  )

  return mutate
}

export default useGetPWBulkOrderList
