/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { toast } from 'react-toastify'
import CN from 'classnames'
import { useGetInboundOrderCutOffCSV } from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { SimpleSelect } from 'components/atoms/Select'

import './AdvanceSearchModel.scss'

export interface AdvanceSearchModelProps {
  isActive?: boolean | undefined
  className?: string | undefined
  isCustomStyles?: boolean | undefined
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  setFilterVariables?: any
  filterVariables?: any
  searchKeyword?: any
  setPageNumber?: any
  getInboundorderCutOffList?: any
  tableVariables?: any
}
export const AdvanceSearchModal: FC<AdvanceSearchModelProps> = ({
  className,
  isActive,
  isCustomStyles,
  onCloseClick,
  onOverlayClick,
  setFilterVariables,
  filterVariables,
  setPageNumber,
  getInboundorderCutOffList,
  tableVariables,
}: AdvanceSearchModelProps) => {
  const csvLinkEl = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)
  const [selectedCriteria, setSelectedCriteria] = useState<any>(
    filterVariables.criteria,
  )
  const [selectedStatus, setSelectedStatus] = useState<any>(
    filterVariables.status,
  )
  const [csvOrderData, setCsvOrderData] = useState([])
  /** Handling Click overlay */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const criteriaList = [
    { label: 'Type Of Container', value: 'Type Of Container' },
    { label: 'Volume', value: 'Volume' },
  ]
  const statusList = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]
  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }
  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }
  const handleApplyFilter = (event: any) => {
    setPageNumber(1)
    event.stopPropagation()
    setFilterVariables({
      ...filterVariables,
      criteria: selectedCriteria,
      status: selectedStatus,
    })
    getInboundorderCutOffList(
      tableVariables.take,
      tableVariables.skip,
      tableVariables.search,
      selectedCriteria,
      selectedStatus,
    )
    if (onCloseClick) {
      onCloseClick()
    }
  }
  const onResetClick = () => {
    setPageNumber(1)
    setFilterVariables({
      ...filterVariables,
      criteria: { label: 'All', value: '' },
      status: { label: 'All', value: '' },
    })
    getInboundorderCutOffList(
      tableVariables.take,
      tableVariables.skip,
      tableVariables.search,
      { label: 'All', value: '' },
      { label: 'All', value: '' },
    )
    setSelectedCriteria({ label: 'All', value: '' })
    setSelectedStatus({ label: 'All', value: '' })
    /*if (onCloseClick) {
      onCloseClick()
    }*/
  }
  /** Handling filtering orders Export as Csv */
  const handleExportCSV = () => {
    /** call process the orders Export as CSV */
    getOrdersExportCSV(selectedCriteria, selectedStatus)
  }
  /** Process the orders Export as CSV */
  async function getOrdersExportCSV(criteria: any, status: any) {
    getInboundOrderCutOffCSVMutate(
      { criteria, status },
      {
        onSuccess: ({ data: successData }: any) => {
          /** set data to csv document */
          setCsvOrderData(successData)
          /** trigger csv element */
          csvLinkEl?.current?.link.click()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }
  /** API Call for filtering file vs order export as CSV using react-query */
  const { mutate: getInboundOrderCutOffCSVMutate } =
    useGetInboundOrderCutOffCSV()
  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className
  /** notification styles */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-fit bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Inbound Order KPI</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        <div className='flex w-full pt-6 px-4'>
          <div className='flex w-2/3 flex-col'>
            <div className='flex flex-col'>
              <p className='text-sm font-medium text-N-700 pb-2'>By Criteria</p>
              <SimpleSelect
                options={[{ label: 'All', value: '' }, ...criteriaList]}
                value={selectedCriteria}
                onChange={(e: any) => {
                  setSelectedCriteria(e)
                }}
              />
            </div>
          </div>
        </div>
        <div className='flex w-full pt-6 px-4'>
          <div className='flex w-2/3 flex-col'>
            <div className='flex flex-col'>
              <p className='text-sm font-medium text-N-700 pb-2'>Status</p>
              <SimpleSelect
                options={[{ label: 'All', value: '' }, ...statusList]}
                value={selectedStatus}
                onChange={(e: any) => {
                  setSelectedStatus(e)
                }}
              />
            </div>
          </div>
        </div>
        <div className='w-full border-t border-t-N-200 mt-[116px] px-5 py-4'>
          <Button
            className='w-[152px]'
            onClick={(e) => handleApplyFilter(e)}
            disabled={false}
            isLoading={false}>
            Apply
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onResetClick}>
            Reset Filters
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={() => {
              handleExportCSV()
            }}>
            Export CSV
          </Button>
          {/* for csv export */}
          <CSVLink
            filename='Inbound_order_cutoff.csv'
            data={csvOrderData}
            ref={csvLinkEl}
          />
        </div>
      </div>
    </div>
  )
}
AdvanceSearchModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
}
export default AdvanceSearchModal
