import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateShippingQtyInterface {
  shipmentId?: string
  shipmentLineId?: string
  qty: number
}

export const useUpdateShippingQty = () => {
  const mutate = useMutation(
    ({ shipmentId, shipmentLineId, qty }: UpdateShippingQtyInterface) => {
      const payload = {
        ShipmentId: shipmentId, // Change from 'type' to 'Type'
        ShipmentLineId: shipmentLineId,
        ShippingQty: Number(qty),
      }
      // Call the API
      return putAPI(`/shippingQty/update`, payload)
    },
  )

  return mutate
}

export default useUpdateShippingQty
