/* eslint-disable import/no-extraneous-dependencies */
import { FC, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useGetPWBulkConsignmentHistory,
  useGetWarehouseById,
  usePWBulkOrderConsign,
  useUserList,
} from 'framework/api/methods'
import useGetPartnerListByIntegration from 'framework/api/methods/partner/useGetPartnerListByIntegration'
import useGetBulkConsignmentUsers from 'framework/api/methods/PWbulkConsignment/useGetBulkConsignmentUsers'
import { addWebSocketEventListener } from 'framework/websocket/ws'

import { Button, Input, JsonLoader, SimpleSelect } from 'components/atoms'
import { DataTable as BulkHistoryTable } from 'components/pages/BulkOrderConsignmentNew/PWBulkConsignment/Tables/BulkSummaryDataTable'

export interface BulkConsignmentHistoryProps {
  [x: string]: any
}

export const BulkConsignmentHistory: FC<BulkConsignmentHistoryProps> = ({
  className,
  isActive,
  onCloseClick,
  notify,
  setHistoryIsOpen,
  seBulkConsignmentMenuIsOpen,
  setPWBulkConsignmentIsOpen,
  setSummaryIsOpen,
  setSummaryData,
  FromHistory,
  setFromHistory,
  setWarehouseDetails,
  userDetails,
}: BulkConsignmentHistoryProps) => {
  const [isFilterMenuOpen, setIsFilterModalOpen] = useState(true)
  const [filterVariables, setFilterVariables] = useState<any>({
    principle: { label: 'Signal Brands', value: '42167' },
    consNo: '',
    consignedFrom: '',
    consignedTo: '',
    createdFrom: '',
    createdTo: '',
    user: '',
  })
  const [filterActive, setFilterActive] = useState(false)
  const [isResetFilter, setIsResetFilter] = useState(false)
  const [status, setStatus] = useState('Pending')
  const [mainLoadingState, setIsLoading] = useState(false)

  const [historyList, setHistoryList] = useState([])
  const [totalRowCount, setTotalRowCount] = useState(0)

  const [userList, setUserList] = useState([])

  const [warehouseId, setWarehouseId] = useState<any>()
  const [progressDetails, setProgress] = useState([
    {
      splitReferance: '',
      progress: 0,
    },
  ])

  const [value, setValue] = useState(0)
  const [userListvariables, setUserListvariables] = useState({
    skip: null,
    take: null,
    Search: null,
  })

  const theme = createTheme({
    palette: {
      primary: {
        main: '#CBD5E1',
      },
      secondary: {
        main: '##F1A248',
      },
    },
  })

  /** API Call for Bulk Order Consign */
  const {
    mutate: pwbulkOrderConsignMutate,
    isLoading: pwbulkOrderConsignIsLoading,
  } = usePWBulkOrderConsign()

  /** Process the Bulk Order Consign */
  async function createPwShipmentConsignment(
    splitReferance: string,
  ): Promise<void> {
    pwbulkOrderConsignMutate(
      {
        serviceSplitReference: splitReferance,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    switch (newValue) {
      case 0:
        setStatus('Pending')
        setPageNumber(1)
        break
      case 1:
        setStatus('InProgress')
        setPageNumber(1)
        break
      case 2:
        setStatus('Completed')
        setPageNumber(1)
        break
      case 3:
        setStatus('Failed')
        setPageNumber(1)
        break
      default:
        setStatus('')
        setPageNumber(1)
        break
    }
  }

  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    search: '',
  })
  const [pageNumber, setPageNumber] = useState(1)

  const [partnerList, setPartnerList] = useState([])
  const [bulkReferance, setbulkReferance] = useState()

  /** API Call for get partner list function using react-query */
  const {
    mutate: GetPartnerOrderListByIntegrationMutate,
    isLoading: getPartnerTableIsLoading,
  } = useGetPartnerListByIntegration()

  /** Process to get partner List for integration */
  async function getPartnerListByIntegration(): Promise<void> {
    GetPartnerOrderListByIntegrationMutate(
      { warehuseIntegration: 'PaperlessWMS' },
      {
        onSuccess: ({ data: successData }: any) => {
          const result: any = []
          successData.partners.forEach((item: any) => {
            if (item.isActive) {
              result.push({ label: item.name, value: item?.code, id: item?.id })
            }
          })
          setPartnerList(result)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  /** API Call for Get History List */
  const {
    mutate: getPWBulkConsignmentHistoryListMutate,
    //isLoading: getPWBulkConsignmentHistoryListIsLoading,
  } = useGetPWBulkConsignmentHistory()

  /** Process the Get Similar Order List */
  async function getPWBulkConsignmentHistoryList() {
    setIsLoading(true)
    getPWBulkConsignmentHistoryListMutate(
      {
        take: tableVariables.take,
        skip: tableVariables.skip,
        status,
        bulkReferance,
        filterVariables,
        splitReferance: '',
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setHistoryList(successData?.summaryList)
          setTotalRowCount(successData?.totalCount)
          if (bulkReferance) {
            setSummaryData(successData?.summaryList)
            setSummaryIsOpen(true)
            setHistoryIsOpen(false)
          }
          if (status == 'InProgress') {
            addWebSocketEventListener('progress', (message: any) => {
              // Log the entire WebSocket message to verify its structure
              console.log('📩 WebSocket message received:', message)
              if (typeof message === 'string') {
                message = JSON.parse(message)
              }

              // Destructure progress and splitReferance from the message
              const { progress: newProgress, splitReferance } = message

              // Log the extracted values to ensure they are correct
              console.log('Destructured Progress:', newProgress)
              console.log('Destructured Split Referance:', splitReferance)

              // Check if progress and splitReferance are not undefined
              if (newProgress !== undefined && splitReferance !== undefined) {
                // Update the progress state for the specific splitReferance
                setProgress((prev) => ({
                  ...prev,
                  [splitReferance]: newProgress, // Use splitReferance as the key and progress as the value
                }))
              }
            })
          }
          setIsLoading(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
          setIsLoading(false)
        },
      },
    )
  }

  const {
    mutate: getPWBulkConsignmetUsersMutate,
    //isLoading: getPWBulkConsignmentHistoryListIsLoading,
  } = useGetBulkConsignmentUsers()

  async function getPWBulkConsignmentUsers() {
    try {
      setIsLoading(true)

      getPWBulkConsignmetUsersMutate(undefined, {
        onSuccess: (successData: any) => {
          const result =
            successData?.data?.userProfiles?.map((item: any) => ({
              label: `${item.firstName} ${item.lastName}`,
              value: item?.userName,
            })) || []

          setUserList(result)
          setIsLoading(false)
        },
        onError: (error: any) => {
          const message =
            error?.response?.data?.message || 'Something went wrong.'
          notify(message, 'error')
          setIsLoading(false)
        },
      })
    } catch (err) {
      notify('An unexpected error occurred.', 'error')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getPWBulkConsignmentHistoryList()
  }, [tableVariables.skip, tableVariables.take, tableVariables.search, status])

  useEffect(() => {
    setIsLoading(true)
    //getPartnerListByIntegration()
    getPWBulkConsignmentHistoryList()
    //getPWBulkConsignmentUsers()
    setIsLoading(false)
  }, [])

  useEffect(() => {
    getPWBulkConsignmentHistoryList()
  }, [bulkReferance])

  useEffect(() => {
    getPWBulkConsignmentHistoryList()
  }, [status])

  function FormatDate(dateString: string) {
    const dateObject = new Date(dateString)
    const year = dateObject.getFullYear()
    const month = dateObject.getMonth() + 1 // getMonth() returns month index (0-11), so add 1 for the actual month
    const day = dateObject.getDate()
    const formattedDate = `${year}/${month.toString().padStart(2, '0')}/${day
      .toString()
      .padStart(2, '0')}`
    return formattedDate
  }

  return (
    <div
      className={CN(
        'flex items-start justify-center bg-white h-full w-full pt-4 px-8 pb-0',
        {},
        className,
      )}>
      {/* Loading Screen */}
      {mainLoadingState && (
        <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[99] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
          <JsonLoader size='xs' />
        </div>
      )}

      <div className='flex flex-col w-full h-full bg-white overflow-y-auto styled-scroll'>
        <div className='flex flex-col w-full h-auto bg-white rounded-lg px-4 py-2 pb-8'>
          <Button
            className='mb-1'
            size='xs'
            appearance='outline'
            onClick={() => {
              seBulkConsignmentMenuIsOpen(true)
              setPWBulkConsignmentIsOpen(false)
              setHistoryIsOpen(false)
            }}>
            <i className='ri-arrow-left-line pr-3'></i> Back to bulk order
            consignment
          </Button>
          <div className='flex w-full items-center justify-between pt-12'></div>
          {/*
          <div className='flex w-full items-center justify-between pt-6'>
            <Button
              onClick={() => {
                setIsFilterModalOpen(!isFilterMenuOpen)
              }}
              appearance={isFilterMenuOpen ? 'default' : 'outline'}>
              <i className='ri-filter-3-line pr-3'></i> Filter
            </Button>
            {filterActive && (
              <div className='w-[6px] h-[2px] bg-R-500 rounded-full absolute top-[0px] right-[-2px]'></div>
            )}
          </div>
          */}

          {/*<AnimatePresence initial={false}>
            {isFilterMenuOpen && (
              <motion.div
                className='flex flex-col bg-white w-full'
                initial='collapsed'
                animate='open'
                exit='collapsed'
                transition={{ type: 'tween', duration: 0.3 }}
                variants={{
                  open: { opacity: 1, y: 0, height: 'auto' },
                  collapsed: { opacity: -1, y: -35, height: 0 },
                }}>

                <div className='flex flex-col w-full mb-4 py-2 z-50'>
                  <div className='flex flex-row w-full gap-x-5'>
                    <div className='flex flex-col w-1/6 z-[50]'>
                      <span className='pb-1 text-N-700'>Principle</span>
                      <SimpleSelect
                        value={filterVariables.principle}
                        options={[{ label: 'All', value: '' }, ...partnerList]}
                        onChange={(e: any) => {
                          setFilterVariables({
                            ...filterVariables,
                            principle: {
                              label: e.label,
                              value: e.value,
                            },
                          })
                        }}
                      />
                    </div>
                    <div className='flex flex-col w-1/6 z-[50]'>
                      <span className='pb-1 text-N-700'>Cons No</span>
                      <Input
                        value={filterVariables?.consNo || ''} // Ensure it's not undefined
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const numericValue = e.target.value.replace(
                            /[^0-9]/g,
                            '',
                          )
                          setFilterVariables((prev: any) => ({
                            ...filterVariables,
                            consNo: numericValue, // Get the correct value from the input
                          }))
                        }}
                      />
                    </div>
                    <div className='flex flex-col w-1/6 z-[50]'>
                      <span className='pb-1 text-N-700'>User</span>
                      <SimpleSelect
                        value={filterVariables.user}
                        options={[{ label: 'All', value: '' }, ...userList]}
                        onChange={(e: any) => {
                          setFilterVariables({
                            ...filterVariables,
                            user: {
                              label: e.label,
                              value: e.value,
                            },
                          })
                        }}
                      />
                    </div>
                    {status === 'Completed' && (
                      <div className='flex flex-col w-1/6 z-[50]'>
                        <span className='pb-1 text-N-700'>Consigned Date</span>
                        <div className='date-picker mt-0'>
                          <DateRangePickerComponent
                            format='yyyy/MM/dd'
                            delayUpdate={true}
                            placeholder='Select a range'
                            onChange={(e: any) => {
                              setFilterVariables({
                                ...filterVariables,
                                consignedFrom: FormatDate(e.target.startDate),
                                consignedTo: FormatDate(e.target.endDate),
                              })
                            }}
                            startDate={filterVariables?.consignedFrom}
                            endDate={filterVariables?.consignedTo}
                          />
                        </div>
                      </div>
                    )}
                    {status === 'Completed' && (
                      <div className='flex flex-col w-1/6 z-[50]'>
                        <span className='pb-1 text-N-700'>Consigned Date</span>
                        <div className='date-picker mt-0'>
                          <DateRangePickerComponent
                            format='yyyy/MM/dd'
                            delayUpdate={true}
                            placeholder='Select a range'
                            onChange={(e: any) => {
                              setFilterVariables({
                                ...filterVariables,
                                consignedFrom: FormatDate(e.target.startDate),
                                consignedTo: FormatDate(e.target.endDate),
                              })
                            }}
                            startDate={filterVariables?.consignedFrom}
                            endDate={filterVariables?.consignedTo}
                          />
                        </div>
                      </div>
                    )}

                    <div className='flex items-end gap-3 w-1/6 z-[50] '>
                      <Button
                        onClick={() => {
                          filterVariables.principle.value === ''
                            ? setFilterActive(false)
                            : setFilterActive(true)
                          //getPWBulkOrderList()
                          //setPageNumber(1)
                        }}>
                        Apply
                      </Button>
                      <Button
                        appearance='dull'
                        onClick={() => {
                          setFilterVariables({
                            principle: {
                              label: 'All',
                              value: '',
                            },
                            consNo: '',
                            user: '',
                            consignedFrom: '',
                            consignedTo: '',
                          })
                          setFilterActive(false)
                          setIsFilterModalOpen(false)
                          setIsResetFilter(true)
                          setPageNumber(1)
                        }}>
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='flex flex-row w-full mb-2 py-2 z-30'></div>
              </motion.div>
            )}
          </AnimatePresence>*/}
          <ThemeProvider theme={theme}>
            <Box sx={{ bgcolor: 'background.paper', width: '50%' }}>
              <AppBar position='static'>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor='secondary'
                  textColor='inherit'
                  variant='fullWidth'
                  aria-label='full width tabs example'>
                  <Tab
                    label='Drafts'
                    sx={{
                      'width': '25%', // 1/4 width for each tab
                      'backgroundColor':
                        value === 0 ? '#F1A248' : 'transparent', // Orange for active tab
                      'color': value === 0 ? '#fff' : '#000', // White text for active tab
                      '&:hover': {
                        backgroundColor: '#FDDBB6', // Darker shade for hover effect
                      },
                    }}
                  />
                  <Tab
                    label='In Progress'
                    sx={{
                      'width': '25%', // 1/4 width for each tab
                      'backgroundColor':
                        value === 1 ? '#F1A248' : 'transparent', // Orange for active tab
                      'color': value === 1 ? '#fff' : '#000', // White text for active tab
                      '&:hover': {
                        backgroundColor: '#FDDBB6', // Darker shade for hover effect
                      },
                    }}
                  />
                  <Tab
                    label='Completed'
                    sx={{
                      'width': '25%', // 1/4 width for each tab
                      'backgroundColor':
                        value === 2 ? '#F1A248' : 'transparent', // Orange for active tab
                      'color': value === 2 ? '#fff' : '#000', // White text for active tab
                      '&:hover': {
                        backgroundColor: '#FDDBB6', // Darker shade for hover effect
                      },
                    }}
                  />
                  <Tab
                    label='Failed'
                    sx={{
                      'width': '25%', // 1/4 width for each tab
                      'backgroundColor':
                        value === 2 ? '#F1A248' : 'transparent', // Orange for active tab
                      'color': value === 2 ? '#fff' : '#000', // White text for active tab
                      '&:hover': {
                        backgroundColor: '#FDDBB6', // Darker shade for hover effect
                      },
                    }}
                  />
                </Tabs>
              </AppBar>
            </Box>
          </ThemeProvider>

          {/* Tab Content */}

          <div className='flex flex-col w-full h-auto bg-white rounded-lg px-0 py-2 overflow: visible'>
            <BulkHistoryTable
              itemData={historyList}
              className=''
              setTableVariables={setTableVariables}
              tableVariables={tableVariables}
              totalRowCount={totalRowCount}
              setIsLoading={false}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              status={status}
              setbulkreferance={setbulkReferance}
              FromHistory={FromHistory}
              setFromHistory={setFromHistory}
              setWarehouseId={setWarehouseId}
              setWarehouseDetails={setWarehouseDetails}
              createPwShipmentConsignment={createPwShipmentConsignment}
              progressDetails={progressDetails}
              setStatus={setStatus}
              setSummaryData={setSummaryData}
              userDetails={userDetails}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

BulkConsignmentHistory.defaultProps = {}

export default BulkConsignmentHistory
