/* eslint-disable @typescript-eslint/no-array-constructor */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useBulkOrderConsign,
  useCreateBulkOrder,
  useCreateShipmentPackage,
  useDeleteOrderShipmentPackage,
  useGetBulkOrder,
  useGetCarrierProducts,
  useGetSimilarOrders,
  useUpdateSimilarOrders,
} from 'framework/api/methods'

import {
  Accordion,
  Button,
  DatePicker,
  Input,
  JsonLoader,
  PopOut,
  PopUpAlert,
} from 'components/atoms'
import { DataTable as OrderStatusListDataTable } from 'components/pages/BulkOrderConsignment/Tables/OrderStatusListDataTable'
import { DataTable as ProductDetailsDataTable } from 'components/pages/BulkOrderConsignment/Tables/ProductDetailsDataTable'
import { DataTable as SimilarOrderListDataTable } from 'components/pages/BulkOrderConsignment/Tables/SimilarOrderList'
import { ConsignedShippingLine } from 'components/pages/OrderConsignment/ShipmentTabs/ConsignedShippingLine'
import { DataTable as ConsignedPackageTable } from 'components/pages/OrderConsignment/Tables/ConsignedPackageTable'

import { AddNewPackageModal } from '../OrderConsignment/Modals/AddNewPackageModal'
import { ConsignedWithoutQuoteModal } from '../OrderConsignment/Modals/ConsignedWithoutQuoteModal'

import { ScanSubmitFormBulkOrder } from './sections/ScanSubmitFormBulkOrder'

import 'react-toastify/dist/ReactToastify.css'

export interface BulkOrderConsignmentProps {
  [x: string]: any
}

export const BulkOrderConsignment: FC<BulkOrderConsignmentProps> = ({
  className,
}: BulkOrderConsignmentProps) => {
  const [mainLoadingState, setMainLoadingState] = useState(false)
  const [showConsignScreen, setShowConsignScreen] = useState(false)
  const [wmsOrderId, setWmsOrderId] = useState('')
  const [bulkConsignmentId, setBulkConsignmentId] = useState('')
  const [isShowProductDetails, setIsShowProductDetails] =
    useState<boolean>(true)
  const [orderConsignmentData, setOrderConsignmentData] = useState<any>()
  const [productDetailTableValues, setProductDetailTableValues] = useState<any>(
    {},
  )
  const [selectedOrderDetails, setSelectedOrderDetails] = useState<any>({})
  const [selectedBulkOrderDetails, setSelectedBulkOrderDetails] = useState<any>(
    {},
  )
  const [similarOrdersData, setSimilarOrdersData] = useState<any>()
  const [selectedTableRows, setSelectedTableRows] = useState<any>([])
  const [selectedRowIds, setSelectedRowIds] = useState<any>([])
  const [selectedOriginalRowIds, setSelectedOriginalRowIds] = useState<any>([])

  const [selectedShipmentId, setSelectedShipmentId] = useState('')
  const [isAddNewPackageModalOpen, setIsAddNewPackageModalOpen] =
    useState(false)
  const [isConsignWithoutQuoteModalOpen, setIsConsignWithoutQuoteModalOpen] =
    useState(false)
  const [continueCallAPI, setContinueCallAPI] = useState(false)
  const [filterDetails, setFilterDetails] = useState<any>({
    isFilter: false,
    suburb: '',
    date: '',
  })
  const [deleteObjectDetails, setDeleteObjectDetails] = useState<any>({
    trigger: false,
    deleteData: {},
    method: '',
    deleteHeading: '',
    deleteDescription: '',
  })
  const [shipmentPackageDetails, setShipmentPackageDetails] = useState<any>({
    packageType: { value: 'Carton', label: 'Carton' },
    packageQty: 1,
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
  })
  const [showSimilarOrderModal, setShowSimilarOrderModal] = useState(false)
  const [showOrderListStatusModal, setShowOrderListStatusModal] =
    useState(false)
  const [orderListStatusTableData, setOrderListStatusTableData] = useState<any>(
    [],
  )

  const [isBulkSlip, setIsBulkSlip] = useState<any>(false)
  const [bulkSlipNo, setBulkSlipNo] = useState('')

  /** API Call for Create Bulk Order Consignment */
  const { mutate: createBulkOrderMutate, isLoading: createBulkOrderIsLoading } =
    useCreateBulkOrder()

  /** Process the Create Bulk Order Consignment */
  async function createBulkOrder(orderNo: string) {
    createBulkOrderMutate(
      { orderNo },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          setShowConsignScreen(true)
          getBulkOrder(successData?.bulkConsignment?.id)
          setBulkConsignmentId(successData?.bulkConsignment?.id)
          if (successData.bulkConsignment?.isBulkSlipOrder == true) {
            setSimilarOrdersData(successData?.similarOrders)
            setSelectedTableRows(successData?.similarOrders)
            setBulkSlipNo(successData?.bulkConsignment?.bulkSlipNo)
            setSelectedOriginalRowIds(
              successData?.similarOrders.map((item: any) => item.id),
            )
          } else {
            getSimilarOrders(successData?.bulkConsignment?.id, filterDetails)
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
          setShowConsignScreen(false)
        },
      },
    )
  }

  /** API Call for Get Bulk Order Consignment */
  const { mutate: getBulkOrderMutate, isLoading: getBulkOrderIsLoading } =
    useGetBulkOrder()

  /** Process the Get Bulk Order Consignment */
  async function getBulkOrder(bulkConsignmentId: string) {
    getBulkOrderMutate(
      { bulkConsignmentId },
      {
        onSuccess: ({ data: successData }: any) => {
          setOrderConsignmentData(successData.bulkConsignment)
          setSelectedOrderDetails(successData.bulkConsignment.templateOrder)
          setIsBulkSlip(successData.bulkConsignment.isBulkSlip)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** API Call for Get Similar Order List */
  const {
    mutate: getSimilarOrdersMutate,
    isLoading: getSimilarOrdersIsLoading,
  } = useGetSimilarOrders()

  /** Process the Get Similar Order List */
  async function getSimilarOrders(
    bulkConsignmentId: string,
    filterDetails?: any,
  ) {
    getSimilarOrdersMutate(
      { bulkConsignmentId, filterDetails },
      {
        onSuccess: ({ data: successData }: any) => {
          setSimilarOrdersData(successData?.warehouseOrders)
          setSelectedOriginalRowIds(
            successData?.warehouseOrders.map((item: any) => item.id),
          )
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** Apply Filter */

  useEffect(() => {
    const timer = setTimeout(() => {
      bulkConsignmentId && getSimilarOrders(bulkConsignmentId, filterDetails)
    }, 1000)
    return () => clearTimeout(timer)
  }, [filterDetails])

  /** API Call for set Bulk Consignment warehouse orders */
  const {
    mutate: updateSimilarOrdersMutate,
    isLoading: updateSimilarOrdersIsLoading,
  } = useUpdateSimilarOrders()

  /** Process the set Bulk Consignment warehouse orders */
  async function updateSimilarOrders(bulkConsignmentId: string, rowIds: any) {
    setSelectedOriginalRowIds(selectedRowIds)
    updateSimilarOrdersMutate(
      { bulkConsignmentId, rowIds },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          getBulkOrder(bulkConsignmentId)
          setShowSimilarOrderModal(false)

          setSelectedBulkOrderDetails(
            successData?.bulkConsignment?.templateOrder,
          )
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** API Call for Delete Order Shipment Package */
  const {
    mutate: deleteOrderShipmentPackageMutate,
    isLoading: deleteOrderShipmentPackageIsLoading,
  } = useDeleteOrderShipmentPackage()

  /** Process the Delete Order Shipment package */
  async function deleteOrderShipmentPackage(
    orderId: string,
    shipmentId: string,
    packageId: string,
  ) {
    deleteOrderShipmentPackageMutate(
      { orderId, shipmentId, packageId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          getBulkOrder(bulkConsignmentId)
          resetDeleteObjectDetails()
          resetNewPackageModalDetails()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** API Call for Bulk Order Consign */
  const {
    mutate: bulkOrderConsignMutate,
    isLoading: bulkOrderConsignIsLoading,
  } = useBulkOrderConsign()

  /** Process the Bulk Order Consign */
  async function createShipmentConsignment(
    wereHouseOrderId: string,
    shipmentId: string,
    quoteType: string,
    productCode: string,
    ATLValue: boolean,
    personToLift: number,
    pickUpDate: string,
  ): Promise<void> {
    bulkOrderConsignMutate(
      {
        bulkConsignmentId: bulkConsignmentId,
        type: quoteType,
        productCode: productCode,
        // shipmentId,
        // ATLValue,
        // personToLift,
        // pickUpDate,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          setMainLoadingState(false)
          getBulkOrder(bulkConsignmentId)
          setIsConsignWithoutQuoteModalOpen(false)
          setContinueCallAPI(true)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** Delete PopUp Modal Reset */
  const resetDeleteObjectDetails = () => {
    setDeleteObjectDetails({
      trigger: false,
      deleteData: {},
      method: '',
      deleteHeading: '',
      deleteDescription: '',
    })
  }

  /** Delete PopUp Modal Cancel */
  const handleDeleteCancel = () => {
    setDeleteObjectDetails({
      trigger: false,
      deleteData: {},
      method: '',
      deleteHeading: '',
      deleteDescription: '',
    })
  }

  /** Shipment Package Modal Reset */
  const resetNewPackageModalDetails = () => {
    setShipmentPackageDetails({
      packageType: { value: 'Carton', label: 'Carton' },
      packageQty: 1,
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
    })
  }

  /** Handling All Delete Pop-Up Confirmations */
  const handleDeleteConfirm = () => {
    if (deleteObjectDetails.trigger) {
      /** Delete Shipment Package */
      if (deleteObjectDetails.method === 'deleteShipmentPackage') {
        deleteOrderShipmentPackage(
          selectedOrderDetails.id,
          deleteObjectDetails.deleteData.shipmentId,
          deleteObjectDetails.deleteData.id,
        )
      }
    }
  }

  useEffect(() => {
    wmsOrderId && createBulkOrder(wmsOrderId)
  }, [wmsOrderId])

  useEffect(() => {
    orderConsignmentData &&
      setProductDetailTableValues(
        orderConsignmentData?.templateOrder?.partnerOrder?.partnerOrderLines,
      )
    if (
      orderConsignmentData?.status === 'INPROGRESS' ||
      orderConsignmentData?.status === 'DONE'
    ) {
      setTimeout(
        () => {
          continueCallAPI && getBulkOrder(bulkConsignmentId)
        },
        orderConsignmentData?.warehouseOrders?.length > 100 ? 15000 : 3000,
      )
    }
    if (orderConsignmentData?.status === 'COMPLETE') {
      continueCallAPI && setContinueCallAPI(false)
      // setOrderListStatusTableData(orderConsignmentData?.warehouseOrders)

      const toBeUpdatedData = orderConsignmentData?.warehouseOrders?.map(
        (item: any) => {
          return {
            id: item.id,
            errorReason: item?.errorReason,
            customerOrderNo:
              item?.warehouseOrder?.partnerOrder?.customerOrderNo,
            orderNo: item?.warehouseOrder?.orderNo,
            shippingAddress:
              item?.warehouseOrder?.partnerOrder?.shippingAddress,
            createdOn: item?.warehouseOrder?.createdOn,
          }
        },
      )
      setOrderListStatusTableData(toBeUpdatedData)

      /** Open Labels of all Consignment except DSE */

      if (
        orderConsignmentData?.templateOrder?.shipments[0]?.consignment
          ?.productCode !== 'DSE'
      ) {
        window.open(orderConsignmentData?.bulkLabelUrl || '', '_blank')
      }
    }
  }, [orderConsignmentData])

  useEffect(() => {
    const toBeUpdated = selectedTableRows.map((item: any) => {
      return item.id
    })
    setSelectedRowIds(toBeUpdated)
  }, [selectedTableRows])

  /** Toaster Notifications */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  /** Handling Consign Shipment Click */
  const handleConsignClick = (
    shipmentId: any,
    quoteType: any,
    productCode: any,
    ATLValue: any,
    personToLift: any,
    pickUpDate: any,
  ) => {
    createShipmentConsignment(
      selectedOrderDetails.id,
      shipmentId,
      quoteType,
      productCode,
      ATLValue,
      personToLift,
      pickUpDate,
    )
    setMainLoadingState(true)
  }

  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    setShowSimilarOrderModal(false)
  }

  const handleOnCloseClick = (event: any) => {
    setShowSimilarOrderModal(false)
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const handleConfirmClick = (event: any) => {
    setShowSimilarOrderModal(false)
  }

  /** Handling Click Status For Order Status Modal*/
  const handleOverlayClickOrderListStatusModal = (event: any) => {
    setShowOrderListStatusModal(false)
  }

  const handleOnCloseClickOrderListStatusModal = (event: any) => {
    setShowOrderListStatusModal(false)
  }
  const disableOverlayClickOrderListStatusModal = (event: any) => {
    event.stopPropagation()
  }

  /** Main Loading Status handling with every single API Loading Status's */
  useEffect(() => {
    if (
      !continueCallAPI &&
      (createBulkOrderIsLoading ||
        getBulkOrderIsLoading ||
        getSimilarOrdersIsLoading ||
        deleteOrderShipmentPackageIsLoading ||
        updateSimilarOrdersIsLoading)
    ) {
      setMainLoadingState(true)
    } else {
      setMainLoadingState(false)
    }
  }, [
    createBulkOrderIsLoading,
    getBulkOrderIsLoading,
    getSimilarOrdersIsLoading,
    deleteOrderShipmentPackageIsLoading,
    updateSimilarOrdersIsLoading,
    continueCallAPI,
  ])

  const [shipments, setShipments] = useState<any>([])

  /** Quote Types are Hard-coded */
  useEffect(() => {
    if (selectedOrderDetails) {
      const toBeUpdated = selectedOrderDetails?.shipments?.map(
        (shipment: any) => {
          let isOpen = false
          if (shipment?.shipmentConsignmentQuotes.length > 0) {
            isOpen = true
          }
          return {
            ...shipment,
            isOpenedSavedQuotes: isOpen,
            savedQuoteTypes: [
              'All',
              'Australian Post',
              'Direct Express',
              'Smart Send',
              // 'DSE',
            ],
            selectedQuoteType: 'All',
          }
        },
      )
      setShipments(toBeUpdated)
    }
  }, [selectedOrderDetails])

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full pt-4 px-8 pb-4',
        {},
        className,
      )}>
      {/* Loading Screen */}
      {mainLoadingState && (
        <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[99] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
          <JsonLoader size='xs' />
        </div>
      )}
      {!showConsignScreen && (
        /** WMS Order # Scan Area */
        <div className='flex flex-col w-full h-full justify-center items-center'>
          <ScanSubmitFormBulkOrder
            isBulk={true}
            setWmsOrderId={setWmsOrderId}
            isLoading={createBulkOrderIsLoading}
          />
        </div>
      )}
      {continueCallAPI && (
        /** Progress Area */
        <div className='flex flex-col w-1/2 items-center justify-center'>
          {/* Top Description area */}
          <div className='flex w-full items-center justify-between mb-2'>
            <p className='text-sm font-medium text-N-700'>
              Consigning Orders...
            </p>
            <div className='flex items-center'>
              <p className='text-lg font-semibold text-N-700'>
                {Number(orderConsignmentData?.consignedOrders || 0) +
                  Number(orderConsignmentData?.failedOrders || 0)}
              </p>
              <p className='font-normal text-N-700'>
                /{orderConsignmentData?.totalOrders || '0'}
              </p>
            </div>
          </div>

          {/* Progress Bar */}
          <div className='w-full bg-N-200 rounded-full h-2.5 dark:bg-N-100'>
            <div
              className='bg-P-500 h-2 rounded-full'
              style={{
                width:
                  ((Number(orderConsignmentData?.consignedOrders || 0) +
                    Number(orderConsignmentData?.failedOrders || 0)) /
                    Number(orderConsignmentData?.totalOrders || 0)) *
                    100 +
                  '%',
              }}></div>
          </div>

          {/* Bottom Area */}
          <div className='flex w-full items-center justify-end'>
            <div className='flex items-center'>
              <i className='ri-checkbox-circle-line text-G-500'></i>
              <p className='text-xs text-N-500 font-normal pl-1'>Succeeded:</p>
              <p className='text-xs text-N-500 font-semibold pl-1'>
                {orderConsignmentData?.consignedOrders || '0'}
              </p>
            </div>
            <div className='flex items-center pl-4'>
              <i className='ri-close-circle-line text-R-500'></i>
              <p className='text-xs text-N-500 font-normal pl-1'>Failed:</p>
              <p className='text-xs text-N-500 font-semibold pl-1'>
                {orderConsignmentData?.failedOrders || '0'}
              </p>
            </div>
          </div>
        </div>
      )}
      {!continueCallAPI &&
        showConsignScreen &&
        orderConsignmentData?.totalOrders === 1 && (
          <div className='flex flex-col w-full h-full overflow-y-auto styled-scroll'>
            <Button
              onClick={() => {
                setShowConsignScreen(false)
                setWmsOrderId('')
              }}
              className='mb-2'
              size='xs'
              appearance='outline'>
              <i className='ri-arrow-left-line pr-3'></i> Back to bulk order
              consignment
            </Button>

            <div className='flex flex-col w-full h-auto bg-white rounded-lg px-4 py-5'>
              {/* Header Section */}
              <div className='flex items-center justify-between mb-10'>
                <div className='flex flex-col'>
                  <p className='text-lg font-semibold text-black'>
                    This is a Bulk Order
                  </p>
                  <span className='flex text-sm font-normal text-N-500 pt-[6px]'>
                    {isBulkSlip
                      ? `Our system indicated there are ${
                          similarOrdersData?.length || 0
                        } similar orders to BulkSlip No`
                      : `Our system indicated there are ${
                          similarOrdersData?.length || 0
                        } similar orders to WMS order`}
                    <p className='pl-1 text-P-500 font-medium'>
                      {isBulkSlip
                        ? bulkSlipNo || ''
                        : `${
                            orderConsignmentData?.templateOrder?.partnerOrder
                              ?.warehouseOrder?.orderNo || ''
                          }`}
                    </p>
                  </span>
                  <div className='flex pt-[6px]'>
                    <span className='flex text-sm font-normal text-N-500'>
                      State Code:
                      <p className='pl-1 text-N-800 font-medium'>{`${
                        orderConsignmentData?.templateOrder?.partnerOrder
                          ?.shippingAddress?.stateCode || ''
                      }`}</p>
                    </span>
                    <span className='flex text-sm font-normal text-N-500 pl-6'>
                      Partner:{' '}
                      {`${
                        orderConsignmentData?.templateOrder?.partnerOrder
                          ?.partner?.code || ''
                      } - `}
                      <p className='pl-1 text-N-800 font-medium'>{`${
                        orderConsignmentData?.templateOrder?.partnerOrder
                          ?.partner?.name || ''
                      }`}</p>
                    </span>
                  </div>
                </div>
                <Button
                  disabled={
                    selectedRowIds && selectedRowIds.length > 0 ? false : true
                  }
                  isLoading={updateSimilarOrdersIsLoading}
                  onClick={() => {
                    updateSimilarOrders(bulkConsignmentId, selectedRowIds)
                  }}>
                  Consign Bulk Order
                </Button>
              </div>

              {/* Product Details Table */}
              <Accordion
                key='Product Details'
                isOpen={isShowProductDetails}
                title='Product Details'
                subTitle={`(${productDetailTableValues?.length || 0} Products)`}
                OnClick={() => {
                  setIsShowProductDetails(!isShowProductDetails)
                }}>
                <ProductDetailsDataTable
                  className='pt-4 pb-14 h-full'
                  itemData={productDetailTableValues}
                />
              </Accordion>

              {/* Similar Order List */}
              <div className='flex items-center justify-between pb-5'>
                <span className='flex text-sm font-semibold text-black'>
                  Similar Order List{' '}
                  <p className='text-N-500 pl-1'>{`(${
                    similarOrdersData?.length || 0
                  } Orders)`}</p>
                </span>
                {!isBulkSlip && (
                  <Button
                    appearance='outline'
                    size='sm'
                    className='px-2'
                    onClick={(e: any) => {
                      if (!filterDetails.isFilter) {
                        setFilterDetails({
                          ...filterDetails,
                          isFilter: !filterDetails.isFilter,
                        })
                      } else {
                        setFilterDetails({
                          ...filterDetails,
                          isFilter: false,
                          suburb: '',
                          date: '',
                        })
                      }
                    }}>
                    <i
                      className={CN({
                        'ri-filter-off-line': filterDetails.isFilter,
                        'ri-filter-2-line': !filterDetails.isFilter,
                      })}></i>
                  </Button>
                )}
              </div>

              {filterDetails?.isFilter && (
                <div className='flex w-2/3 pb-6'>
                  <div className='w-1/2'>
                    <Input
                      className=''
                      labelValue='By Suburb'
                      placeholder='Ex: Hornsby'
                      type='text'
                      value={filterDetails?.suburb}
                      onChange={(e: any) => {
                        setFilterDetails({
                          ...filterDetails,
                          suburb: e.target.value,
                        })
                      }}
                      isBulkFilter={true}
                    />
                  </div>

                  <div className='pl-4 flex flex-col'>
                    <span className='text-sm font-medium text-N-700 pb-2'>
                      By Created Date
                    </span>
                    <DatePicker
                      enabled={true}
                      onChange={(e: any) => {
                        setFilterDetails({
                          ...filterDetails,
                          date: e.target.value,
                        })
                      }}
                      value={filterDetails?.date}
                      allowEdit={false}
                      isBulkFilter={true}
                    />
                  </div>
                </div>
              )}

              <SimilarOrderListDataTable
                key={similarOrdersData?.length}
                itemData={similarOrdersData || []}
                className=''
                setSelectedRows={setSelectedTableRows}
                selectedOriginalRowIds={selectedOriginalRowIds}
                isBulkSlip={isBulkSlip}
              />
            </div>
          </div>
        )}
      {!continueCallAPI &&
        showConsignScreen &&
        orderConsignmentData?.totalOrders > 1 && (
          <div className='w-full h-full overflow-y-auto styled-scroll'>
            <Button
              onClick={() => {
                setShowConsignScreen(false)
                setWmsOrderId('')
              }}
              className='mb-2'
              size='xs'
              appearance='outline'>
              <i className='ri-arrow-left-line pr-3'></i> Back to bulk order
              consignment
            </Button>

            {/* Top Panel */}
            {orderConsignmentData?.status !== 'COMPLETE' && (
              <div className='flex items-center justify-between w-full px-4 py-[18px] bg-white rounded-lg'>
                <div
                  className='flex items-center px-[18px] bg-P-50 rounded-lg cursor-pointer'
                  onClick={() => {
                    setShowSimilarOrderModal(true)
                  }}>
                  <p className='text-sm text-N-500 py-[23px] font-medium'>
                    No of Orders:
                  </p>
                  <p className='text-4xl font-semibold text-P-600 pl-5'>
                    {orderConsignmentData?.totalOrders}
                  </p>
                </div>
                <div className='flex flex-col items-end'>
                  <div className='flex'>
                    <p className='text-sm font-normal text-N-500'>
                      State Code:
                    </p>
                    <p className='text-sm font-semibold text-N-800 pl-1'>{`${
                      orderConsignmentData?.templateOrder?.partnerOrder
                        ?.shippingAddress?.stateCode || ''
                    }`}</p>
                  </div>
                  <div className='flex pt-2'>
                    <p className='text-sm font-normal text-N-500'>
                      Partner:{' '}
                      {`${
                        orderConsignmentData?.templateOrder?.partnerOrder
                          ?.partner?.code || ''
                      } - `}
                    </p>
                    <p className='text-sm font-semibold text-N-800 pl-1'>
                      {`${
                        orderConsignmentData?.templateOrder?.partnerOrder
                          ?.partner?.name || ''
                      }`}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* Product Details Table */}
            <div className='w-full pt-4 px-5 bg-white rounded-lg mt-[30px] mb-[30px]'>
              <Accordion
                key='Product Details'
                isOpen={isShowProductDetails}
                title='Product Details'
                subTitle={`(${productDetailTableValues?.length || 0} Products)`}
                OnClick={() => {
                  setIsShowProductDetails(!isShowProductDetails)
                }}>
                <ProductDetailsDataTable
                  className='pt-4 pb-14 h-full'
                  itemData={productDetailTableValues}
                />
              </Accordion>
            </div>
            <div>
              <Tabs defaultIndex={1}>
                <TabList>
                  {orderConsignmentData?.templateOrder?.shipments?.map(
                    (shipment: any, index: any) => (
                      <Tab
                        key={shipment.id}
                        className={CN(
                          'react-tabs__tab text-sm font-bold py-[9px]',
                          {
                            'text-G-500 border-x-0':
                              shipment?.status === 'CLOSED',
                            'text-N-800 border-0':
                              shipment?.status !== 'CLOSED',
                          },
                        )}>
                        {shipment?.name}
                        <i
                          className={CN(
                            'ri-close-fill text-N-300 hover:text-N-500 active:text-N-600 pl-2',
                            {
                              'cursor-not-allowed':
                                shipment?.status === 'CLOSED' ||
                                shipment?.status === 'CANCELLED',
                            },
                          )}></i>
                      </Tab>
                    ),
                  )}
                </TabList>

                {orderConsignmentData?.templateOrder?.shipments?.map(
                  (shipment: any, index: any) => (
                    <TabPanel key={shipment.id}>
                      <div className='flex flex-col w-full bg-white pt-8 pb-4 px-4'>
                        <div className='flex flex-col'>
                          {/* If the Shipment is Consigned following msg will shown */}
                          {orderConsignmentData?.status === 'COMPLETE' && (
                            <>
                              <div className='flex items-center justify-start mb-6'>
                                <div className='flex'>
                                  <p className='text-sm font-normal text-N-500'>
                                    State Code:
                                  </p>
                                  <p className='text-sm font-semibold text-N-800 pl-1'>
                                    {`${
                                      orderConsignmentData?.templateOrder
                                        ?.partnerOrder?.shippingAddress
                                        ?.stateCode || ''
                                    }`}
                                  </p>
                                </div>
                                <div className='flex pl-9'>
                                  <p className='text-sm font-normal text-N-500'>
                                    Partner:{' '}
                                    {`${
                                      orderConsignmentData?.templateOrder
                                        ?.partnerOrder?.partner?.code || ''
                                    } - `}
                                  </p>
                                  <p className='text-sm font-semibold text-N-800 pl-1'>
                                    {`${
                                      orderConsignmentData?.templateOrder
                                        ?.partnerOrder?.partner?.name || ''
                                    }`}
                                  </p>
                                </div>
                              </div>
                              <PopOut
                                appearance='success'
                                size='small'
                                className='mb-3'>
                                Bulk Order Consigned
                              </PopOut>
                            </>
                          )}

                          <div className='flex w-full'>
                            {orderConsignmentData?.status === 'COMPLETE' && (
                              <div className='flex w-7/12'>
                                <div
                                  onClick={() => {
                                    setShowOrderListStatusModal(true)
                                  }}
                                  className='flex flex-col bg-N-100 rounded-lg px-6 py-4 cursor-pointer'>
                                  <div className='flex items-center justify-between'>
                                    <p className='text-N-500 font-medium'>
                                      Consigned Orders
                                    </p>
                                    <p className='text-N-700 font-bold pl-32'>
                                      {orderConsignmentData?.consignedOrders ||
                                        '0'}
                                    </p>
                                  </div>

                                  <div className='flex items-center justify-between pt-2'>
                                    <p className='text-N-500 font-medium'>
                                      Failed Orders
                                    </p>
                                    <p className='text-R-500 font-bold pl-32'>
                                      {orderConsignmentData?.failedOrders ||
                                        '0'}
                                    </p>
                                  </div>

                                  <div className='flex items-center justify-between pt-2'>
                                    <p className='text-N-700 font-bold'>
                                      Total Orders
                                    </p>
                                    <p className='text-N-700 font-bold pl-32'>
                                      {orderConsignmentData?.totalOrders || '0'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className='flex flex-col w-5/12 ml-10'>
                              <div className='flex items-center justify-between pb-5'>
                                <h2 className='text-sm font-medium text-N-700'>
                                  Shipment Packages
                                </h2>
                                {orderConsignmentData?.status !==
                                  'COMPLETE' && (
                                  <Button
                                    onClick={() => {
                                      setSelectedShipmentId(shipment.id)
                                      setIsAddNewPackageModalOpen(true)
                                    }}
                                    size='xs'
                                    appearance='outline'>
                                    <i className='ri-add-line pr-3'></i>New
                                    Package
                                  </Button>
                                )}
                              </div>
                              <div
                                className={CN('flex flex-col w-full h-full', {
                                  'justify-center items-center':
                                    shipment?.packages?.length === 0,
                                })}>
                                {shipment?.packages &&
                                  shipment?.packages?.map(
                                    (item: any, index: any) => (
                                      <div
                                        key={index}
                                        className='flex items-center justify-between w-full rounded-lg border border-N-200 px-4 py-3 mb-4 cursor-pointer'>
                                        <p className='text-sm text-N-700 font-semibold'>
                                          {`Package ${index + 1}`}
                                        </p>
                                        <p className='text-sm font-normal text-N-500'>
                                          {item.type}
                                        </p>
                                        <p className='text-sm font-normal text-N-500'>
                                          {`${item.length} x ${item.width} x ${item.height} CM | ${item.weight} KG`}
                                        </p>
                                        {orderConsignmentData?.status !==
                                          'COMPLETE' && (
                                          <Button
                                            disabled={
                                              shipment?.status === 'CANCELLED'
                                            }
                                            onClick={() => {
                                              setDeleteObjectDetails({
                                                ...deleteObjectDetails,
                                                trigger: true,
                                                deleteData: {
                                                  ...item,
                                                  shipmentId: shipment.id,
                                                },
                                                method: 'deleteShipmentPackage',
                                                deleteHeading:
                                                  'Delete Shipment Package?',
                                                deleteDescription:
                                                  'Are you sure you want to proceed? This is not reversible.',
                                              })
                                            }}
                                            appearance='danger'
                                            size='xs'>
                                            <i className='ri-delete-bin-6-line text-R-500'></i>
                                          </Button>
                                        )}
                                      </div>
                                    ),
                                  )}
                                {shipment?.packages?.length === 0 && (
                                  <div className='flex flex-col items-center'>
                                    <p className='text-lg font-semibold text-N-700'>
                                      No Packages Found
                                    </p>
                                    <p className='text-sm font-medium text-N-500 pt-2'>
                                      You need at least one package to get a
                                      quote
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Get Quote Buttons will show only for not consigned shipments */}
                          {orderConsignmentData?.status !== 'COMPLETE' && (
                            <div className='flex w-full items-center justify-between border-t border-t-N-200 mt-10 px-5 py-4'>
                              <Button
                                className='ml-5'
                                disabled={
                                  shipment?.packages?.length === 0 ||
                                  shipment?.status === 'CANCELLED'
                                }
                                onClick={() => {
                                  setIsConsignWithoutQuoteModalOpen(true)
                                  setSelectedShipmentId(shipment.id)
                                }}>
                                Consign without Quote
                              </Button>
                            </div>
                          )}

                          {/* The Consigned Summery will only be shown to the consigned Shipments only */}
                          {orderConsignmentData?.status === 'COMPLETE' && (
                            <div className='flex flex-col w-full pt-12 '>
                              <div className='flex w-full items-center justify-between'>
                                <div className='left-con flex items-center'>
                                  <i className='ri-checkbox-circle-line text-[40px] text-G-500'></i>
                                  <div className='flex flex-col items-start pl-3'>
                                    <p className='font-semibold text-black'>
                                      Consigned with
                                      {shipment?.consignment?.type ===
                                        'AUSPOST' && ' Australia Post'}
                                      {shipment?.consignment?.type ===
                                        'DIRECT' && ' Direct Express'}
                                      {shipment?.consignment?.type === 'DSE' &&
                                        ' DSE'}
                                      {shipment?.consignment?.type ===
                                        'SMART/SEND' && ' Smart Send'}
                                      {shipment?.consignment?.type ===
                                        'STARSHIPIT' && ' Starshipit'}
                                    </p>
                                    <p className='text-sm font-medium text-N-500 pt-1'>
                                      {shipment?.consignment?.type ===
                                        'AUSPOST' && 'Australia Post - '}
                                      {shipment?.consignment?.type ===
                                        'DIRECT' && 'Direct Express - '}
                                      {shipment?.consignment?.type === 'DSE' &&
                                        'DSE - '}
                                      {shipment?.consignment?.type ===
                                        'SMART/SEND' && 'Smart Send - '}
                                      {shipment?.consignment?.productName}

                                      {shipment?.consignment?.type ===
                                        'STARSHIPIT' &&
                                        ' - ' +
                                          shipment?.consignment?.productCode}
                                    </p>
                                  </div>
                                </div>

                                <Button
                                  appearance='dull'
                                  disabled={
                                    shipment?.consignment?.type === 'DSE'
                                  }
                                  size='sm'
                                  className='text-N-700'
                                  onClick={() => {
                                    window.open(
                                      orderConsignmentData?.bulkLabelUrl || '',
                                      '_blank',
                                    )
                                  }}>
                                  Print All Labels
                                </Button>
                              </div>
                              <ConsignedPackageTable
                                isBulk={true}
                                itemData={shipment?.consignment?.shippmentConsignmentItems.map(
                                  (item: any) => ({
                                    ...item,
                                    consignedType: shipment?.consignment?.type,
                                  }),
                                )}
                                className='mt-7 mb-6'
                              />
                              <ConsignedShippingLine
                                key={index}
                                shipment={shipment}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                  ),
                )}
              </Tabs>
            </div>

            {/* Add New Shipment Package Modal */}
            {isAddNewPackageModalOpen && (
              <AddNewPackageModal
                isActive={isAddNewPackageModalOpen}
                notify={notify}
                onCloseClick={() => {
                  setIsAddNewPackageModalOpen(false)
                  resetNewPackageModalDetails()
                }}
                setShipmentPackageDetails={setShipmentPackageDetails}
                shipmentPackageDetails={shipmentPackageDetails}
                useCreateShipmentPackage={useCreateShipmentPackage}
                callOrderShipmentsById={() => {
                  getBulkOrder(bulkConsignmentId)
                }}
                selectedShipmentId={selectedShipmentId}
                selectedOrderDetails={selectedOrderDetails}
              />
            )}

            {/* Delete Pop-Ups */}
            {deleteObjectDetails.trigger && (
              <PopUpAlert
                isActive={deleteObjectDetails.trigger}
                title={deleteObjectDetails?.deleteHeading}
                message={deleteObjectDetails?.deleteDescription}
                cancelButtonText='Cancel'
                confirmButtonText='Yes, I’m Sure'
                onCancelClick={() => handleDeleteCancel()}
                onOverlayClick={() => handleDeleteCancel()}
                onConfirmClick={() => handleDeleteConfirm()}
              />
            )}

            {isConsignWithoutQuoteModalOpen && (
              <ConsignedWithoutQuoteModal
                isActive={isConsignWithoutQuoteModalOpen}
                onCloseClick={() => {
                  setIsConsignWithoutQuoteModalOpen(false)
                }}
                onOverlayClick={() => {
                  setIsConsignWithoutQuoteModalOpen(false)
                }}
                handleConsignWithoutQuoteConfirmClick={handleConsignClick}
                selectedShipmentId={selectedShipmentId}
                useGetCarrierProducts={useGetCarrierProducts}
                notify={notify}
                shipments={shipments}
                isBulkConsignment={true}
                selectedOrderDetails={selectedBulkOrderDetails}
                warehouseTimezone={
                  selectedBulkOrderDetails?.warehouse?.timeZone || ''
                }
              />
            )}

            {showSimilarOrderModal && (
              <div
                className={CN(
                  'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-[10] top-0 right-0 bottom-0 left-0 flex items-center justify-center',
                  className,
                  {
                    hidden: !showSimilarOrderModal,
                  },
                )}
                onClick={(e: any) => handleOverlayClick(e)}>
                <div
                  className='flex w-[800px] bg-white rounded-md flex-col items-center h-[80%]'
                  onClick={(e: any) => disableOverlayClick(e)}>
                  {/* Modal Header  */}
                  <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
                    <h2 className='text-lg font-bold'>
                      {`Bulk Order List - ${
                        selectedTableRows?.length || 0
                      } Orders`}
                    </h2>
                    <button
                      onClick={(e: any) => {
                        handleOnCloseClick(e)
                      }}
                      className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
                      <i className='ri-close-line'></i>
                    </button>
                  </div>

                  {/* Modal Body  */}
                  <div className='flex flex-col w-full pt-6 pl-4 pr-[18px] overflow-y-auto styled-scroll'>
                    {/* Similar Order List */}
                    <div className='flex items-center justify-between pb-5'>
                      <span className='flex text-sm font-semibold text-black'>
                        Similar Order List{' '}
                        <p className='text-N-500 pl-1'>{`(${
                          similarOrdersData?.length || 0
                        } Orders)`}</p>
                      </span>
                      {!isBulkSlip && (
                        <Button
                          appearance='outline'
                          size='sm'
                          className='px-2'
                          onClick={(e: any) => {
                            if (!filterDetails.isFilter) {
                              setFilterDetails({
                                ...filterDetails,
                                isFilter: !filterDetails.isFilter,
                              })
                            } else {
                              setFilterDetails({
                                ...filterDetails,
                                isFilter: false,
                                suburb: '',
                                date: '',
                              })
                            }
                          }}>
                          <i
                            className={CN({
                              'ri-filter-off-line': filterDetails.isFilter,
                              'ri-filter-2-line': !filterDetails.isFilter,
                            })}></i>
                        </Button>
                      )}
                    </div>
                    {filterDetails?.isFilter && (
                      <div className='flex w-2/3 pb-6'>
                        <div className='w-1/2'>
                          <Input
                            className=''
                            labelValue='By Suburb'
                            placeholder='Ex: Hornsby'
                            type='text'
                            value={filterDetails?.suburb}
                            onChange={(e: any) => {
                              setFilterDetails({
                                ...filterDetails,
                                suburb: e.target.value,
                              })
                            }}
                            isBulkFilter={true}
                          />
                        </div>

                        <div className='pl-4 flex flex-col'>
                          <span className='text-sm font-medium text-N-700 pb-2'>
                            By Created Date
                          </span>
                          <DatePicker
                            enabled={true}
                            onChange={(e: any) => {
                              setFilterDetails({
                                ...filterDetails,
                                date: e.target.value,
                              })
                            }}
                            value={filterDetails?.date}
                            allowEdit={false}
                            minDate={new Date()}
                            isBulkFilter={true}
                          />
                        </div>
                      </div>
                    )}

                    <SimilarOrderListDataTable
                      key={similarOrdersData?.length}
                      itemData={similarOrdersData || []}
                      className=''
                      setSelectedRows={setSelectedTableRows}
                      selectedOriginalRowIds={selectedOriginalRowIds}
                      isBulkSlip={isBulkSlip}
                    />
                  </div>

                  {/* Modal Footer  */}
                  <div className='w-full border-t border-t-N-200 px-5 py-4 mt-11'>
                    <Button
                      disabled={
                        selectedRowIds && selectedRowIds.length > 0
                          ? false
                          : true
                      }
                      isLoading={updateSimilarOrdersIsLoading}
                      onClick={() => {
                        updateSimilarOrders(bulkConsignmentId, selectedRowIds)
                      }}>
                      Consign Bulk Order
                    </Button>
                    <Button
                      className='ml-5 w-[152px]'
                      appearance='dull'
                      disabled={false}
                      onClick={handleOnCloseClick}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* Similar Order Status Modal */}
            {showOrderListStatusModal && (
              <div
                className={CN(
                  'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-[10] top-0 right-0 bottom-0 left-0 flex items-center justify-center',
                  className,
                  {
                    hidden: !showOrderListStatusModal,
                  },
                )}
                onClick={(e: any) => handleOverlayClickOrderListStatusModal(e)}>
                <div
                  className='flex w-[800px] bg-white rounded-md flex-col items-center h-[80%]'
                  onClick={(e: any) =>
                    disableOverlayClickOrderListStatusModal(e)
                  }>
                  {/* Modal Header  */}
                  <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
                    <h2 className='text-lg font-bold'>
                      {`Bulk Order List - ${
                        selectedTableRows?.length || 0
                      } Orders`}
                    </h2>
                    <button
                      onClick={(e: any) => {
                        handleOnCloseClickOrderListStatusModal(e)
                      }}
                      className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
                      <i className='ri-close-line'></i>
                    </button>
                  </div>

                  {/* Modal Body  */}
                  <div className='flex flex-col w-full h-full pt-3 pl-4 pr-[18px] overflow-y-auto styled-scroll'>
                    <OrderStatusListDataTable
                      key={orderListStatusTableData?.length}
                      itemData={orderListStatusTableData || []}
                      className=''
                      // setSelectedRows={setSelectedTableRows}
                      // selectedOriginalRowIds={selectedOriginalRowIds}
                    />
                  </div>

                  {/* Modal Footer  */}
                  <div className='w-full border-t border-t-N-200 px-5 py-4 mt-11'>
                    <Button
                      className='ml-5 w-[152px]'
                      appearance='dull'
                      disabled={false}
                      onClick={(e: any) => {
                        handleOnCloseClickOrderListStatusModal(e)
                      }}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            )}

            <Button
              onClick={() => {
                setShowConsignScreen(false)
                setWmsOrderId('')
              }}
              className='my-2'
              size='xs'
              appearance='outline'>
              <i className='ri-arrow-left-line pr-3'></i> Back to bulk order
              consignment
            </Button>
          </div>
        )}

      {/* Toaster */}
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
    </div>
  )
}

BulkOrderConsignment.defaultProps = {}

export default BulkOrderConsignment
