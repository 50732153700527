import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateInboundOrderCutoffTimeInterface {
  id?: string
  partnerCode?: string
  partnerName?: string
  integrationType?: string
  integrationId?: string
  integrationURL?: string
  criteria?: string
  containerTypes?: string
  numberOfLines?: number
  inboundCutOffLeadTime?: number
  isActive: boolean
}
export const useUpdateInboundOrderCutoffTime = () => {
  const mutate = useMutation(
    ({
      id,
      partnerCode,
      partnerName,
      integrationType,
      integrationId,
      integrationURL,
      criteria,
      containerTypes,
      numberOfLines,
      inboundCutOffLeadTime,
      isActive,
    }: UpdateInboundOrderCutoffTimeInterface) => {
      return putAPI(`/UpdateInboundOrderCutoffTime`, {
        id,
        partnerCode,
        partnerName,
        integrationType,
        integrationId,
        integrationURL,
        criteria,
        containerTypes,
        numberOfLines,
        inboundCutOffLeadTime,
        isActive,
      })
    },
  )
  return mutate
}
export default useUpdateInboundOrderCutoffTime
