import { FC, useState } from 'react'
import CN from 'classnames'
import { useCreateWarehouse } from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'
import { Input } from 'components/atoms/Input'
import { MultiSelect } from 'components/atoms/MultiSelect'
import { SimpleSelect } from 'components/atoms/Select'
import { ToggleSwitch } from 'components/atoms/ToggleSwitch'
import { isEmail } from 'utils'

export interface AddWarehouseProps {
  className?: string | undefined
  [x: string]: any
}

export const AddWarehouse: FC<AddWarehouseProps> = ({
  className,
  isActive,
  onCloseClick,
  countryList,
  notify,
  getWarehousesList,
  labelList,
}: AddWarehouseProps) => {
  const AddWarehousesClasses = CN(
    'AddWarehouses flex flex-col py-4 px-5 relative h-full overflow-y-auto overscroll-y-auto styled-scroll gap-y-5',
    className,
    {},
  )
  const [addWarehouseDetails, setAddWarehouseDetails] = useState<any>({
    status: true,
    warehouseName: '',
    warehouseCode: '',
    phoneNo: '',
    email: '',
    streetAddress: '',
    suburb: '',
    state: '',
    stateCode: '',
    postalCode: '',
    country: '',
    timeZone: '',
    labelIds: '',
  })

  const [selectedWarehouseLabels, setSelectedWarehouseLabels] = useState<any>()
  const [isErrorField, setIsErrorField] = useState<any>({
    warehouseName: false,
    warehouseCode: false,
    streetAddress: false,
    suburb: false,
    state: false,
    stateCode: false,
    postalCode: false,
    country: false,
    timeZone: false,
  })

  const timeZoneList = [
    {
      label: 'AWST (UTC+08:00)',
      value: 'AWST (UTC+08:00)',
    },
    {
      label: 'AEST (UTC+10:00)',
      value: 'AEST (UTC+10:00)',
    },
    {
      label: 'ACST (UTC+09:30)',
      value: 'ACST (UTC+09:30)',
    },
    {
      label: 'NFT (UTC+11:00)',
      value: 'NFT (UTC+11:00)',
    },
    {
      label: 'CXT (UTC+07:00)',
      value: 'CXT (UTC+07:00)',
    },
    {
      label: 'CCT (UTC+06:30)',
      value: 'CCT (UTC+06:30)',
    },
  ]

  /** API Call for Login function using react-query */
  const { mutate: createWarehouseMutate, isLoading: createWarehouseIsLoading } =
    useCreateWarehouse()

  /** Process the Login submission */
  async function addWarehouse() {
    createWarehouseMutate(addWarehouseDetails, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData.message, 'success')
        onCloseClick()
        getWarehousesList()
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error', 'error')
      },
    })
  }

  const validateInputs = () => {
    const {
      warehouseName,
      warehouseCode,
      email,
      streetAddress,
      suburb,
      state,
      stateCode,
      postalCode,
      country,
      timeZone,
    } = addWarehouseDetails

    setIsErrorField({
      ...isErrorField,
      warehouseName: warehouseName ? false : true,
      warehouseCode: warehouseCode ? false : true,
      streetAddress: streetAddress ? false : true,
      suburb: suburb ? false : true,
      state: state ? false : true,
      stateCode: stateCode ? false : true,
      postalCode: postalCode ? false : true,
      country: country ? false : true,
      email: isEmail(email) || email === '' ? false : true,
      timeZone: timeZone ? false : true,
    })

    const isAllValidFields =
      warehouseName &&
      warehouseCode &&
      streetAddress &&
      suburb &&
      state &&
      stateCode &&
      postalCode &&
      country &&
      (isEmail(email) || email === '') &&
      timeZone
    return isAllValidFields
  }

  const handleAddClick = () => {
    const valid = validateInputs()

    if (valid) {
      addWarehouse()
    }
  }

  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      width={400}
      title='Add New Warehouse '>
      <div className={AddWarehousesClasses}>
        <Input
          labelValue='Warehouse Code'
          isRequired={true}
          type='text'
          value={addWarehouseDetails?.warehouseCode}
          onChange={(e: any) => {
            setAddWarehouseDetails({
              ...addWarehouseDetails,
              warehouseCode: e.target.value,
            })
          }}
          isError={isErrorField.warehouseCode}
        />

        <Input
          labelValue='Warehouse Name'
          type='text'
          isRequired={true}
          value={addWarehouseDetails?.warehouseName}
          onChange={(e: any) => {
            setAddWarehouseDetails({
              ...addWarehouseDetails,
              warehouseName: e.target.value,
            })
          }}
          isError={isErrorField.warehouseName}
        />

        <Input
          labelValue='Warehouse Phone No'
          type='text'
          value={addWarehouseDetails?.phoneNo}
          onChange={(e: any) => {
            const regex = /^[+0-9\b\s]+$/
            if (e.target.value === '' || regex.test(e.target.value)) {
              setAddWarehouseDetails({
                ...addWarehouseDetails,
                phoneNo: e.target.value,
              })
            }
          }}
        />

        <Input
          labelValue='Warehouse Email'
          type='text'
          value={addWarehouseDetails?.email}
          onChange={(e: any) => {
            setAddWarehouseDetails({
              ...addWarehouseDetails,
              email: e.target.value,
            })
          }}
          isError={isErrorField?.email}
        />

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>
            Warehouse Time Zone
            <span className='text-R-500 ml-[2px]'>*</span>
          </span>

          <SimpleSelect
            value={addWarehouseDetails?.timeZone}
            onChange={(e: any) => {
              setAddWarehouseDetails({
                ...addWarehouseDetails,
                timeZone: e,
              })
            }}
            options={timeZoneList}
            placeholder='Select Warehouse Time Zone'
            isError={isErrorField.timeZone}
          />
        </div>

        <span className='text-sm font-semibold mt-4 text-N-900 uppercase'>
          Warehouse Address
        </span>

        <Input
          labelValue='Street Address'
          type='text'
          isRequired={true}
          value={addWarehouseDetails?.streetAddress}
          onChange={(e: any) => {
            setAddWarehouseDetails({
              ...addWarehouseDetails,
              streetAddress: e.target.value,
            })
          }}
          isError={isErrorField?.streetAddress}
        />

        <Input
          labelValue='Suburb'
          type='text'
          isRequired={true}
          value={addWarehouseDetails?.suburb}
          onChange={(e: any) => {
            setAddWarehouseDetails({
              ...addWarehouseDetails,
              suburb: e.target.value,
            })
          }}
          isError={isErrorField?.suburb}
        />

        <Input
          labelValue='State'
          type='text'
          isRequired={true}
          value={addWarehouseDetails?.state}
          onChange={(e: any) => {
            setAddWarehouseDetails({
              ...addWarehouseDetails,
              state: e.target.value,
            })
          }}
          isError={isErrorField?.state}
        />

        <div className='flex w-full gap-x-5'>
          <Input
            labelValue='State Code'
            type='text'
            isRequired={true}
            className='w-1/2'
            value={addWarehouseDetails?.stateCode}
            onChange={(e: any) => {
              setAddWarehouseDetails({
                ...addWarehouseDetails,
                stateCode: e.target.value,
              })
            }}
            isError={isErrorField?.stateCode}
          />

          <Input
            labelValue='Postal Code'
            type='text'
            isRequired={true}
            className='w-1/2'
            value={addWarehouseDetails?.postalCode}
            onChange={(e: any) => {
              setAddWarehouseDetails({
                ...addWarehouseDetails,
                postalCode: e.target.value,
              })
            }}
            placeholder='138576'
            isError={isErrorField?.postalCode}
          />
        </div>
        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>
            Country
            <span className='text-R-500 ml-[2px]'>*</span>
          </span>
          <SimpleSelect
            value={addWarehouseDetails?.country}
            onChange={(e: any) => {
              setAddWarehouseDetails({
                ...addWarehouseDetails,
                country: e,
              })
            }}
            options={countryList}
            isError={isErrorField?.country}
          />
        </div>

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>Warehouse Labels</span>
          <MultiSelect
            value={selectedWarehouseLabels}
            onChange={(selectedItems: any) => {
              const selectedLabels: string[] = selectedItems.map(
                (item: any) => item.value,
              )
              setAddWarehouseDetails({
                ...addWarehouseDetails,
                labelIds: selectedLabels,
              })
              setSelectedWarehouseLabels(selectedItems)
            }}
            options={labelList}
            isMulti={true}
            placeholder='Select label'
          />
        </div>

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>Status</span>
          <ToggleSwitch
            text={addWarehouseDetails?.status ? 'Active' : 'Inactive'}
            setValue={addWarehouseDetails?.status}
            onToggleClick={(e: any) => {
              setAddWarehouseDetails({
                ...addWarehouseDetails,
                status: e.target.checked,
              })
            }}
          />
        </div>
      </div>

      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm gap-x-5'>
        <Button
          isLoading={createWarehouseIsLoading}
          disabled={createWarehouseIsLoading}
          isFullWidth
          onClick={() => {
            handleAddClick()
          }}>
          Save
        </Button>

        <Button isFullWidth appearance='dull' onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </Drawer>
  )
}

AddWarehouse.defaultProps = {
  className: undefined,
}

export default AddWarehouse
