import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetInboundOrderCutoffTableInterface {
  take?: number
  skip?: number
  name?: string
  criteria?: any
  status?: any
}
export const useGetInboundOrderCutoffTable = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      name,
      criteria,
      status,
    }: GetInboundOrderCutoffTableInterface) => {
      return get(
        `/InboundOrderCutoffTimeList?take=${take}&skip=${skip}&search=${name}&criteria=${criteria.value}&status=${status.value}`,
      )
    },
  )
  return mutate
}
export default useGetInboundOrderCutoffTable
