import React, { FC } from 'react'
import CN from 'classnames'

import { Badge, Button } from 'components/atoms'
import { Drawer } from 'components/atoms/Drawer'

export interface UserView {
  [x: string]: any
}

export const UserView: FC<UserView> = ({
  className,
  isActive,
  userDetails,
  onCloseClick,
  setDeleteUserPopOutIsOpen,
  setUpdatePartnerDrawerIsOpen,
  setIsOpenViewUser,
  setIsAddNew,
  ...restProps
}: UserView) => {
  const UserViewClasses = CN(`user-view`, className)

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    roleName,
    isActive: status,
  } = userDetails

  return (
    <Drawer
      className={UserViewClasses}
      isActive={isActive}
      width={360}
      {...restProps}
      title='View User'
      onCloseClick={onCloseClick}>
      <div
        className='p-[24px] justify-between h-full relative'
        overflow-y='auto'>
        <div className='rounded-full user-view__profile-img bg-S-800 w-[64px] h-[64px] items-center justify-center'>
          <div className='flex items-center text-[25px] text-white font-medium justify-center w-full h-full'>
            {`DS`}
          </div>
        </div>
        <div className='user-view__first-name mt-[36px] flex flex-col gap-y-[2px]'>
          <span className='font-medium text-[14px] leading-[20px] text-N-500'>
            First Name :
          </span>
          <span className='font-medium text-[16px] leading-[20px] text-N-700'>
            {firstName}
          </span>
        </div>
        <div className='user-view__last-name mt-[14px] flex flex-col gap-y-[2px]'>
          <span className='font-medium text-[14px] leading-[20px] text-N-500'>
            Last Name :
          </span>
          <span className='font-medium text-[16px] leading-[20px] text-N-700'>
            {lastName}
          </span>
        </div>
        <div className='user-view__last-name mt-[14px] flex flex-col gap-y-[2px]'>
          <span className='font-medium text-[14px] leading-[20px] text-N-500'>
            Email :
          </span>
          <span className='font-medium text-[16px] leading-[20px] text-N-700'>
            {email}
          </span>
        </div>
        <div className='user-view__last-name mt-[14px] flex flex-col gap-y-[2px]'>
          <span className='font-medium text-[14px] leading-[20px] text-N-500'>
            Phone No :
          </span>
          <span className='font-medium text-[16px] leading-[20px] text-N-700'>
            {phoneNumber}
          </span>
        </div>
        <div className='user-view__last-name mt-[14px] flex flex-col gap-y-[2px]'>
          <span className='font-medium text-[14px] leading-[20px] text-N-500'>
            Role :
          </span>
          <span className='font-medium text-[16px] leading-[20px] text-N-700'>
            {roleName}
          </span>
        </div>
        <div className='user-view__last-name mt-[14px] flex flex-col gap-y-[2px]'>
          <span className='font-medium text-[14px] leading-[20px] text-N-500'>
            Status :
          </span>
          <Badge
            labelValue={status ? 'Active' : 'InActive'}
            appearance={status ? 'success' : 'error'}
          />
        </div>
      </div>
      <div className='absolute flex items-center justify-between shadow-sm drawer-footer border-N-200 left-[24px] bottom-[12px]'>
        <Button
          // isLoading={createPartnerIsLoading}
          // disabled={createPartnerIsLoading}
          appearance='outline'
          onClick={(e: any) => {
            setIsAddNew(false)
            setIsOpenViewUser(false)
            setUpdatePartnerDrawerIsOpen(true)
          }}>
          Edit User
        </Button>
        <Button
          className='ml-5 w-[152px] border'
          appearance='danger'
          onClick={() => {
            setDeleteUserPopOutIsOpen(true)
            onCloseClick()
          }}>
          Delete User
        </Button>
      </div>
    </Drawer>
  )
}

UserView.defaultProps = {}

export default UserView
