import { webSocketBaseUrl } from 'config/framework.config'

const eventListeners: Map<string, (message: any) => void> = new Map()
let socket: WebSocket | null = null
let token: string | null = null
let reconnectAttempts = 0
const maxReconnectAttempts = 5
const reconnectInterval = 3000
let isManuallyClosed = false

/**
 * Establish WebSocket connection with a dynamic controller
 */
export const connectWebSocket = (controller: string) => {
  console.log(`✅ WebSocket connected to ${controller}`)
  token = sessionStorage.getItem('token')
  if (!token) {
    console.error('WebSocket connection failed: No token found.')
    return
  }

  //const wsUrl = `${webSocketBaseUrl}${controller}`
  const wsUrl = `${webSocketBaseUrl}${controller}?token=${token}`
  console.log('wsUrl', wsUrl)
  socket = new WebSocket(wsUrl)
  isManuallyClosed = false

  socket.onopen = () => {
    console.log(`✅ WebSocket connected to ${wsUrl}`)
    reconnectAttempts = 0
  }

  socket.onmessage = (event) => {
    const message = event.data
    console.log('📩 WebSocket message received:', message)
    eventListeners.forEach((callback) => callback(message))
  }

  socket.onerror = (error) => {
    console.error('❌ WebSocket error:', error)
  }

  socket.onclose = () => {
    console.warn(`⚠️ WebSocket disconnected from ${wsUrl}`)
    if (!isManuallyClosed && reconnectAttempts < maxReconnectAttempts) {
      setTimeout(() => {
        console.log(
          `🔄 Reconnecting attempt ${reconnectAttempts + 1} to ${wsUrl}...`,
        )
        reconnectAttempts++
        connectWebSocket(controller)
      }, reconnectInterval)
    }
  }
}

/**
 * Disconnect WebSocket connection
 */
export const disconnectWebSocket = () => {
  isManuallyClosed = true
  if (socket) {
    socket.close()
    socket = null
    console.log('🔌 WebSocket manually disconnected')
  }
}

/**
 * Send a message through WebSocket
 */
export const sendMessage = (message: any) => {
  if (socket && socket.readyState === WebSocket.OPEN) {
    socket.send(JSON.stringify(message))
  } else {
    console.warn('⚠️ WebSocket is not connected. Cannot send message.')
  }
}

/**
 * Add event listener for incoming messages
 */
export const addWebSocketEventListener = (
  event: string,
  callback: (message: any) => void,
) => {
  eventListeners.set(event, callback)
}

/**
 * Remove event listener
 */
export const removeWebSocketEventListener = (event: string) => {
  eventListeners.delete(event)
}

/* Export WebSocket functions */
export default {
  connectWebSocket,
  disconnectWebSocket,
  sendMessage,
  addWebSocketEventListener,
  removeWebSocketEventListener,
}
