import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddPrintDetailsInterface {
  printUsers: {
    splitReferance?: string
    user?: string
    printedDate?: any
  }
}

export const useAddPrintDetails = () => {
  const mutate = useMutation(({ printUsers }: AddPrintDetailsInterface) => {
    return postAPI(`PWBulkConsignment/AddPrintDetails`, {
      printUsers: {
        splitReferance: printUsers?.splitReferance,
        user: printUsers?.user,
        printedDate: printUsers?.printedDate,
      },
    })
  })

  return mutate
}
