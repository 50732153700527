import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetInboundOrderCutOffCSV {
  criteria?: any
  status?: any
}
export const useGetInboundOrderCutOffCSV = () => {
  const mutate = useMutation(
    ({ criteria, status }: GetInboundOrderCutOffCSV) => {
      return get(
        `/InboundordercutOff/CSVList?&Criteria=${criteria?.value}&Status=${status?.value}`,
      )
    },
  )
  return mutate
}
export default GetInboundOrderCutOffCSV
