/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { useManageShipmentPackages } from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { SimpleSelect } from 'components/atoms/Select'

export interface UpdatePackageModalProps {
  isActive?: boolean
  className?: string
  isCustomStyles?: boolean
  onCloseClick?: () => void
  onOverlayClick?: () => void
  notify?: any
  shipmentPackageDetails?: any
  setShipmentPackageDetails?: any
  setIsUpdate?: any
  callOrderShipmentsById?: any
  selectedRowIds?: any
  setIsLoading?: any
}

const UpdatePackageModal: FC<UpdatePackageModalProps> = ({
  className = '',
  isActive = false,
  isCustomStyles = false,
  onCloseClick,
  onOverlayClick,
  notify,
  shipmentPackageDetails,
  setIsUpdate,
  setIsLoading,
  callOrderShipmentsById,

  selectedRowIds = [],
}) => {
  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    event.stopPropagation()
    //onOverlayClick()
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }
  const [packageData, setPackageData] = useState<any>({
    packageType: 'Carton',
    width: 0,
    length: 0,
    height: 0,
    weight: 0,
  })
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true)

  const packageTypes = [
    { value: 'Carton', label: 'Carton' },
    { value: 'Pallet', label: 'Pallet' },
    { value: 'Envelope', label: 'Envelope' },
    { value: 'Satchel', label: 'Satchel' },
    { value: 'Paper Roll', label: 'Paper Roll' },
  ]

  useEffect(() => {
    const isValid = validatePackageDimensions(
      Number(packageData?.length),
      Number(packageData?.width),
      Number(packageData?.height),
      Number(packageData?.weight),
    )

    setIsConfirmButtonDisabled(!isValid)
  }, [packageData])

  const validatePackageDimensions = (
    length: number,
    width: number,
    height: number,
    weight: number,
  ) => {
    let count = 0
    if (length > 0) count++
    if (width > 0) count++
    if (height > 0) count++
    return weight > 0 && count > 2
  }

  const {
    mutate: manageShipmentPackagesMutate,
    isLoading: loadingCreatePackage,
  } = useManageShipmentPackages()

  /** Process the Create Shipment Package */
  async function manageShipmentPackages() {
    manageShipmentPackagesMutate(
      {
        warehouseOrderIds: selectedRowIds,
        packageDetail: packageData,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          setIsUpdate(true)
          if (onCloseClick) {
            onCloseClick()
          }
          // callOrderShipmentsById()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  const handleConfirmClick = (event: any) => {
    setIsLoading(true)
    event.stopPropagation()
    manageShipmentPackages()
  }

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        { hidden: !isActive },
      )
    : className

  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-[458px] bg-white rounded-md flex-col items-center shadow-lg  space-y-6'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header */}
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Bulk Update</h2>
          <button
            onClick={(e: any) => handleOnCloseClick(e)}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>

        {/* Modal Body */}
        <div className='flex flex-col w-full pt-6 px-4 h-[350px] overflow-y-auto styled-scroll space-y-6'>
          {/* Package Type Section */}
          <div className='flex items-center justify-between w-full mb-4'>
            <div className='flex flex-col pr-4 w-2/3'>
              <p className='text-xs font-medium text-N-700 pb-2'>
                Package Type
              </p>
              <SimpleSelect
                options={packageTypes}
                disabled={true}
                value={
                  shipmentPackageDetails?.packageType ||
                  packageTypes.find((item) => item.label === 'Carton')
                }
                onChange={(e: any) => {
                  setPackageData({
                    ...packageData,
                    packageType: e.value,
                  })
                }}
              />
            </div>
          </div>

          {/* Weight Section */}
          <div className='flex flex-col w-full mb-4 gap-4'>
            <div className='flex items-center'>
              <span className='text-xs font-medium text-N-700 pb-2'>
                Weight (kg)
              </span>
              <span className='text-R-500 ml-1 leading-[16px]'>*</span>
            </div>

            <Input
              type='number' // Use 'text' for better control
              placeholder='0'
              className='border border-N-300 rounded-md w-20 text-center'
              onChange={(e: any) => {
                const numericValue =
                  e.target.value.replace(/[^0-9]/g, '') || '0'
                setPackageData({
                  ...packageData,
                  weight: numericValue,
                })
              }}
            />
          </div>

          {/* Dimensions Section */}
          <div className='flex flex-col w-full gap-4'>
            <div className='flex items-center pb-2'>
              <span className='text-xs font-medium text-N-700'>
                Dimensions (CM)
              </span>
              <span className='text-R-500 ml-1 leading-[16px]'>*</span>
            </div>

            <div className='flex gap-2'>
              <div className='flex items-center gap-1'>
                <Input
                  type='number'
                  placeholder='H'
                  className='border border-N-300 rounded-md w-20 text-center'
                  onChange={(e: any) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '')
                    setPackageData({
                      ...packageData,
                      height: numericValue,
                    })
                  }}
                />
              </div>

              <div className='flex items-center gap-1'>
                <Input
                  type='number'
                  placeholder='L'
                  className='border border-N-300 rounded-md w-20 text-center'
                  onChange={(e: any) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '')
                    setPackageData({
                      ...packageData,
                      length: numericValue,
                    })
                  }}
                />
              </div>

              <div className='flex items-center gap-1'>
                <Input
                  type='number'
                  placeholder='W'
                  className='border border-N-300 rounded-md w-20 text-center'
                  onChange={(e: any) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '')
                    setPackageData({
                      ...packageData,
                      width: numericValue,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Modal Footer */}
        <div className='w-full border-t border-t-N-200 mt-9 px-5 py-4 flex items-center justify-between'>
          <Button
            onClick={(e: any) => handleConfirmClick(e)}
            disabled={isConfirmButtonDisabled}
            isLoading={false}
            className='bg-orange-500 text-white px-6 py-2 rounded-md hover:bg-orange-600 transition'>
            Save
          </Button>

          <Button
            className='bg-N-200 px-6 py-2 rounded-md hover:bg-N-300 transition'
            appearance='dull'
            onClick={onCloseClick}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UpdatePackageModal
