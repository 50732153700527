import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface ManageShipmentPackagesInterface {
  warehouseOrderIds: any
  packageDetail: {
    packageType: string
    packageQty: string | number
    width: string | number
    height: string | number
    length: string | number
    weight: string | number
  }
}

export const useManageShipmentPackages = () => {
  const mutate = useMutation(
    ({ warehouseOrderIds, packageDetail }: ManageShipmentPackagesInterface) => {
      return postAPI(`PWBulkConsignment/ManagePackages`, {
        warehouseOrderIds: warehouseOrderIds.map(
          (order: { id: any }) => order.id,
        ),
        package: {
          qty: 1,
          width: Number(packageDetail?.width),
          height: Number(packageDetail?.height),
          length: Number(packageDetail?.length),
          weight: Number(packageDetail?.weight),
          type: 'Carton',
        },
      })
    },
  )

  return mutate
}

export default useManageShipmentPackages
