/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { FaPrint } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import CN from 'classnames'
import {
  useAddPrintDetails,
  useGetPWBulkConsignmentHistory,
} from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { CheckBox } from 'components/atoms/Checkbox'
import { QuantityPicker } from 'components/atoms/QuantityPicker'

import 'react-circular-progressbar/dist/styles.css'

const Table = ({
  columns,
  data,
  setTableVariables,
  tableVariables,
  totalRowCount,
  setIsLoading,
  pageNumber,
  setPageNumber,
  status,
  setbulkreferance,
  FromHistory,
  setFromHistory,
  setWarehouseDetails,
  createPwShipmentConsignment,
  progressDetails,
  setStatus,
  setSummaryData,
  userDetails,
}) => {
  const [tableData, setTableData] = useState(data)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    toggleRowSelected,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageSize: 10,
        pageIndex: 0,
      },
      manualPagination: true,
      manualSortBy: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableSortBy: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
  )

  /** Pagination States */
  const [paginationVariables, setPaginationVariables] = useState({
    start: 0,
    end: 0,
  })

  const [bulkConsignment, setBulkConsignment] = useState([])

  // Update status when progress reaches 100%
  const handleProgressUpdate = (splitReferance, progress) => {
    getPWBulkConsignmentHistoryList(splitReferance)
  }
  const progressHandledRef = useRef(new Set())
  const bulkConsignmentRef = useRef([])

  useEffect(() => {
    const updatedData = tableData.map((rowData) => {
      const matchedConsignment = bulkConsignment.find(
        (item) => item.splitReferance === rowData.splitReferance,
      )

      if (matchedConsignment) {
        return {
          ...rowData,
          status: matchedConsignment.status, // Update the status from the matched consignment
        }
      }

      return rowData // No match, keep as is
    })
    setTableData(updatedData)
  }, [bulkConsignment])

  /** API Call for Get History List */
  const {
    mutate: getPWBulkConsignmentHistoryListMutate,
    //isLoading: getPWBulkConsignmentHistoryListIsLoading,
  } = useGetPWBulkConsignmentHistory()

  /** Process the Get Similar Order List */
  async function getPWBulkConsignmentHistoryList(splitReferance) {
    getPWBulkConsignmentHistoryListMutate(
      {
        take: tableVariables.take,
        skip: tableVariables.skip,
        splitReferance: splitReferance,
      },
      {
        onSuccess: ({ data: successData }) => {
          setBulkConsignment([
            ...bulkConsignment,
            ...(successData?.summaryList || []),
          ])
        },
      },
    )
  }

  useEffect(() => {
    Object.entries(progressDetails).forEach(([splitReferance, progress]) => {
      if (progress === 100 && !progressHandledRef.current.has(splitReferance)) {
        handleProgressUpdate(splitReferance, progress) // Update status to 'Completed' when progress is 100%
        progressHandledRef.current.add(splitReferance)
      }
    })
  }, [progressDetails])

  useEffect(() => {
    if (data) {
      setTableData(data)
    }
  }, [data])
  /** Pagination Functions */
  useEffect(() => {
    setPaginationVariables({
      ...paginationVariables,
      start:
        Number(tableVariables.take) * Number(pageNumber) -
        Number(tableVariables.take) +
        1,
      end:
        Number(tableVariables.take) * Number(pageNumber) >= totalRowCount
          ? totalRowCount
          : Number(tableVariables.take) * Number(pageNumber),
    })
  }, [tableVariables, totalRowCount, pageNumber])

  const getSydneyTime = () => {
    const options = {
      timeZone: 'Australia/Sydney',
      hour12: true,
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }

    // Get current time in Sydney timezone
    const sydneyTime = new Intl.DateTimeFormat('en-AU', options).format(
      new Date(),
    )

    return sydneyTime
  }
  const formatDate = (dateStr) => {
    const date = new Date(dateStr)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear()).slice(-2)

    let hours = date.getHours()
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const ampm = hours >= 12 ? 'PM' : 'AM'

    hours = hours % 12
    hours = hours ? hours : 12 // hour '0' should be '12'
    const hourStr = String(hours).padStart(2, '0')

    return `${day}/${month}/${year}, ${hourStr}:${minutes} ${ampm}`
  }

  /** Pagination Functions */
  useEffect(() => {
    setTableVariables((prev) => ({
      ...prev,
      skip: pageNumber === 1 ? 0 : Number(prev.take) * (Number(pageNumber) - 1),
    }))
  }, [pageNumber])

  const handleConsignClick = (row) => {
    const updatedData = tableData.map((rowData) =>
      rowData.splitReferance === row.original.splitReferance
        ? { ...rowData, status: 'InProgress' } // Update status for the specific row
        : rowData,
    )

    // Update the tableData state with the new status
    setTableData(updatedData)
    setStatus('Completed')
    createPwShipmentConsignment(row.original.splitReferance)
  }

  const slicedPageData = page

  const { mutate: addPrintDetailsMutate, isLoading: loadingPrintDetails } =
    useAddPrintDetails()

  /** Process the Create Shipment Package */
  async function addprintDetails(printDetails) {
    addPrintDetailsMutate(
      {
        printUsers: printDetails,
      },
      {
        onSuccess: ({ data: successData }) => {
          notify(successData.message || 'success', 'success')
        },
        onError: ({ response: { data: errData } }) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  return (
    <div className='styled-scroll h-full relative overflow-y-auto rounded border bg-white border-N-200'>
      <table className='w-full overflow-y-auto' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={CN(
                    'bg-N-100 border-b border-b-N-200 sticky top-0 shadow-sm text-left pl-4 z-[1]',
                    {
                      'bg-N-200': column.isSorted,
                    },
                  )}
                  style={{ height: 40 }}
                  width={column.customWidth}>
                  <div
                    className='inline-flex items-center'
                    style={{ minHeight: 20 }}>
                    {column.render('Header')}
                    <span className='flex ml-1' style={{ width: 14 }}>
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <i className='ri-arrow-up-s-line' />
                        ) : (
                          <i className='ri-arrow-down-s-line' />
                        ))}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {slicedPageData.map((row) => {
            prepareRow(row)
            return (
              <tr
                key={row.serviceSplitReference}
                className={CN('h-11 cursor-pointer', {})}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={CN(
                      'border-b border-b-N-200 text-sm text-N-700 py-[11px] px-4',
                      {
                        'bg-N-300 border-b border-b-N-400': cell.isSelected,
                      },
                    )}>
                    {cell.column.id == 'action' ? (
                      <div
                        className='line-clamp-2 flex items-center'
                        style={{
                          overflow: 'visible',
                          whiteSpace: 'normal',
                        }}>
                        {row.original.status === 'Pending' && (
                          <button
                            style={{
                              backgroundColor: '#F1A248',
                              color: 'white',
                              border: 'none',
                              padding: '8px 12px',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              WebkitAppearance: 'button',
                              backgroundImage: 'none',
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              if (FromHistory) {
                                setbulkreferance(row.original.bulkReferance)
                                setFromHistory(true)
                                setTableVariables({
                                  ...tableVariables,
                                  take: 10,
                                  skip: 0,
                                })
                                setWarehouseDetails(
                                  row.original.warehouseDetails,
                                )
                              } else {
                                handleConsignClick(row)
                              }
                            }}>
                            Consign
                          </button>
                        )}

                        {row.original.status === 'InProgress' &&
                          !FromHistory && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 75,
                                height: 75,
                              }}>
                              <CircularProgressbar
                                styles={{
                                  path: {
                                    stroke: '#4CAF50', // Green color for progress
                                  },
                                  text: {
                                    fill: '#4CAF50', // Green color for text
                                    fontSize: '16px', // Optional: Change font size
                                  },
                                  trail: {
                                    stroke: '#d6d6d6', // Optional: Grey color for the background trail
                                  },
                                }}
                                value={
                                  progressDetails[
                                    row.original.splitReferance
                                  ] || 0
                                } // Get the progress dynamically
                                text={`${
                                  progressDetails[
                                    row.original.splitReferance
                                  ] || 0
                                }%`}
                              />
                            </div>
                          )}

                        {row.original.status === 'InProgress' &&
                          FromHistory && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 75,
                                height: 75,
                              }}>
                              <CircularProgressbar
                                styles={{
                                  path: {
                                    stroke: '#4CAF50', // Green color for progress
                                  },
                                  text: {
                                    fill: '#4CAF50', // Green color for text
                                    fontSize: '16px', // Optional: Change font size
                                  },
                                  trail: {
                                    stroke: '#d6d6d6', // Optional: Grey color for the background trail
                                  },
                                }}
                                value={
                                  progressDetails[
                                    row.original.splitReferance
                                  ] ?? 0
                                } // If key is undefined or null, fallback to 0
                                text={`${
                                  progressDetails[
                                    row.original.splitReferance
                                  ] ?? 0
                                }%`} // Show progress or 0% if the key is missing
                              />
                            </div>
                          )}

                        {row.original.status === 'Failed' && (
                          <div>
                            <a
                              href='#'
                              //onClick={handleClick}
                              style={{
                                fontWeight: 'bold',
                                color: 'black',
                                textDecoration: 'none',
                                borderBottom: '1px solid #000', // This adds the underline effect
                              }}>
                              Retry
                            </a>
                          </div>
                        )}
                      </div>
                    ) : cell.column.id == 'print' ? (
                      <div
                        className='line-clamp-2 flex items-center'
                        style={{
                          overflow: 'visible',
                          whiteSpace: 'normal',
                        }}>
                        {row.original.status === 'Completed' && (
                          <div>
                            <button
                              onClick={() => {
                                {
                                  if (!FromHistory) {
                                    const matchedConsignment =
                                      bulkConsignment.find(
                                        (item) =>
                                          item.splitReferance ===
                                          row.original.splitReferance,
                                      )

                                    const bulkURL =
                                      matchedConsignment?.bulkLabelURL ||
                                      row.original.bulkLabelURL

                                    window.open(bulkURL, '_blank')

                                    const updatedData = tableData.map(
                                      (rowData) =>
                                        rowData.splitReferance ===
                                        row.original.splitReferance
                                          ? { ...rowData, status: 'Printed' }
                                          : rowData,
                                    )

                                    setTableData(updatedData)
                                  } else {
                                    window.open(
                                      row.original.bulkLabelURL,
                                      '_blank',
                                    )
                                    const updatedData = tableData.map(
                                      (rowData) =>
                                        rowData.splitReferance ===
                                        row.original.splitReferance
                                          ? { ...rowData, status: 'Printed' } // Update the status
                                          : rowData,
                                    )
                                    setTableData(updatedData)
                                  }
                                  const printUser = {
                                    user: `${userDetails.firstName} ${userDetails.lastName}`,
                                    printedDate: new Date().toISOString(),
                                    splitReferance: row.original.splitReferance,
                                  }
                                  addprintDetails(printUser)
                                }
                              }}>
                              <FaPrint
                                style={{
                                  fontSize: '24px',
                                  color: '#F1A248',
                                }}
                              />
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                  paddingRight: '10px',
                                }}>
                                {row.original.printDetails ? (
                                  <>
                                    Printed on{' '}
                                    {formatDate(
                                      row.original.printDetails.printedDate,
                                    )}{' '}
                                    by {row.original.printDetails?.user}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </button>
                          </div>
                        )}
                        {row.original.status === 'Printed' && FromHistory && (
                          <div>
                            {/* Optional: Display message after print */}
                            <div>
                              <button
                                onClick={() => {
                                  const bulkURL =
                                    row.original.splitReferance ===
                                    bulkConsignment?.splitReferance
                                      ? bulkConsignment.bulkLabelURL
                                      : ''

                                  window.open(bulkURL, '_blank')
                                  const updatedData = tableData.map((rowData) =>
                                    rowData.splitReferance ===
                                    row.original.splitReferance
                                      ? { ...rowData, status: 'Printed' } // Update the status
                                      : rowData,
                                  )
                                  setTableData(updatedData)
                                }}>
                                <FaPrint
                                  style={{
                                    fontSize: '24px',
                                    color: '#F1A248',
                                  }}
                                />
                              </button>
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                  paddingRight: '10px',
                                }}>
                                <>
                                  Printed on {getSydneyTime()} by{' '}
                                  {userDetails.firstName} {userDetails.lastName}
                                </>
                              </span>
                            </div>
                          </div>
                        )}

                        {row.original.status === 'Printed' && !FromHistory && (
                          <div>
                            {/* Optional: Display message after print */}
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                                paddingRight: '10px',
                              }}>
                              <>
                                Printed on {getSydneyTime()} by{' '}
                                {userDetails.firstName} {userDetails.lastName}
                              </>
                            </span>
                          </div>
                        )}
                      </div>
                    ) : cell.column.id === 'status' ? (
                      <div
                        className='line-clamp-2 flex items-center'
                        style={{
                          overflow: 'visible',
                          whiteSpace: 'normal',
                        }}>
                        {row.original.status === 'Pending' && (
                          <span
                            style={{
                              color: '#85817b',
                              fontSize: '16px',
                              fontWeight: '600',
                            }}>
                            Pending
                          </span>
                        )}
                        {row.original.status === 'InProgress' && (
                          <span
                            style={{
                              color: '#bf8737',
                              fontSize: '16px',
                              fontWeight: '600',
                            }}>
                            In Progresss
                          </span>
                        )}
                        {(row.original.status === 'Completed' ||
                          row.original.status === 'Printed') && (
                          <span
                            style={{
                              color: '#11a651',
                              fontSize: '16px',
                              fontWeight: '600',
                            }}>
                            Completed
                          </span>
                        )}

                        {row.original.status === 'Failed' && (
                          <span
                            style={{
                              color: '#8a0e0e',
                              fontSize: '16px',
                              fontWeight: '600',
                            }}>
                            Failed
                          </span>
                        )}
                      </div>
                    ) : (
                      cell.render('Cell')
                    )}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>

      {/* Pagination */}
      <div className='flex items-center justify-between px-3 py-[10px] border border-N-200 rounded-b-md overflow-auto'>
        <div className='flex items-center'>
          <span className='text-sm text-N-700'>Show rows per page</span>
          <select
            className='w-20 ml-2 border rounded-md outline-none border-N-200 h-7'
            value={tableVariables.take}
            onChange={(e) => {
              setTableVariables({ ...tableVariables, take: e.target.value })
            }}>
            {[10, 20, 30, 50].map((pageSizeValue) => (
              <option key={pageSizeValue} value={pageSizeValue}>
                {pageSizeValue}
              </option>
            ))}
          </select>
        </div>
        <div className='flex'>
          <div className='flex items-center text-sm text-N-700 mr-9'>
            <p>
              {paginationVariables.start} - {paginationVariables.end} of{' '}
              {totalRowCount}
            </p>
          </div>
          <Button
            className='mr-8'
            appearance='dull'
            size='xs'
            onClick={() => {
              setPageNumber(pageNumber - 1)
            }}
            disabled={pageNumber <= 1}>
            <i className='ri-arrow-left-s-line' />
          </Button>
          <Button
            className='mr-2'
            appearance='dull'
            size='xs'
            onClick={() => {
              setPageNumber(pageNumber + 1)
            }}
            disabled={
              Number(pageNumber) * Number(tableVariables.take) >
              Number(totalRowCount)
            }>
            <i className='ri-arrow-right-s-line' />
          </Button>
        </div>
      </div>
    </div>
  )
}

Table.propTypes = {}

export default Table
