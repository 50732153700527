import React, { useState } from 'react'
import { FaCheck, FaPen, FaTimes } from 'react-icons/fa'

import { Button } from 'components/atoms'

interface PickedQtyCellProps {
  row: {
    original: {
      id: number | string // Adjust the type based on your data (e.g., string or number)
      pickedQty: number
      qty: number
    }
  }
  onConfirm: (shipmentLineId: number | string, newValue: number) => void // Function to call on confirm
}

const PickedQtyCell: React.FC<PickedQtyCellProps> = ({ row, onConfirm }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [tempValue, setTempValue] = useState<number>(row.original.pickedQty)

  const handleConfirm = () => {
    onConfirm(row.original.id, tempValue) // Call the provided onConfirm function with row ID and new value
    setIsEditing(false) // Exit edit mode
  }

  const handleDecline = () => {
    setTempValue(row.original.pickedQty) // Reset to original value
    setIsEditing(false) // Exit edit mode
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {isEditing ? (
        <input
          type='number'
          value={tempValue}
          onChange={(e) => setTempValue(Number(e.target.value))}
          style={{
            width: '80px', // Slightly wider for better visibility
            height: '30px', // Adjust height for better appearance
            textAlign: 'center', // Center align the text
            border: '1px solid #ccc', // Light grey border
            borderRadius: '4px', // Rounded corners
            fontSize: '14px', // Professional font size
            padding: '4px', // Add padding for better spacing
            outline: 'none', // Remove default outline
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for a 3D effect
            backgroundColor: '#f9f9f9', // Slightly off-white background
            transition: 'border-color 0.3s, box-shadow 0.3s', // Smooth hover effect
          }}
          max={row.original.qty}
          min={1}
        />
      ) : (
        <span style={{ width: '50px', textAlign: 'center' }}>
          {row.original.pickedQty}
        </span>
      )}
      {isEditing ? (
        <>
          <button onClick={handleConfirm} style={acceptButtonStyle}>
            <FaCheck size={14} />
          </button>
          <button onClick={handleDecline} style={declineButtonStyle}>
            <FaTimes size={14} />
          </button>
        </>
      ) : (
        <Button
          size='xs'
          onClick={() => setIsEditing(true)}
          style={{ cursor: 'pointer' }}>
          <FaPen size={14} />
        </Button>
      )}
    </div>
  )
}

const acceptButtonStyle = {
  backgroundColor: '#ededed', // Black background
  color: '#45803c', // green text/icon
  border: 'none', // Remove border
  borderRadius: '4px', // Rounded corners
  padding: '5px 10px', // Padding
  cursor: 'pointer', // Pointer cursor on hover
  display: 'flex', // Flex for centering
  alignItems: 'center', // Vertically center
  justifyContent: 'center', // Horizontally center
}
const declineButtonStyle = {
  backgroundColor: '#ededed', // Black background
  color: '#870707', // red text/icon
  border: 'none', // Remove border
  borderRadius: '4px', // Rounded corners
  padding: '5px 10px', // Padding
  cursor: 'pointer', // Pointer cursor on hover
  display: 'flex', // Flex for centering
  alignItems: 'center', // Vertically center
  justifyContent: 'center', // Horizontally center
}
export default PickedQtyCell
