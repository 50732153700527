import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateWarehouseInterface {
  country?: any
  email?: string
  phoneNo?: string
  postalCode?: string
  state?: string
  stateCode?: string
  status?: boolean
  streetAddress?: string
  suburb?: string
  timeZone?: any
  warehouseCode?: string
  warehouseName?: string
  labelIds?: any
}

export const useCreateWarehouse = () => {
  const mutate = useMutation(
    ({
      warehouseName,
      warehouseCode,
      phoneNo,
      email,
      streetAddress,
      suburb,
      state,
      stateCode,
      country,
      postalCode,
      status,
      timeZone,
      labelIds,
    }: CreateWarehouseInterface) => {
      return postAPI('/Warehouse', {
        code: warehouseCode,
        name: warehouseName,
        phoneNo,
        email,
        isActive: status,
        address: {
          lineOne: streetAddress,
          lineTwo: '',
          city: suburb,
          suburb: suburb,
          state: state,
          stateCode: stateCode,
          country: country.label,
          countryCode: country.value,
          postCode: postalCode,
        },
        timeZone: timeZone?.value,
        ...(labelIds && labelIds !== '' && { labelIds }),
      })
    },
  )

  return mutate
}

export default useCreateWarehouse
