import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreatePackageTemplateInterface {
  partnerCode?: string
  orderType?: string
  templateName: string
  isCustomized?: boolean
  packageData?: any
}

export const useCreatePackageTemplate = () => {
  const mutate = useMutation(
    ({
      packageData,
      partnerCode,
      orderType,
      templateName,
      isCustomized,
    }: CreatePackageTemplateInterface) => {
      const payload = {
        Type: packageData?.packageType?.value, // Change from 'type' to 'Type'
        width: Number(packageData?.width),
        height: Number(packageData?.height),
        length: Number(packageData?.length),
        weight: Number(packageData?.weight),
        partnerCode: partnerCode || '', // Ensuring it's set correctly
        TemplateName: templateName, // Change from 'templateName' to 'TemplateName'
        isCustomized: isCustomized, // Ensure this is sent if required
        orderType: orderType || '', // Ensure this is sent correctly
      }
      // Call the API
      return postAPI(`/CreatePackageTemplate`, payload)
    },
  )

  return mutate
}

export default useCreatePackageTemplate
