/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react'
import CN from 'classnames'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({ isBulk, itemData, className, ...restProps }) => {
  const [data, setData] = useState([])
  const columns = useMemo(() => {
    return isBulk
      ? columnsStructure.filter((column) => column.accessor !== 'labelUrl') // Exclude labelUrl column if isBulk is true
      : columnsStructure
  }, [isBulk])
  useEffect(() => {
    if (itemData?.length > 0) {
      setData([...itemData])
    }
  }, [itemData, isBulk])

  return (
    <div className={CN('data-table w-full text-sm', className)} {...restProps}>
      <Table data={data} columns={columns} />
    </div>
  )
}

export default DataTable
