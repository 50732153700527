import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateOrderOnHoldInterface {
  partnerOrderId?: string
  reason?: string
  date?: any
}
export const useCreateOrderOnHold = () => {
  const mutate = useMutation(
    ({ partnerOrderId, reason, date }: CreateOrderOnHoldInterface) => {
      return postAPI(`/OrderOnHold`, {
        partnerOrderId,
        holdReason: reason,
        dateTobeReleased: date,
      })
    },
  )
  return mutate
}
export default useCreateOrderOnHold
