import { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import { useGetWarehouseById, useUpdateWarehouse } from 'framework/api/methods'

import { JsonLoader } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'
import { Input } from 'components/atoms/Input'
import { MultiSelect } from 'components/atoms/MultiSelect'
import { SimpleSelect } from 'components/atoms/Select'
import { ToggleSwitch } from 'components/atoms/ToggleSwitch'
import { isEmail } from 'utils'

export interface UpdateWarehouseProps {
  className?: string | undefined
  [x: string]: any
}

export const UpdateWarehouse: FC<UpdateWarehouseProps> = ({
  className,
  countryList,
  getWarehousesList,
  isActive,
  notify,
  onCloseClick,
  selectedRowByButtonAction,
  labelList,
}: UpdateWarehouseProps) => {
  const AddWarehousesClasses = CN(
    'UpdateWarehouses flex flex-col py-4 px-5 relative h-full overflow-y-auto overscroll-y-auto styled-scroll gap-y-5',
    className,
    {},
  )
  const [updateWarehouseDetails, setUpdateWarehouseDetails] = useState<any>({
    warehouseId: '',
    labelIds: '',
    status: true,
    warehouseName: '',
    warehouseCode: '',
    phoneNo: '',
    email: '',
    streetAddress: '',
    suburb: '',
    state: '',
    stateCode: '',
    postalCode: '',
    country: '',
    timeZone: '',
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    warehouseName: false,
    warehouseCode: false,
    email: false,
    streetAddress: false,
    suburb: false,
    state: false,
    stateCode: false,
    postalCode: false,
    country: false,
    timeZone: false,
  })

  const timeZoneList = [
    {
      label: 'AWST (UTC+08:00)',
      value: 'AWST (UTC+08:00)',
    },
    {
      label: 'AEST (UTC+10:00)',
      value: 'AEST (UTC+10:00)',
    },
    {
      label: 'ACST (UTC+09:30)',
      value: 'ACST (UTC+09:30)',
    },
    {
      label: 'NFT (UTC+11:00)',
      value: 'NFT (UTC+11:00)',
    },
    {
      label: 'CXT (UTC+07:00)',
      value: 'CXT (UTC+07:00)',
    },
    {
      label: 'CCT (UTC+06:30)',
      value: 'CCT (UTC+06:30)',
    },
  ]

  const [selectedWarehouseLabels, setSelectedWarehouseLabels] = useState([])
  /** API Call using react-query */
  const {
    mutate: getWarehouseByIdMutate,
    isLoading: getWarehouseByIdIsLoading,
  } = useGetWarehouseById()

  /** Process the get warehouse Table Data */
  async function getWarehouseById(id: any): Promise<void> {
    getWarehouseByIdMutate(
      { id },
      {
        onSuccess: ({ data: successData }: any) => {
          const {
            isActive,
            name,
            code,
            phoneNo,
            email,
            address,
            timeZone,
            warehouseLabels,
          } = successData.warehouse

          setUpdateWarehouseDetails({
            ...updateWarehouseDetails,
            warehouseId: id,
            status: isActive,
            warehouseName: name,
            warehouseCode: code,
            labelIds: warehouseLabels.map((item: any) => item.value),
            phoneNo,
            email,
            streetAddress: address.lineOne,
            suburb: address.suburb,
            state: address.state,
            stateCode: address.stateCode,
            postalCode: address.postCode,
            country: { value: address.countryCode, label: address.country },
            timeZone: { value: timeZone, label: timeZone },
          })
          setSelectedWarehouseLabels(
            warehouseLabels.map((item: any) => ({
              label: item.labelName,
              value: item.labelId,
            })),
          )
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
          onCloseClick()
        },
      },
    )
  }

  const { mutate: updateWarehouseMutate, isLoading: updateWarehouseIsLoading } =
    useUpdateWarehouse()

  /** Process the Update warehouse data */
  async function updateWarehouse() {
    updateWarehouseMutate(updateWarehouseDetails, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData.message, 'success')
        onCloseClick()
        getWarehousesList()
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  useEffect(() => {
    getWarehouseById(selectedRowByButtonAction.id)
  }, [selectedRowByButtonAction])

  const validateInputs = () => {
    const {
      warehouseName,
      warehouseCode,
      email,
      streetAddress,
      suburb,
      state,
      stateCode,
      postalCode,
      country,
      timeZone,
    } = updateWarehouseDetails

    setIsErrorField({
      ...isErrorField,
      warehouseName: warehouseName ? false : true,
      warehouseCode: warehouseCode ? false : true,
      streetAddress: streetAddress ? false : true,
      suburb: suburb ? false : true,
      state: state ? false : true,
      stateCode: stateCode ? false : true,
      postalCode: postalCode ? false : true,
      country: country ? false : true,
      email: isEmail(email) || email === '' ? false : true,
      timeZone: timeZone ? false : true,
    })

    const isAllValidFields =
      warehouseName &&
      warehouseCode &&
      (isEmail(email) || email === '') &&
      streetAddress &&
      suburb &&
      state &&
      stateCode &&
      postalCode &&
      country &&
      timeZone
    return isAllValidFields
  }

  const handleUpdateClick = () => {
    const valid = validateInputs()

    if (valid) {
      updateWarehouse()
    }
  }

  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      width={400}
      title='Update Warehouse'>
      <div className={AddWarehousesClasses}>
        {getWarehouseByIdIsLoading && (
          <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
            <JsonLoader size='xs' />
          </div>
        )}

        <Input
          labelValue='Warehouse Code'
          type='text'
          isRequired={true}
          value={updateWarehouseDetails.warehouseCode}
          onChange={(e: any) => {
            setUpdateWarehouseDetails({
              ...updateWarehouseDetails,
              warehouseCode: e.target.value,
            })
          }}
          isError={isErrorField.warehouseCode}
        />

        <Input
          labelValue='Warehouse Name'
          type='text'
          isRequired={true}
          value={updateWarehouseDetails?.warehouseName}
          onChange={(e: any) => {
            setUpdateWarehouseDetails({
              ...updateWarehouseDetails,
              warehouseName: e.target.value,
            })
          }}
          isError={isErrorField.warehouseName}
        />

        <Input
          labelValue='Warehouse Phone No'
          type='text'
          value={updateWarehouseDetails?.phoneNo}
          onChange={(e: any) => {
            const regex = /^[+0-9\b\s]+$/
            if (e.target.value === '' || regex.test(e.target.value)) {
              setUpdateWarehouseDetails({
                ...updateWarehouseDetails,
                phoneNo: e.target.value,
              })
            }
          }}
        />

        <Input
          labelValue='Warehouse Email'
          type='text'
          value={updateWarehouseDetails?.email}
          onChange={(e: any) => {
            setUpdateWarehouseDetails({
              ...updateWarehouseDetails,
              email: e.target.value,
            })
          }}
          isError={isErrorField?.email}
        />

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>
            Warehouse Time Zone
            <span className='text-R-500 ml-[2px]'>*</span>
          </span>

          <SimpleSelect
            value={updateWarehouseDetails?.timeZone}
            onChange={(e: any) => {
              setUpdateWarehouseDetails({
                ...updateWarehouseDetails,
                timeZone: e,
              })
            }}
            options={timeZoneList}
            placeholder='Select Warehouse Time Zone'
            isError={isErrorField?.timeZone}
          />
        </div>

        <span className='text-sm font-semibold mt-4 text-N-900 uppercase'>
          Warehouse Address
        </span>

        <Input
          labelValue='Street Address'
          type='text'
          isRequired={true}
          value={updateWarehouseDetails?.streetAddress}
          onChange={(e: any) => {
            setUpdateWarehouseDetails({
              ...updateWarehouseDetails,
              streetAddress: e.target.value,
            })
          }}
          isError={isErrorField.streetAddress}
        />

        <Input
          labelValue='Suburb'
          type='text'
          isRequired={true}
          value={updateWarehouseDetails?.suburb}
          onChange={(e: any) => {
            setUpdateWarehouseDetails({
              ...updateWarehouseDetails,
              suburb: e.target.value,
            })
          }}
          isError={isErrorField.suburb}
        />

        <Input
          labelValue='State'
          type='text'
          isRequired={true}
          value={updateWarehouseDetails?.state}
          onChange={(e: any) => {
            setUpdateWarehouseDetails({
              ...updateWarehouseDetails,
              state: e.target.value,
            })
          }}
          isError={isErrorField.state}
        />

        <div className='flex w-full gap-x-5'>
          <Input
            labelValue='State Code'
            type='text'
            isRequired={true}
            className='w-1/2'
            value={updateWarehouseDetails?.stateCode}
            onChange={(e: any) => {
              setUpdateWarehouseDetails({
                ...updateWarehouseDetails,
                stateCode: e.target.value,
              })
            }}
            isError={isErrorField.stateCode}
          />

          <Input
            labelValue='Postal Code'
            type='text'
            isRequired={true}
            className='w-1/2'
            value={updateWarehouseDetails?.postalCode}
            onChange={(e: any) => {
              setUpdateWarehouseDetails({
                ...updateWarehouseDetails,
                postalCode: e.target.value,
              })
            }}
            placeholder='138576'
            isError={isErrorField.postalCode}
          />
        </div>
        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>
            Country
            <span className='text-R-500 ml-[2px]'>*</span>
          </span>
          <SimpleSelect
            value={updateWarehouseDetails?.country}
            onChange={(e: any) => {
              setUpdateWarehouseDetails({
                ...updateWarehouseDetails,
                country: e,
              })
            }}
            options={countryList}
            isError={isErrorField.country}
          />
        </div>

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>Warehouse Labels</span>
          <MultiSelect
            value={selectedWarehouseLabels}
            onChange={(selectedItems: any) => {
              const selectedLabelIds = selectedItems.map(
                (item: any) => item.value,
              )
              setUpdateWarehouseDetails({
                ...updateWarehouseDetails,
                labelIds: selectedLabelIds,
              })
              setSelectedWarehouseLabels(selectedItems)
            }}
            options={labelList}
            isMulti={true}
            placeholder='Select label'
          />
        </div>

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>Status</span>
          <ToggleSwitch
            text={updateWarehouseDetails?.status ? 'Active' : 'Inactive'}
            setValue={updateWarehouseDetails?.status}
            onToggleClick={(e: any) => {
              setUpdateWarehouseDetails({
                ...updateWarehouseDetails,
                status: e.target.checked,
              })
            }}
          />
        </div>
      </div>

      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm gap-x-5'>
        <Button
          isLoading={updateWarehouseIsLoading}
          disabled={updateWarehouseIsLoading}
          isFullWidth
          onClick={() => {
            handleUpdateClick()
          }}>
          Update
        </Button>

        <Button isFullWidth appearance='dull' onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </Drawer>
  )
}

UpdateWarehouse.defaultProps = {
  className: undefined,
}

export default UpdateWarehouse
