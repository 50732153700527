/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react'
import CN from 'classnames'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  className,
  selectAll,
  setSelectAll,
  setSelectedRowIds,
  selectedRowIds,
  tableVariables,
  setTableVariables,
  totalRowCount,
  setIsLoading,
  setPageNumber,
  pageNumber,
  setUpdateCarrier,
  worderId,

  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = useMemo(() => columnsStructure, [])

  useEffect(() => {
    if (itemData?.length > 0) {
      setData([...itemData])
    }
  }, [itemData])

  return (
    <div className={CN('data-table w-full text-sm', className)} {...restProps}>
      <Table
        columns={columns}
        data={itemData}
        selectAll={selectAll}
        setFetchAll={setSelectAll}
        setSelectedRowIds={setSelectedRowIds}
        selectedRowIds={selectedRowIds}
        setTableVariables={setTableVariables}
        tableVariables={tableVariables}
        totalRowCount={totalRowCount}
        setIsLoading={setIsLoading}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        setUpdateCarrier={setUpdateCarrier}
      />
    </div>
  )
}

export default DataTable
