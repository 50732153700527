import { useMutation } from 'react-query'
import { get, postAPI } from 'framework/api/http'

interface GetPWBulkConsignmentHistoryInterface {
  take?: number
  skip?: number
  status?: any
  bulkReferance?: any
  filterVariables?: any
  splitReferance?: any
}

export const useGetPWBulkConsignmentHistory = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      status,
      filterVariables,
      bulkReferance,
      splitReferance,
    }: GetPWBulkConsignmentHistoryInterface) => {
      return postAPI(`/PWBulkConsignmentSummary`, {
        take,
        skip,
        status,
        bulkReferance,
        principleCode: filterVariables?.principle?.value,
        consNo: filterVariables?.consNo,
        consignedFrom: filterVariables?.consignedFrom,
        consignedTo: filterVariables?.consignedTo,
        splitReferance: splitReferance ?? '',
      })
    },
  )

  return mutate
}

export default useGetPWBulkConsignmentHistory
