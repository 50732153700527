import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import orderConsignmentVector from 'assets/images/order-consignment-vector.svg'
import CN from 'classnames'

import { DataTable as BulkConsignmentOrderDetaisTable } from 'components/pages/BulkOrderConsignmentNew/BulkConsignmentOrderDetails/Tables/BulkConsignmentOrdersDetailsTable'

export interface BulkConsignmentOrdersDetailsProps {
  [x: string]: any
}

const customizeClass = CN(
  'w-[350px] px-6 py-4 text-lg font-semibold text-white rounded-lg hover:bg-blue-700 flex items-start justify-start',
)

export const BulkConsignmentOrdersDetails: FC<
  BulkConsignmentOrdersDetailsProps
> = ({ className }: BulkConsignmentOrdersDetailsProps) => {
  return (
    <div
      className={CN(
        'relative flex items-center justify-start bg-N-50 h-full w-full pt-4 px-8 pb-4',
        className,
      )}>
      <div className='flex flex-col w-full h-auto bg-white rounded-lg px-4 py-2 overflow: visible'>
        {/* <BulkConsignmentOrderDetaisTable /> */}
      </div>
    </div>
  )
}

BulkConsignmentOrdersDetails.defaultProps = {}

export default BulkConsignmentOrdersDetails
