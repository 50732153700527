/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useGetInboundOrderCutoffTable,
  useGetPartnerTable,
} from 'framework/api/methods'

import { Button } from 'components/atoms'
import {
  AddInboundOrderCutOffTime,
  UpdateInboundOrderCutOffTime,
} from 'components/DrawerModals/InboundOrderCutOffTime'

import AdvanceSearchModel from './AdvanceSearchModel/AdvanceSearchModel'
import { DataTable } from './InboundOrderCutOffTimeDataTable'

import 'react-toastify/dist/ReactToastify.css'

export interface InboundOrderCutOffTimeProps {
  [x: string]: any
}
export const InboundOrderCutOffTime: FC<InboundOrderCutOffTimeProps> = ({
  className,
}: InboundOrderCutOffTimeProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)
  const inboundOrderCutOffEditAccess = userDetails?.userPermissions?.includes(
    'InboundOrderCutOffEditAccess',
  )
  const [searchWareHouseName, setSearchWareHouseName] = useState('')
  const [rows, setRows] = useState({})
  const [
    addInboundOrderCutOffTimeDrawerIsOpen,
    setAddInboundOrderCutOffTimeDrawerIsOpen,
  ] = useState(false)
  const [
    updateInboundOrderCutOffTimeDrawerIsOpen,
    setUpdateInboundOrderCutOffTimeDrawerIsOpen,
  ] = useState(false)
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    name: '',
  })
  const [searchKeyword, setSearchKeyword] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [selectedRowByButtonAction, setSelectedRowByButtonAction] = useState({
    id: '',
  })
  const [
    inboundOrderAdvancedSearchModalIsOpen,
    setInboundOrderAdvancedSearchModalIsOpen,
  ] = useState(false)
  const [filterVariables, setFilterVariables] = useState<any>({
    criteria: { label: 'All', value: '' },
    status: { label: 'All', value: '' },
  })
  const [partnerList, setPartnerList] = useState([])
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  /** API Call for get cut off time list using react-query */
  const {
    mutate: getInboundOrderCutOffTimeListMutate,
    isLoading: getInboundOrderCutOffTimeListIsLoading,
  } = useGetInboundOrderCutoffTable()
  /** Process the get cut off time table data */
  async function getInboundOrderCutOffTimeList() {
    getInboundOrderCutOffTimeListMutate(
      { ...tableVariables, ...filterVariables }, // Merge tableVariables and filterVariables into a single object
      {
        onSuccess: ({ data: successData }: any) => {
          setRows(successData?.inboundOrderCutOffTimes)
          setTotalRowCount(successData?.totalCount)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }
  /** API Call for get partner list function using react-query */
  const { mutate: getPartnerTableMutate, isLoading: getPartnerTableIsLoading } =
    useGetPartnerTable()
  /** Process to get partner List for integration */
  async function getPartnerList(): Promise<void> {
    getPartnerTableMutate(
      { take: 100, skip: 0, Search: '' },
      {
        onSuccess: ({ data: successData }: any) => {
          const result: any = []
          successData.partners.forEach((item: any) => {
            if (item.isActive) {
              result.push({ label: item.name, value: item?.code, id: item?.id })
            }
          })
          setPartnerList(result)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }
  /** call warehouses list when table variables change */
  useEffect(() => {
    getInboundOrderCutOffTimeList()
  }, [tableVariables, filterVariables])
  useEffect(() => {
    getPartnerList()
  }, [])
  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setTableVariables({
        ...tableVariables,
        name: searchWareHouseName,
      })
    }
  }
  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col h-full w-full rounded-lg bg-white shadow-md px-5 py-7'>
        <div className='flex flex-col w-full h-full bg-white'>
          <h2 className='text-lg font-semibold'>Inbound Order Cut-off Time</h2>
          <div className='flex w-full items-center justify-between pt-6'>
            {inboundOrderCutOffEditAccess && (
              <div className='w-2/3'>
                <Button
                  onClick={() => {
                    setAddInboundOrderCutOffTimeDrawerIsOpen(true)
                  }}>
                  <i className='ri-add-circle-line pr-2'></i>Add Cut-off Time
                </Button>
              </div>
            )}
            <div className='flex flex-row-[1px]'>
              {inboundOrderAdvancedSearchModalIsOpen && (
                <AdvanceSearchModel
                  setFilterVariables={setFilterVariables}
                  filterVariables={filterVariables}
                  isActive={true}
                  onCloseClick={() => {
                    setInboundOrderAdvancedSearchModalIsOpen(false)
                  }}
                  searchKeyword={searchKeyword}
                  setPageNumber={setPageNumber}
                  tableVariables={tableVariables}
                  getInboundorderCutOffList={getInboundOrderCutOffTimeList}
                />
              )}
              <button
                onClick={() => {
                  setInboundOrderAdvancedSearchModalIsOpen(true)
                }}
                className='flex items-center justify-center w-9 h-9 bg-white border border-N-200 rounded ml-2 relative'>
                <i className='ri-filter-2-line text-N-500'></i>
                {(filterVariables.criteria.label !== 'All' ||
                  filterVariables.criteria.status !== 'All') && (
                  <div className='w-[6px] h-[6px] bg-R-500 rounded-full absolute top-[-3px] right-[-3px]'></div>
                )}
              </button>
            </div>
            <div className='flex flex-col w-[400px] pl-0 relative'>
              <input
                onChange={(e: any) => {
                  setSearchWareHouseName(e.target.value)
                }}
                onKeyPress={(e: any) => {
                  handleSearchKeyPress(e)
                }}
                type='text'
                placeholder='Search by warehouse or partner'
                className='pr-4 pl-11 py-2 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm'
              />
              <i
                className='ri-search-2-line absolute top-0 bottom-0 flex items-center left-[22px] text-1xl text-N-500 cursor-pointer'
                onClick={() => {
                  setTableVariables({
                    ...tableVariables,
                    name: searchWareHouseName,
                  })
                }}></i>
            </div>
          </div>
          <div className='w-full h-[calc(100%-100px)] pt-4'>
            <DataTable
              itemData={rows}
              setTableVariables={setTableVariables}
              tableVariables={tableVariables}
              totalRowCount={totalRowCount}
              setUpdateInboundOrderCutOffTimeDrawerIsOpen={
                setUpdateInboundOrderCutOffTimeDrawerIsOpen
              }
              setSelectedRowByButtonAction={setSelectedRowByButtonAction}
              getInboundOrderCutOffTimeListIsLoading={
                getInboundOrderCutOffTimeListIsLoading
              }
            />
          </div>
          {addInboundOrderCutOffTimeDrawerIsOpen && (
            <AddInboundOrderCutOffTime
              isActive={addInboundOrderCutOffTimeDrawerIsOpen}
              onCloseClick={() =>
                setAddInboundOrderCutOffTimeDrawerIsOpen(false)
              }
              notify={notify}
              partnerList={partnerList}
              getInboundOrderCutOffTimeList={getInboundOrderCutOffTimeList}
            />
          )}
          {updateInboundOrderCutOffTimeDrawerIsOpen && (
            <UpdateInboundOrderCutOffTime
              isActive={updateInboundOrderCutOffTimeDrawerIsOpen}
              onCloseClick={() =>
                setUpdateInboundOrderCutOffTimeDrawerIsOpen(false)
              }
              selectedRowByButtonAction={selectedRowByButtonAction}
              notify={notify}
              partnerList={partnerList}
              getInboundOrderCutOffTimeList={getInboundOrderCutOffTimeList}
            />
          )}
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
        </div>
      </div>
    </div>
  )
}
InboundOrderCutOffTime.defaultProps = {}
export default InboundOrderCutOffTime
