/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useReducer, useState } from 'react'
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'
import { CheckBox } from 'components/atoms/Checkbox'
import { QuantityPicker } from 'components/atoms/QuantityPicker'

const Table = ({
  columns,
  data,
  selectAll,
  setFetchAll,
  setSelectedRowIds,
  selectedRowIds,
  setTableVariables,
  tableVariables,
  totalRowCount,
  setUpdateCarrier,
  setIsLoading,
  pageNumber,
  setPageNumber,
}) => {
  const [currentRow, setCurrentRow] = useState(null) // ✅ Correct use of state
  const [checkAll, setCheckAll] = useState(false)
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    toggleRowSelected,
    toggleAllRowsSelected,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        pageIndex: 0,
        selectedRowIds: selectedRowIds || [],
      },
      manualPagination: true,
      manualSortBy: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableSortBy: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((cols) => [
        {
          id: 'selection',
          customWidth: 32,
          Header: ({
            getToggleAllPageRowsSelectedProps,
            toggleAllRowsSelected,
          }) => {
            const { checked, onChange, ...rest } =
              getToggleAllPageRowsSelectedProps()

            return (
              <CheckBox
                {...rest}
                checked={checked}
                onChange={(e) => {
                  setIsLoading(true)
                  const isChecked = e.target.checked
                  console.log('isChecked', isChecked)
                  setFetchAll(isChecked) //  Update fetchAll state
                  setCheckAll(isChecked) // Track checkAll state
                  handleCheckAll(isChecked) // Use improved logic
                  setIsLoading(false)
                }}
              />
            )
          },
          Cell: ({ row }) => {
            const { checked, onChange, ...rest } =
              row.getToggleRowSelectedProps() //Extract props properly

            return (
              <CheckBox
                {...rest} //Spread the remaining props
                checked={checked}
                //Ensures proper selection check
                onChange={() => handleRowSelection(row, checked)} //Calls selection handler
              />
            )
          },
        },
        ...cols,
      ])
    },
  )
  const handleRowSelection = (row) => {
    if (!row || !row.original || typeof row.original.id === 'undefined') return //  Prevents errors
    setCurrentRow(row)
    setSelectedRowIds((prevSelected) => {
      const isSelected = prevSelected.some(
        (selected) => selected.id === row.original.id,
      )

      if (isSelected) {
        row.toggleRowSelected(false) // Immediately uncheck
        return prevSelected.filter(
          (selected) => selected.id !== row.original.id,
        ) // Corrected filter logic
      } else {
        row.toggleRowSelected(true) //Immediately check
        return [...prevSelected, row.original] // Add row to selected list
      }
    })
    forceUpdate()
  }
  useEffect(() => {
    console.log('selectedRowIds', selectedRowIds)
    if (selectedRowIds.length > 0 && page.length > 0) {
      console.log('page', page)
      console.log('pageNumber', pageNumber)
      page.forEach((row) => {
        const isSelected = selectedRowIds.some(
          (selected) => String(selected.id) === String(row.original.id),
        )
        if (pageNumber == 3) {
          console.log('SelctedRow', row)
          console.log('isSelected', row)
        }
        row.toggleRowSelected(isSelected)
        /*setTimeout(() => {
        
        }, 50) */ // Select row if it's in selectedRowIds
      })
    } else {
      toggleAllRowsSelected(false)
    }
  }, [page, selectedRowIds]) // Runs when page or selection changes

  const handleCheckAll = (isChecked) => {
    if (isChecked) {
      toggleAllRowsSelected(true) // Select all rows
    } else {
      toggleAllRowsSelected(false) // Deselect all rows
      setSelectedRowIds([]) //Clear stored selected row IDs
    }

    console.log(
      `handleCheckAll called: ${isChecked ? 'Selected All' : 'Deselected All'}`,
    )
  }

  /** Pagination States */
  const [paginationVariables, setPaginationVariables] = useState({
    start: 0,
    end: 0,
  })

  /** Pagination Functions */
  useEffect(() => {
    console.log('PageNumber', pageNumber)
    setPaginationVariables({
      ...paginationVariables,
      start:
        Number(tableVariables.take) * Number(pageNumber) -
        Number(tableVariables.take) +
        1,
      end:
        Number(tableVariables.take) * Number(pageNumber) >= totalRowCount
          ? totalRowCount
          : Number(tableVariables.take) * Number(pageNumber),
    })
  }, [tableVariables, totalRowCount, pageNumber])

  /** Pagination Functions */
  useEffect(() => {
    setTableVariables((prev) => ({
      ...prev,
      skip: pageNumber === 1 ? 0 : Number(prev.take) * (Number(pageNumber) - 1),
    }))
  }, [pageNumber])

  const slicedPageData = page

  return (
    <>
      <div className='styled-scroll h-full relative overflow-y-auto rounded border  bg-white border-N-200'>
        <table className='w-full overflow-y-auto' {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={CN(
                      'bg-N-100 border-b border-b-N-200 sticky top-0 shadow-sm text-left pl-4 z-[1]',
                      {
                        'bg-N-200': column.isSorted,
                      },
                    )}
                    style={{ height: 40 }}
                    width={column.customWidth}>
                    <div
                      className='inline-flex items-center'
                      style={{ minHeight: 20 }}>
                      {column.render('Header')}
                      <span className='flex ml-1' style={{ width: 14 }}>
                        {column.isSorted &&
                          (column.isSortedDesc ? (
                            <i className='ri-arrow-up-s-line' />
                          ) : (
                            <i className='ri-arrow-down-s-line' />
                          ))}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {slicedPageData.map((row) => {
              prepareRow(row)
              return (
                <tr className={CN('h-11  cursor-pointer', {})}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={CN(
                        'border-b border-b-N-200 text-sm text-N-700 py-[11px] px-4',
                        {
                          'bg-N-300 border-b border-b-N-400': cell.isSelected,
                        },
                      )}>
                      {cell.column.isAction ? (
                        <div
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          className='flex items-center w-fit'></div>
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>

        <div className='flex items-center justify-between px-3 py-[10px] border border-N-200 rounded-b-md overflow-auto'>
          {!selectAll && (
            <div className='flex items-center'>
              <span className='text-sm text-N-700'>Show rows per page</span>
              <select
                className='w-20 ml-2 border rounded-md outline-none border-N-200 h-7'
                value={tableVariables.take}
                onChange={(e) => {
                  //setPageNumber(1)
                  setTableVariables({ ...tableVariables, take: e.target.value })
                }}>
                {[10, 20, 30, 50].map((pageSizeValue) => (
                  <option key={pageSizeValue} value={pageSizeValue}>
                    {pageSizeValue}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className='flex items-center justify-between px-3 py-[10px] border border-N-200 rounded-b-md overflow-auto'>
            <div className='flex items-center'>
              <span className='text-sm text-N-700'>
                Selected Row Count- {selectedRowIds.length}
              </span>
            </div>
          </div>

          {!selectAll && (
            <div className='flex'>
              <div className='flex items-center text-sm text-N-700 mr-9'>
                <p>
                  {paginationVariables.start} - {paginationVariables.end} of{' '}
                  {totalRowCount}
                </p>
              </div>
              <Button
                className='mr-8'
                appearance='dull'
                size='xs'
                onClick={() => {
                  setPageNumber(pageNumber - 1)
                  console.log('PrevpageNumber', pageNumber)
                }}
                disabled={pageNumber <= 1}>
                <i className='ri-arrow-left-s-line' />
              </Button>
              <Button
                className='mr-2'
                appearance='dull'
                size='xs'
                onClick={() => {
                  setPageNumber(pageNumber + 1)
                  console.log('NextpageNumber', pageNumber)
                }}
                disabled={
                  Number(pageNumber) * Number(tableVariables.take) >
                  Number(totalRowCount)
                }>
                <i className='ri-arrow-right-s-line' />
              </Button>
            </div>
          )}
          {selectAll && (
            <div className='flex'>
              <div className='flex items-center text-sm text-N-700 mr-9'>
                <p>
                  {paginationVariables.start} - {totalRowCount} of{' '}
                  {totalRowCount}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

Table.propTypes = {}

export default Table
