/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import {
  useGetMachShipCarriers,
  useGetStarshipITCarriers,
  useManageCarriers,
} from 'framework/api/methods'

import { DatePicker, SimpleSelect, ToggleSwitch } from 'components/atoms'
import { Button } from 'components/atoms/Button'

export interface UpdateCourrierModalProps {
  className?: string | undefined
  isActive?: boolean | undefined
  isCustomStyles?: boolean | undefined
  notify?: any
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  selectedRowIds?: any
  useGetCarrierProducts?: any
  setIsLoading?: any
  setIsUpdate?: any
}

export const UpdateCourrierModal: FC<UpdateCourrierModalProps> = ({
  className,
  isActive,
  isCustomStyles,
  notify,
  onCloseClick,
  onOverlayClick,
  selectedRowIds,
  useGetCarrierProducts,
  setIsLoading,
  setIsUpdate,
}: UpdateCourrierModalProps) => {
  const [selectedCourierType, setSelectedCourierType] = useState<any>(null)
  const [selectedServiceType, setSelectedServiceType] = useState<any>(null)

  const [selectedStarShipItCourierType, setSelectedStarShipItCourierType] =
    useState<any>(null)

  const [selectedMachShipCourierType, setSelectedMachShipCourierType] =
    useState<any>(null)

  /** dse extra fields */
  const [showDSEExtraFields, setShowDSEExtraFields] = useState(false)
  const [pickUpDate, setPickUpDate] = useState<any>(null)
  const [cutoffTime, setCutoffTime] = useState<any>()
  const [personToLift, setPersonToLift] = useState<number>(0)

  /** Service Type Arrays */
  const [serviceTypes, setServiceTypes] = useState([])
  const [ausPostTypes, setAusPostTypes] = useState([])
  const [carrierProducts, setCarrierProducts] = useState<any>(null)
  const [ausPostInternationalTypes, setAusPostInternationalTypes] = useState([])
  const [directExpressTypes, setDirectExpressTypes] = useState([])
  const [dseTypes, setDseTypes] = useState([])
  const [starshipitTypes, setStarshipitTypes] = useState([])
  const [machShipTypes, setMachShipTypes] = useState([])

  const [starshipitCourierList, setStarshipitCourierList] = useState<any>([])
  const [macShipCourierList, setMacShipCourierList] = useState<any>([])

  const [courrierDetails, setCourrierDetails] = useState<any>({
    carrierProvider: '',
    serviceCarrier: '',
    serviceType: '',
  })

  const { shippingSummeryWarehouseCode }: any = useSelector<any>(
    (state) => state.order,
  )

  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false)
  const [alliedExpressTypes, setAlliedExpressTypes] = useState([])

  const [CourierTypes, setCourrierType] = useState([
    {
      value: 'AUSPOST',
      label: 'Australia Post',
    },
    {
      value: 'AUSPOST_INTERNATIONAL',
      label: 'Australia Post International',
    },
    // Uncomment others as needed
    /*
    {
      value: 'ALLIED',
      label: 'AlliedExpress',
    },
    {
      value: 'DIRECT',
      label: 'Direct Express',
    },
    {
      value: 'DSE',
      label: 'DSE',
    },
    {
      value: 'MACHSHIP',
      label: 'MachShip',
    },
    {
      value: 'STARSHIPIT',
      label: 'Starshipit',
    },
    */
  ])

  /** Function On-Success Get Currier Products */
  const getCarrierProductsIsOnSuccess = (data: any) => {
    setCarrierProducts(data)
    /** Manipulating Auspost Data Array */
    const ausPost = data.auspost.map((item: any) => {
      return {
        label: `${item.productName} - ${item.productCode}`,
        value: item.productCode,
      }
    })
    setAusPostTypes(ausPost)

    /** Manipulating Auspost International Data Array */
    const ausPostInternational = data?.auspostinternational?.map(
      (item: any) => {
        return {
          label: `${item.productName} - ${item.productCode}`,
          value: item.productCode,
        }
      },
    )
    setAusPostInternationalTypes(ausPostInternational)

    /** Manipulating Direct Express Data Array */
    const directExpress = data.direct.map((item: any) => {
      return {
        label: item.productName,
        value: item.productCode,
      }
    })
    setDirectExpressTypes(directExpress)

    /** Manipulating DSE Data Array */
    const dse = data?.dse.map((item: any) => {
      return {
        label: `${item.productName} - ${item.productCode}`,
        value: item.productCode,
      }
    })
    setDseTypes(dse)

    /** Manipulating Starshipit Data Array */
    const starshipit = data?.starshipit.map((item: any) => {
      return {
        label: `${item.productName} - ${item.productCode}`,
        value: item.productCode,
        carrierCode: item.carrierCode,
      }
    })
    setStarshipitTypes(starshipit)

    /** Manipulating MachShip Data Array */
    const machship = data?.machship.map((item: any) => {
      return {
        label: `${item.productName} - ${item.productCode}`,
        value: item.id,
        carrierCode: item.productName,
      }
    })
    setMachShipTypes(machship)

    const alliedExpress = data.alliedExpress.map((item: any) => {
      return {
        label: `${item.productName} - ${item.productCode}`,
        value: item.productCode,
      }
    })
    setAlliedExpressTypes(alliedExpress)
  }

  /** Function On-Error Get Currier Products*/
  const getCarrierProductsIsOnError = (error: any) => {
    notify(error.message || 'Error...!', 'error')
  }

  /** API Call for Get Currier Products */
  const {
    isLoading: GetCarrierProductsIsLoading,
    refetch: callGetCarrierProducts,
  } = useGetCarrierProducts(
    {},
    getCarrierProductsIsOnSuccess,
    getCarrierProductsIsOnError,
  )

  useEffect(() => {
    callGetCarrierProducts()
  }, [])

  useEffect(() => {
    if (selectedRowIds[0].partnerOrder.shippingAddress?.countryCode == 'AU') {
      setCourrierType([
        {
          value: 'AUSPOST',
          label: 'Australia Post',
        },
      ])
    } else {
      setCourrierType([
        {
          value: 'AUSPOST_INTERNATIONAL',
          label: 'Australia Post International',
        },
      ])
    }
  }, [selectedRowIds])

  /** Handling Service type according to selected courier service */
  const onChangeCourierType = (e: any) => {
    setSelectedCourierType(e)
    setShowDSEExtraFields(false)
    const courier = e.value
    setSelectedServiceType(null)
    setSelectedStarShipItCourierType(null)
    setCourrierDetails({ ...courrierDetails, carrierProvider: e.value })

    switch (courier) {
      case 'AUSPOST':
        setServiceTypes(ausPostTypes)
        break
      case 'AUSPOST_INTERNATIONAL':
        setServiceTypes(ausPostInternationalTypes)
        break
      case 'DIRECT':
        setServiceTypes(directExpressTypes)
        break
      case 'DSE':
        setServiceTypes(dseTypes)
        setShowDSEExtraFields(true)
        break
      case 'STARSHIPIT':
        setServiceTypes([])
        getStarshipITCarriers()
        break
      case 'MACHSHIP':
        setServiceTypes([])
        getMachShipCarriers()
        break
      case 'ALLIED':
        setServiceTypes(alliedExpressTypes)
        break
      default:
        setServiceTypes([])
        break
    }
  }

  /** Handling Service type according to selected courier service in starshipit */
  const onChangeStarShipItCourierType = (e: any) => {
    setSelectedServiceType(null)
    setSelectedStarShipItCourierType(e)
    const courier = e.value

    const starShipItServices = starshipitTypes?.filter(
      (item: any) => item.carrierCode === courier,
    )

    setServiceTypes(starShipItServices)
    setCourrierDetails({ ...courrierDetails, serviceCarrier: e.value })
  }

  /** Handling Service type according to selected courier service in mach ship */
  const onChangeMachShipCourierType = (e: any) => {
    setSelectedServiceType(null)
    setSelectedMachShipCourierType(e)
    const courier = e.label

    const machShipServices = machShipTypes?.filter(
      (item: any) => item.carrierCode === courier,
    )

    setServiceTypes(machShipServices)
    setCourrierDetails({ ...courrierDetails, serviceCarrier: e.value })
  }

  /** Handling Service types*/
  const onChangeServiceType = (e: any) => {
    setSelectedServiceType(e)
    setCourrierDetails({ ...courrierDetails, serviceType: e.value })
  }

  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const { mutate: manageCourriersMutate, isLoading: loadingCreatePackage } =
    useManageCarriers()

  /** Process the Create Shipment Package */
  async function manageCourriers() {
    manageCourriersMutate(
      {
        warehouseOrderIds: selectedRowIds,
        carrierDetail: courrierDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          setPickUpDate(true)
          if (onCloseClick) {
            onCloseClick()
          }
          setIsUpdate(true)
          // callOrderShipmentsById()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  const handleConfirmClick = (event: any) => {
    if (!courrierDetails?.carrierProvider || !courrierDetails?.serviceType) {
      notify('Carrier Provider and Service Type are required.')
      return
    }

    if (
      (selectedCourierType?.value === 'STARSHIPIT' ||
        selectedCourierType?.value === 'MACHSHIP') &&
      !courrierDetails?.serviceCarrier
    ) {
      notify('Service Carrier is required.')
      return
    }

    //setIsLoading(true)
    event.stopPropagation()
    setIsLoading(true)
    manageCourriers()
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-[10] top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  const getStarshipITCarriersOnSuccess = (data: any) => {
    setStarshipitCourierList(data?.starshipitCarrierCodes || [])
  }

  /** Function On-Error Get  Curriers of starshipit */
  const getStarshipITCarriersOnError = (error: any) => {
    notify(error.message || 'Error...!', 'error')
  }

  /** API Call for Get Curriers of starshipit */
  const { refetch: getStarshipITCarriers } = useGetStarshipITCarriers(
    {},
    getStarshipITCarriersOnSuccess,
    getStarshipITCarriersOnError,
  )

  const getMachShipCarriersOnSuccess = (data: any) => {
    setMacShipCourierList(data?.machShipCarrierCodes || [])
  }

  /** Function On-Error Get  Curriers of starshipit */
  const getMachShipCarriersOnError = (error: any) => {
    notify(error.message || 'Error...!', 'error')
  }

  /** API Call for Get Curriers of machship */
  const { refetch: getMachShipCarriers } = useGetMachShipCarriers(
    {},
    getMachShipCarriersOnSuccess,
    getMachShipCarriersOnError,
  )

  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-[502px] bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}
        <div className='flex items-center justify-between w-full px-4 py-3'>
          <h2 className='text-lg font-bold'>Bulk Carrier Update</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>

        {/* Modal Body  */}
        <div className='flex flex-col w-full pt-6 pl-4 pr-[18px]'>
          <div className='select-courier-drop-down flex flex-col pb-8'>
            <div className='flex'>
              <span className='text-xs font-medium text-N-700 pb-2'>
                Select Carrier
              </span>
              <span className='text-R-500 ml-[2px] leading-[16px]'>*</span>
            </div>

            <SimpleSelect
              onChange={onChangeCourierType}
              options={CourierTypes}
              value={selectedCourierType}
            />
          </div>

          {selectedCourierType?.value === 'STARSHIPIT' && (
            <div className='select-courier-drop-down flex flex-col pb-8'>
              <div className='flex'>
                <span className='text-xs font-medium text-N-700 pb-2'>
                  Select Starshipit Carrier
                </span>
                <span className='text-R-500 ml-[2px] leading-[16px]'>*</span>
              </div>

              <SimpleSelect
                onChange={onChangeStarShipItCourierType}
                options={starshipitCourierList}
                value={selectedStarShipItCourierType}
              />
            </div>
          )}

          {selectedCourierType?.value === 'MACHSHIP' && (
            <div className='select-courier-drop-down flex flex-col pb-8'>
              <div className='flex'>
                <span className='text-xs font-medium text-N-700 pb-2'>
                  Select MachShip Carrier
                </span>
                <span className='text-R-500 ml-[2px] leading-[16px]'>*</span>
              </div>

              <SimpleSelect
                onChange={onChangeMachShipCourierType}
                options={macShipCourierList}
                value={selectedMachShipCourierType}
              />
            </div>
          )}

          <div className='select-service-type-drop-down flex flex-col pb-8'>
            <div className='flex'>
              <span className='text-xs font-medium text-N-700 pb-2'>
                Select Service Type
              </span>
              <span className='text-R-500 ml-[2px] leading-[16px]'>*</span>
            </div>

            <SimpleSelect
              onChange={onChangeServiceType}
              options={serviceTypes}
              value={selectedServiceType}
            />
          </div>
        </div>

        {/* Modal Footer  */}
        <div className='w-full border-t border-t-N-200 mt-9 px-5 py-4 flex items-center justify-between'>
          <Button
            onClick={(e: any) => handleConfirmClick(e)}
            //disabled={isConfirmButtonDisabled}
            isLoading={false}
            className='bg-orange-500 text-white px-6 py-2 rounded-md hover:bg-orange-600 transition'>
            Save
          </Button>

          <Button
            className='bg-N-200 px-6 py-2 rounded-md hover:bg-N-300 transition'
            appearance='dull'
            onClick={onCloseClick}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

UpdateCourrierModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
}

export default UpdateCourrierModal
