import { FC } from 'react'
import CN from 'classnames'

export interface OrderStatusLabelProps {
  className?: string | undefined
  labelValue?: string | undefined
  appearance?:
    | 'Received'
    | 'Closed'
    | 'Confirmed'
    | 'Failed'
    | 'Errored'
    | 'Cancelled'
    | 'Partial-consigned'
    | 'Backorder'
    | 'Open'
    | 'Tobeconsigned'
    | 'Exception'
    | 'Stock received'
    | 'Stock part-received'
    | 'Entered'
    | 'Onhold'
    | undefined
}

export const OrderStatusLabel: FC<OrderStatusLabelProps> = ({
  className,
  labelValue,
  appearance,
}: OrderStatusLabelProps) => {
  return (
    <div
      className={CN(
        'rounded-sm py-[1px] px-1 w-fit',
        {
          'bg-R-100': appearance === 'Failed' || appearance === 'Errored',
          'bg-P-100': appearance === 'Received',
          'bg-[#64748B] bg-opacity-[15%]': appearance === 'Closed',
          'bg-G-100':
            appearance === 'Stock received' || appearance === 'Confirmed',
          'bg-G-200': appearance === 'Stock part-received',
          'bg-[#D9D9D9]': appearance === 'Cancelled',
          'bg-[#0066FF] bg-opacity-[15%]':
            appearance === 'Entered' || appearance === 'Backorder',
          'bg-P-50': appearance === 'Partial-consigned',

          'bg-[#CF46FF] bg-opacity-[15%]': appearance === 'Open',
          'bg-[#14AFF1] bg-opacity-[15%]': appearance === 'Tobeconsigned',
          'bg-[#E5A80F] bg-opacity-[20%]': appearance === 'Exception',
          'bg-N-200': appearance === 'Onhold',
        },
        className,
      )}>
      <p
        className={CN('font-bold text-xs', {
          'text-R-500': appearance === 'Failed' || appearance === 'Errored',
          'text-P-700': appearance === 'Received',
          'text-[#64748B] ': appearance === 'Closed',
          'text-G-700':
            appearance === 'Confirmed' || appearance === 'Stock received',

          'text-G-900': appearance === 'Stock part-received',
          'text-[#0066FF]':
            appearance === 'Backorder' || appearance === 'Entered',
          'text-N-700': appearance === 'Cancelled',
          'text-P-600': appearance === 'Partial-consigned',
          'text-[#CF46FF]': appearance === 'Open',
          'text-[#14AFF1]': appearance === 'Tobeconsigned',
          'text-[#E5A80F]': appearance === 'Exception',
          'text-S-900': appearance === 'Onhold',
        })}>
        {labelValue === 'Backorder'
          ? 'Back Order'
          : labelValue === 'Tobeconsigned'
          ? 'To be Consigned'
          : labelValue === 'Onhold'
          ? 'On Hold'
          : labelValue}
      </p>
    </div>
  )
}

OrderStatusLabel.defaultProps = {
  className: undefined,
  labelValue: undefined,
  appearance: undefined,
}

export default OrderStatusLabel
