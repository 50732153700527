/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetPartnerOrderOnholdReasonListInterface {
  id: number
}
export const useGetPartnerOrderOnholdReasonList = () => {
  const mutate = useMutation(
    ({ id }: GetPartnerOrderOnholdReasonListInterface) => {
      return get(`/OnHoldReasons`)
    },
  )
  return mutate
}
export default useGetPartnerOrderOnholdReasonList
