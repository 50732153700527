import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface ManageCarriersInterface {
  warehouseOrderIds: any
  carrierDetail: {
    carrierProvider?: string
    serviceCarrier?: string
    serviceType?: string
  }
}

export const useManageCarriers = () => {
  const mutate = useMutation(
    ({ warehouseOrderIds, carrierDetail }: ManageCarriersInterface) => {
      return postAPI(`PWBulkConsignment/ManageCarriers`, {
        warehouseOrderIds: warehouseOrderIds.map(
          (order: { id: any }) => order.id,
        ),
        carrierDetail: {
          carrierProvider: carrierDetail?.carrierProvider,
          serviceCarrier: carrierDetail?.serviceCarrier,
          serviceType: carrierDetail?.serviceType,
        },
      })
    },
  )

  return mutate
}

export default useManageCarriers
