/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { FC, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useConfirmOrderList,
  useCreateShipmentPackage,
  useGetCarrierProducts,
  useGetDistinctCourrierList,
  useGetPartnerOrderTypes,
  useGetPWBulkOrderList,
  useGetWarehouseById,
} from 'framework/api/methods'
import useGetPartnerListByIntegration from 'framework/api/methods/partner/useGetPartnerListByIntegration'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

import { Button, Input, JsonLoader, SimpleSelect } from 'components/atoms'
import { MultiSelect } from 'components/atoms/MultiSelect'
import EditShippingAddressModal from 'components/DrawerModals/BulkConsignmentNew/EditShippingAddressModal'
import UpdateCourrierModal from 'components/DrawerModals/BulkConsignmentNew/UpdateCourrierModal'
import UpdatePackageModal from 'components/DrawerModals/BulkConsignmentNew/UpdatePackageModal'
import { DataTable as BulkOrderListDataTable } from 'components/pages/BulkOrderConsignmentNew/PWBulkConsignment/Tables/BulkOrderListDataTable'

export interface PWBulkOrderConsignmentProps {
  [x: string]: any
}

export const PWBulkOrderConsignment: FC<PWBulkOrderConsignmentProps> = ({
  className,
  isActive,
  onCloseClick,
  notify,
  setHistoryIsOpen,
  setSummaryData,
  setSummaryIsOpen,
  setPWBulkConsignmentIsOpen,
  seBulkConsignmentMenuIsOpen,
  FromHistory,
  setWarehouseDetails,
  setFromHistory,
  userDetails,
}: PWBulkOrderConsignmentProps) => {
  const [mainLoadingState, setIsLoading] = useState(false)

  const [isOrderListScreen, setIsOrderListScreen] = useState(true)
  const [warehouseId, setWarehouseId] = useState<any>()
  const consStatusOptions = [
    { value: 'Yes', label: 'With Cons No' },
    { value: 'No', label: 'Without Cons No' },
  ]

  const weightStatusOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ]

  const dimensionsStatusOption = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ]

  //set data for dropdowns
  const [carrierList, setCarrierList] = useState<any>([])
  const [partnerList, setPartnerList] = useState([])
  const [partnerOrderTypeList, setOrderTypeList] = useState<any>([])

  const [selectedOrderTypes, setselectedOrderTypes] = useState([
    { label: 'All', value: '' },
  ])

  const [shipmentPackageDetails, setShipmentPackageDetails] = useState()

  const [bulkOrderList, setBulkOrderList] = useState<any>([])
  const [selectAll, setSelectAll] = useState(false)
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [isFilterMenuOpen, setIsFilterModalOpen] = useState(true)
  const [isUpdatePackageModalOpen, setIsUpdatePackageModalOpen] =
    useState(false)
  const [isUpdateCourrierModalOpen, setIsUpdateCourrierModalOpen] =
    useState(false)

  const [serchByWarehouseOrderNo, setSerchByWarehouseOrderNo] = useState('')
  const [isResetFilter, setIsResetFilter] = useState(false)
  const [worderId, setWOrderId] = useState<any>()

  const [filterVariables, setFilterVariables] = useState<any>({
    principle: { label: 'Signal Brands', value: '42167' },
    consStatus: { label: 'With Cons No', value: 'Yes' },
    orderTypes: selectedOrderTypes,
    consNo: '',
    rddFrom: '',
    rddTo: '',
    weight: { label: 'All', value: '' },
    dimensions: { label: 'All', value: '' },
    courrierType: { label: 'All', value: '' },
  })
  const [filterActive, setFilterActive] = useState(false)

  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    search: '',
  })

  interface OrderType {
    value: string
    label: string
    disabled: boolean
  }

  const [pageNumber, setPageNumber] = useState(1)
  const [isUpdate, setIsUpdate] = useState(false)

  const validConfirm = () => {
    let notified = false

    const notifyOnce = (message: any) => {
      if (!notified) {
        notify(message, 'error')
        notified = true
      }
    }
    // Condition 1: No Orders Selected
    if (selectedRowIds.length === 0) {
      notifyOnce('Please select at least one order to proceed.')
      return {
        valid: false,
      }
    }
    //Condition 2: Orders with Mixed Cons Numbers
    const consNumbers = new Set(
      selectedRowIds.map((order: any) => order.consNo).filter(Boolean),
    )
    if (consNumbers.size > 1) {
      notifyOnce('Please select orders with either one or no cons number.')
      return {
        valid: false,
      }
    }

    //Condition 3: Orders Missing Weight, Dimensions, or Carrier
    const missingDetails = selectedRowIds.some(
      (order: any) =>
        !order?.shipments[0]?.packages[0]?.weight ||
        !order?.shipments[0]?.packages[0]?.width ||
        !order?.shipments[0]?.packages[0]?.height ||
        !order?.shipments[0]?.packages[0]?.length ||
        !order?.partnerOrder?.defaultCarrierName ||
        !order?.partnerOrder?.carrierProduct,
    )
    if (missingDetails) {
      notifyOnce(
        'Please select orders with weight, dimensions, and carrier assigned.',
      )
      return {
        valid: false,
      }
    }

    //Condition 4: Orders with Mixed Carriers
    const carriers = new Set(
      selectedRowIds
        .map((order: any) => order.partnerOrder?.defaultCarrierName)
        .filter(Boolean),
    )
    /* if (carriers.size > 1) {
      notify('Please select orders of one carrier.', 'error')
      return { valid: false }
    }*/

    // Condition 5: Successful Validation
    return { valid: true }
  }

  const {
    mutate: getWarehouseByIdMutate,
    isLoading: getWarehouseByIdIsLoading,
  } = useGetWarehouseById()

  /** Process the get warehouse Table Data */
  async function getWarehouseById(id: any): Promise<void> {
    getWarehouseByIdMutate(
      { id },
      {
        onSuccess: ({ data: successData }: any) => {
          setWarehouseDetails(successData.warehouse)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
          onCloseClick()
        },
      },
    )
  }

  /** API Call for Get Similar Order List */
  const {
    mutate: getPWBulkOrderListMutate,
    isLoading: getPWBulkOrderListIsLoading,
  } = useGetPWBulkOrderList()

  /** Process the Get Similar Order List */
  async function getPWBulkOrderList() {
    setIsLoading(true)
    getPWBulkOrderListMutate(
      {
        take: tableVariables.take,
        skip: tableVariables.skip,
        search: tableVariables.search,
        filterVariables,
        isFetchAll: selectAll,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setBulkOrderList(successData?.warehouseOrders)
          setWarehouseId(successData?.warehouseOrders[0]?.warehouseId)
          setTotalRowCount(successData?.totalCount)
          if (selectAll) {
            setSelectedRowIds(successData?.warehouseOrders)
          }
          setIsLoading(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
          setIsLoading(false)
        },
      },
    )
  }

  /** API Call for Get Courrier List */
  const { mutate: getCourrierListMutate, isLoading: getCourrierListIsLoading } =
    useGetDistinctCourrierList()

  /** Process the Get Courrier List */
  async function getCourrierList() {
    setIsLoading(true)
    const allowedCouriers = ['AUSPOST', 'AUSPOST_INTERNATIONAL']
    getCourrierListMutate(undefined, {
      onSuccess: ({ data: successData }: any) => {
        const allOption: OrderType = {
          value: '',
          label: 'All',
          disabled: false,
        }
        const noCarrierOption: OrderType = {
            value: 'No',
            label: 'No carrier',
            disabled: false,
          },
          formattedData = successData.carriers
            .filter((item: any) => allowedCouriers.includes(item.value))
            .map((item: { label: string; value: string }) => ({
              ...item,
              disabled: true, // Initialize disabled as false
            }))
        const updatedCarrierList: OrderType[] = [
          allOption,
          noCarrierOption,
          ...formattedData,
        ]
        setCarrierList(updatedCarrierList)
        setIsLoading(false)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...!', 'error')
        setIsLoading(false)
      },
    })
  }

  /** API Call for get partner list function using react-query */
  const {
    mutate: GetPartnerOrderListByIntegrationMutate,
    isLoading: getPartnerTableIsLoading,
  } = useGetPartnerListByIntegration()
  /** Process to get partner List for integration */
  async function getPartnerListByIntegration(): Promise<void> {
    setIsLoading(true)
    GetPartnerOrderListByIntegrationMutate(
      { warehuseIntegration: 'PaperlessWMS' },
      {
        onSuccess: ({ data: successData }: any) => {
          const result: any = []
          successData.partners.forEach((item: any) => {
            if (item.isActive) {
              result.push({ label: item.name, value: item?.code, id: item?.id })
            }
          })
          setPartnerList(result)
          setIsLoading(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
          setIsLoading(false)
        },
      },
    )
  }

  /** API Call For Partner order type List (Advanced Search Modal)  */
  const { mutate: getPartnerOrderTypeListMutate } = useGetPartnerOrderTypes()

  /** Process the get Partner order Type List */
  async function getPartnerOrderTypeList() {
    setIsLoading(true)
    getPartnerOrderTypeListMutate(filterVariables, {
      onSuccess: ({ data }: any) => {
        const allOption: OrderType = {
            value: '',
            label: 'All',
            disabled: false,
          },
          formattedData = data.orderTypeList.map(
            (item: { label: string; value: string }) => ({
              ...item,
              disabled: true, // Initialize disabled as false
            }),
          )
        const updatedOrderTypeList: OrderType[] = [allOption, ...formattedData]
        setOrderTypeList(updatedOrderTypeList)
        setIsLoading(false)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
        setIsLoading(false)
      },
    })
  }

  const { mutate: createConfirmOrderListMutate } = useConfirmOrderList()

  /** Process the get Partner order Type List */
  async function createConfirmOrderList() {
    setIsLoading(true)
    createConfirmOrderListMutate(
      {
        warehouseOrderIds: selectedRowIds,
        principle: filterVariables.principle.label,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          seBulkConsignmentMenuIsOpen(false)
          setHistoryIsOpen(false)
          //setWarehoueDetails(successData.WarehouseDetailsList)
          setSummaryData(successData.pwBulkConsignmentSummaryList)
          setTotalRowCount(successData.totalCount)
          setSummaryIsOpen(true)
          if (onCloseClick) {
            onCloseClick()
          }
          setIsLoading(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
          setIsLoading(false)
        },
      },
    )
  }

  /** Apply Filter */

  useEffect(() => {
    setIsLoading(true)
    getPWBulkOrderList()
    getPartnerOrderTypeList()
    getPartnerListByIntegration()
    getCourrierList()
    setIsLoading(false)
  }, [tableVariables.skip, tableVariables.take, tableVariables.search])

  useEffect(() => {
    getPWBulkOrderList()
  }, [selectAll])

  useEffect(() => {
    if (isUpdate) {
      getPWBulkOrderList()
      setSelectedRowIds([])
      setIsUpdate(false)
      setFilterVariables({
        principle: { label: 'Signal Brands', value: '42167' },
        consStatus: { label: 'With Cons No', value: 'Yes' },
        orderType: { label: 'All', value: '' },
        consNo: '',
        rddFrom: '',
        rddTo: '',
        weight: '',
        dueDateTo: '',
      })
    }
  }, [isUpdate])

  useEffect(() => {
    if (isResetFilter) {
      getPWBulkOrderList()
      setIsResetFilter(false)
    }
  }, [isResetFilter])

  useEffect(() => {
    if (selectedOrderTypes?.length > 0) {
      const updatedArray = partnerOrderTypeList.map(
        (
          item: { value: string; label: string },
          idx: string | number | any,
        ) => {
          if (
            selectedOrderTypes?.length > 0 &&
            selectedOrderTypes[0]?.label !== 'All'
          ) {
            return {
              ...item,
              disabled: item.value === 'All',
            }
          } else {
            return {
              ...item,
              disabled: item.label !== selectedOrderTypes[idx]?.label,
            }
          }
        },
      )

      setOrderTypeList(updatedArray)
      setFilterVariables((_prev: any) => ({
        ...filterVariables,
        orderTypes: selectedOrderTypes, // Get the correct value from the input
      }))
    } else {
      const updatedList = partnerOrderTypeList.map(
        (item: { value: string }) => ({
          ...item,
          disabled: false, //  Enable all items
        }),
      )

      setOrderTypeList(updatedList)
      setFilterVariables((_prev: any) => ({
        ...filterVariables,
        orderTypes: { label: 'All', value: '' }, // Get the correct value from the input
      }))
    }
  }, [selectedOrderTypes])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setTableVariables({
        ...tableVariables,
        search: serchByWarehouseOrderNo,
      })
    }
  }

  /** Toaster Notifications */

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }
  function FormatDate(dateString: string) {
    // Assuming the input gives you a string like "Thu May 09 2024 00:00:00 GMT 1000 (Australian Eastern Standard Time)"
    // Create a new Date object from the string
    const dateObject = new Date(dateString)
    // Format the date as YYYY-MM-DD
    const year = dateObject.getFullYear()
    const month = dateObject.getMonth() + 1 // getMonth() returns month index (0-11), so add 1 for the actual month
    const day = dateObject.getDate()
    const formattedDate = `${year}/${month.toString().padStart(2, '0')}/${day
      .toString()
      .padStart(2, '0')}`
    return formattedDate
  }

  const handleConfirmClick = () => {
    const validationResult = validConfirm()
    if (!validationResult.valid) {
      // notify(validationResult.message, 'error') // Display error message
      return
    }
    if (validationResult.valid) {
      setIsLoading(true)
      setFromHistory(false)
      getWarehouseById(warehouseId)
      createConfirmOrderList()
    }
  }

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-white h-full w-full pt-4 px-8 pb-0',
        {},
        className,
      )}>
      {/* Loading Screen */}
      {mainLoadingState && (
        <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[99] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
          <JsonLoader size='xs' />
        </div>
      )}

      {isOrderListScreen && (
        <div className='flex flex-col w-full h-full bg-white overflow-y-auto styled-scroll'>
          <div className='flex flex-col w-full h-auto bg-white rounded-lg px-4 py-2'>
            <Button
              className='mb-1'
              size='xs'
              appearance='outline'
              onClick={() => {
                seBulkConsignmentMenuIsOpen(true)
                setPWBulkConsignmentIsOpen(false)
              }}>
              <i className='ri-arrow-left-line pr-3'></i> Back to bulk order
              consignment
            </Button>
            <div className='flex flex-col w-[432px] pl-2 relative ml-auto'>
              <input
                onChange={(e: any) => {
                  setSerchByWarehouseOrderNo(e.target.value)
                }}
                onKeyPress={(e: any) => {
                  handleSearchKeyPress(e)
                }}
                type='text'
                placeholder='Search by WMS OrderNo'
                className='pr-4 pl-11 py-1 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm'
              />
              <i
                className='ri-search-2-line absolute top-0 bottom-0 flex items-center left-[22px] text-1xl text-N-500 cursor-pointer'
                onClick={() => {
                  setTableVariables({
                    ...tableVariables,
                    search: serchByWarehouseOrderNo,
                  })
                }}></i>
            </div>
            <div className='flex w-full items-center justify-between pt-6'>
              <Button
                onClick={() => {
                  setIsFilterModalOpen(!isFilterMenuOpen)
                }}
                appearance={isFilterMenuOpen ? 'default' : 'outline'}>
                <i className='ri-filter-3-line pr-3'></i> Filter
              </Button>
              {filterActive && (
                <div className='w-[6px] h-[2px] bg-R-500 rounded-full absolute top-[0px] right-[-2px]'></div>
              )}
            </div>
            <AnimatePresence initial={false}>
              {isFilterMenuOpen && (
                <motion.div
                  className='flex flex-col bg-white w-full'
                  initial='collapsed'
                  animate='open'
                  exit='collapsed'
                  transition={{ type: 'tween', duration: 0.3 }}
                  variants={{
                    open: { opacity: 1, y: 0, height: 'auto' },
                    collapsed: { opacity: -1, y: -35, height: 0 },
                  }}>
                  {/** Input field of filtering section */}
                  <div className='flex flex-col w-full mb-4 py-2 z-50'>
                    <div className='flex flex-row w-full gap-x-5'>
                      <div className='flex flex-col w-1/6 z-[50]'>
                        <span className='pb-1 text-N-700'>Principle</span>
                        <SimpleSelect
                          value={filterVariables.principle}
                          options={[
                            { label: 'All', value: '' },
                            ...partnerList,
                          ]}
                          onChange={(e: any) => {
                            setFilterVariables({
                              ...filterVariables,
                              principle: {
                                label: e.label,
                                value: e.value,
                              },
                            })
                          }}
                        />
                      </div>

                      <div className='flex flex-col w-1/6 z-[50]'>
                        <span className='pb-1 text-N-700'>Cons Status</span>
                        <SimpleSelect
                          value={filterVariables.consStatus}
                          options={[
                            { label: 'All', value: '' },
                            ...consStatusOptions,
                          ]}
                          onChange={(e: any) => {
                            setFilterVariables({
                              ...filterVariables,
                              consStatus: {
                                label: e.label,
                                value: e.value,
                              },
                            })
                          }}
                        />
                      </div>
                      <div className='flex flex-col w-1/6 z-[50]'>
                        <span className='pb-1 text-N-700'>Order Type</span>
                        <MultiSelect
                          value={selectedOrderTypes}
                          onChange={(e: any) => {
                            setselectedOrderTypes(e)
                          }}
                          options={partnerOrderTypeList}
                          isMulti={true}
                          placeholder='Select OrderType'
                        />
                      </div>

                      <div className='flex flex-col w-1/6 z-[50]'>
                        <span className='pb-1 text-N-700'>Cons No</span>
                        <Input
                          value={filterVariables?.consNo || ''} // Ensure it's not undefined
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              '',
                            )
                            setFilterVariables((_prev: any) => ({
                              ...filterVariables,
                              consNo: numericValue, // Get the correct value from the input
                            }))
                          }}
                        />
                      </div>
                      <div className='flex flex-col w-1/6 z-[50]'>
                        <span className='pb-1 text-N-700'>
                          Delivery Requested Date
                        </span>
                        <div className='date-picker mt-0'>
                          <DateRangePickerComponent
                            format='yyyy/MM/dd'
                            delayUpdate={true}
                            placeholder='Select a range'
                            onChange={(e: any) => {
                              setFilterVariables({
                                ...filterVariables,
                                rddFrom: FormatDate(e.target.startDate),
                                rddTo: FormatDate(e.target.endDate),
                              })
                            }}
                            startDate={filterVariables?.dateRaisedFrom}
                            endDate={filterVariables?.dateRaisedTo}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col w-full mb-4 py-2 z-40'>
                      <div className='flex flex-row w-full gap-x-5'>
                        <div className='flex flex-col w-1/6 z-[40]'>
                          <span className='pb-1 text-N-700'>Weight</span>
                          <SimpleSelect
                            value={filterVariables?.weight}
                            options={[
                              { label: 'All', value: '' },
                              ...weightStatusOptions,
                            ]}
                            onChange={(e: any) => {
                              setFilterVariables({
                                ...filterVariables,
                                weight: {
                                  label: e.label,
                                  value: e.value,
                                },
                              })
                            }}
                          />
                        </div>
                        <div className='flex flex-col w-1/6 z-[40]'>
                          <span className='pb-1 text-N-700'>Dimensions</span>
                          <SimpleSelect
                            value={filterVariables?.dimensions}
                            options={[
                              { label: 'All', value: '' },
                              ...dimensionsStatusOption,
                            ]}
                            onChange={(e: any) => {
                              setFilterVariables({
                                ...filterVariables,
                                dimensions: {
                                  label: e.label,
                                  value: e.value,
                                },
                              })
                            }}
                          />
                        </div>
                        <div className='flex flex-col w-1/6 z-[50]'>
                          <span className='pb-1 text-N-700'>Courrier Type</span>
                          <SimpleSelect
                            value={filterVariables.courrierType}
                            options={carrierList}
                            onChange={(e: any) => {
                              setFilterVariables({
                                ...filterVariables,
                                courrierType: {
                                  label: e.label,
                                  value: e.value,
                                },
                              })
                            }}
                          />
                        </div>
                        <div className='flex items-end gap-3 w-1/6 z-[50] '>
                          <Button
                            onClick={() => {
                              filterVariables.principle.value === ''
                                ? setFilterActive(false)
                                : setFilterActive(true)
                              getPWBulkOrderList()
                              setPageNumber(1)
                            }}>
                            Apply
                          </Button>
                          <Button
                            appearance='dull'
                            onClick={() => {
                              setFilterVariables({
                                principle: {
                                  label: 'Signal Brands',
                                  value: '42167',
                                },
                                consStatus: {
                                  label: 'With Cons No',
                                  value: 'Yes',
                                },
                                orderType: { label: 'All', value: '' },
                                consNo: '',
                                rddFrom: '',
                                rddTo: '',
                                weight: '',
                                dueDateTo: '',
                              })
                              setFilterActive(false)
                              setIsFilterModalOpen(false)
                              setIsResetFilter(true)
                              setselectedOrderTypes([
                                { label: 'All', value: '' },
                              ])
                              setPageNumber(1)
                            }}>
                            Clear
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-row w-full mb-2 py-0 z-30'></div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className='w-full flex justify-end gap-1 p-4 py-0 bg-white'>
            <Button
              className='px-6 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition'
              onClick={() => setIsUpdatePackageModalOpen(true)}
              disabled={selectedRowIds.length == 0}>
              Update W & D
            </Button>

            <Button
              className='px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition'
              onClick={() => {
                const destinations = selectedRowIds.map(
                  (row: any) => row?.partnerOrder?.shippingAddress?.countryCode,
                )

                const uniqueDestinations = new Set(destinations)

                if (uniqueDestinations.size > 1) {
                  notify(
                    'Please select rows with the same destination.',
                    'error',
                  )
                  return
                }
                setIsUpdateCourrierModalOpen(true)
              }}
              disabled={selectedRowIds.length == 0}>
              Update Courrier
            </Button>
          </div>

          <div className='flex flex-col w-full h-auto bg-white rounded-lg px-4 py-2 overflow: visible'>
            <BulkOrderListDataTable
              key={bulkOrderList?.length}
              itemData={bulkOrderList || []}
              className=''
              setTableVariables={setTableVariables}
              tableVariables={tableVariables}
              totalRowCount={totalRowCount}
              setSelectedRowIds={setSelectedRowIds}
              selectedRowIds={selectedRowIds}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              setIsLoading={setIsLoading}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              setUpdateCarrier={setIsUpdateCourrierModalOpen}
              worderId={worderId}
            />
          </div>
          <div className='flex flex-col w-full h-auto bg-white rounded-lg px-4 py-5 overflow: visible z-[60]'>
            {isUpdatePackageModalOpen && (
              <UpdatePackageModal
                isActive={isUpdatePackageModalOpen}
                notify={notify}
                onCloseClick={() => {
                  setIsUpdatePackageModalOpen(false)
                  //resetUpdatePackageModalDetails()
                }}
                setShipmentPackageDetails={setShipmentPackageDetails}
                shipmentPackageDetails={shipmentPackageDetails}
                setIsUpdate={setIsUpdate}
                setIsLoading={setIsLoading}
                /*callOrderShipmentsById={() => {
              //getBulkOrder(bulkConsignmentId)
            }}*/
                selectedRowIds={selectedRowIds}
              />
            )}

            {isUpdateCourrierModalOpen && (
              <UpdateCourrierModal
                isActive={isUpdateCourrierModalOpen}
                notify={notify}
                onCloseClick={() => {
                  setIsUpdateCourrierModalOpen(false)
                  //resetUpdatePackageModalDetails()
                }}
                setIsLoading={setIsLoading}
                setIsUpdate={setIsUpdate}
                selectedRowIds={selectedRowIds}
                useGetCarrierProducts={useGetCarrierProducts}
              />
            )}
          </div>

          <div className='w-full flex justify-end p-4 bg-white relative bottom-0  border-gray-300'>
            <Button
              className='px-6 py-2 bg-orange-500 text-white rounded-md'
              onClick={handleConfirmClick}>
              Confirm
            </Button>
          </div>
        </div>
      )}
      {/* Footer Container */}
    </div>
  )
}

PWBulkOrderConsignment.defaultProps = {}

export default PWBulkOrderConsignment
